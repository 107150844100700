import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const MyDocument = ({ willData, authUser }) => {
  const [surrogate1Data, setSurrogate1Data] = useState(null);
  const [surrogate2Data, setSurrogate2Data] = useState(null);
  const [lastWillAndTestamentInputs, setLastWillAndTestamentInputs] = useState();

  useEffect(() => {
    console.log(willData);
    findSurrogateData(willData);
    setLastWillAndTestamentInputs(willData?.last_will_and_testament);
  }, []);

  const findSurrogateData = (willData) => {
    const surrogate_1 = willData.surrogate_1;
    const surrogate_2 = willData.surrogate_2;

    if (
      willData.married_status === "1" ||
      willData.married_status === 1 ||
      willData.married_status === true
    ) {
      let spouse_full_name =
        willData.spouse_info.first_name +
        " " +
        willData.spouse_info.middle_name +
        " " +
        willData.spouse_info.last_name;

      if (surrogate_1 === spouse_full_name) {
        const surroage1_data = {
          name: surrogate_1,
          address: willData.spouse_info.address_1,
          phone: willData.spouse_info.phone,
          email: willData.spouse_info.email,
        };
        setSurrogate1Data(surroage1_data);
      }

      if (surrogate_2 === spouse_full_name) {
        const surroage2_data = {
          name: surrogate_2,
          address: willData.spouse_info.address_1,
          phone: willData.spouse_info.phone,
          email: willData.spouse_info.email,
        };
        setSurrogate2Data(surroage2_data);
      }
    }

    if (
      willData.have_children === "1" ||
      willData.have_children === 1 ||
      willData.have_children === true
    ) {
      willData.children_info.map((item) => {
        const c_full_name =
          item.c_first_name + " " + item.c_middle_name + " " + item.c_last_name;

        if (surrogate_1 === c_full_name) {
          const surroage1_data = {
            name: surrogate_1,
            address: item.c_address_1,
            phone: item.c_phone,
            email: item.c_email,
          };
          setSurrogate1Data(surroage1_data);
        }

        if (surrogate_2 === c_full_name) {
          const surroage2_data = {
            name: surrogate_2,
            address: item.c_address_1,
            phone: item.c_phone,
            email: item.c_email,
          };
          setSurrogate2Data(surroage2_data);
        }
      });
    }

    if (
      willData.have_sibling === "1" ||
      willData.have_sibling === 1 ||
      willData.have_sibling === true
    ) {
      willData.sibling_info.map((item) => {
        const s_full_name =
          item.s_first_name + " " + item.s_middle_name + " " + item.s_last_name;

        if (surrogate_1 === s_full_name) {
          const surroage1_data = {
            name: surrogate_1,
            address: item.s_address_1,
            phone: item.s_phone,
            email: item.s_email,
          };
          setSurrogate1Data(surroage1_data);
        }

        if (surrogate_2 === s_full_name) {
          const surroage2_data = {
            name: surrogate_2,
            address: item.s_address_1,
            phone: item.s_phone,
            email: item.s_email,
          };
          setSurrogate2Data(surroage2_data);
        }
      });
    }
  };

  const styles = StyleSheet.create({
    page: {
      paddingHorizontal: 20,
    },
    section: {
      margin: 40,
    },
    pageBreak: {
      pageBreakBefore: "always",
    },
    check_container: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 5,
    },
    checkbox: {
      width: 10,
      height: 10,
      marginRight: 5,
      borderWidth: 1,
      borderColor: "black",
    },
  });

  const Checkbox = ({ label }) => {
    return (
      <View style={styles.check_container}>
        <View style={styles.checkbox} fixed />
        <Text style={{ fontSize: 15 }}>{label}</Text>
      </View>
    );
  };

  const relationShipStatus = (married_status) => {
    if (married_status == 0) {
      return "SINGLE";
    } else if (married_status == 1) {
      return "MARRIED";
    } else {
      return "WIDOWED";
    }
  };

  const address = (data) => {
    let add = data?.address_1
      .concat(`, ${data?.city}`)
      .concat(`, ${data?.state}`)
      .concat(`, ${data?.zip}`);

    return add;
  };

  const childAddress = (data) => {
    let add = data?.c_address_1
      .concat(`, ${data?.c_city}`)
      .concat(`, ${data?.c_state}`)
      .concat(`, ${data?.c_zip}`);

    return add;
  };

  const siblingAddress = (data) => {
    let add = data?.s_address_1
      .concat(`, ${data?.s_city}`)
      .concat(`, ${data?.s_state}`)
      .concat(`, ${data?.s_zip}`);

    return add;
  };

  const testatorName = () => {
    return `${authUser.first_name} ${authUser.middle_name || ""} ${
      authUser.last_name
    }`;
  };

  const testatorAddress = () => {
    return `${authUser.residential_address}, ${authUser.city}, ${authUser.state}, ${authUser.county} - ${authUser.zip}`;
  };

  const isJointResidence = () => {
    if(lastWillAndTestamentInputs?.residence_status == "2"){
      return true;
    }else{
      return false;
    }
  }

  const burialWish = () => {
    switch (lastWillAndTestamentInputs?.burial_choice){
      case "1":
        return "BURIED";
      case "2":
        return "CREMATED";
      case "0":
        return "LET MY PERSONAL REPRESENTATIVE CHOOSE";
    }
  }

  return (
    <>
      {authUser && willData && (
        <>
          <Document>
            <Page size="A4" style={styles.page}>
              <Text
                wrap={true}
                style={{
                  fontSize: 30,
                  textAlign: "center",
                  textDecorationColor: "#000000",
                }}
              >
                Last Will and Testament
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: 14,
                  textAlign: "center",
                  textDecorationColor: "#000000",
                  marginTop: 10,
                }}
              >
                OF
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: 14,
                  textAlign: "center",
                  textDecorationColor: "#000000",
                  marginTop: 10,
                }}
              >
                {testatorName() || ""}
              </Text>

              <Text wrap={true} style={{ fontSize: 12, marginTop: 20 }}>
                I, {testatorName() || ""}, a resident of and legally domiciled
                in {testatorAddress()} County, Florida, and a citizen of the
                United States, make, publish and declare this instrument to be
                my Last Will and Testament, and hereby revoke any and all of my
                prior Wills and Codicils that may exist at the time of executing
                this document.
              </Text>

              <Text
                wrap={true}
                style={{
                  fontSize: 12,
                  textAlign: "center",
                  fontWeight: "600",
                  textDecoration: "underline",
                  textDecorationColor: "#000000",
                  marginTop: 10,
                }}
              >
                ARTICLE I
              </Text>
              <Text
                wrap={true}
                style={{
                  fontSize: 12,
                  textAlign: "center",
                  fontWeight: "600",
                  textDecoration: "underline",
                  textDecorationColor: "#000000",
                  marginTop: 4,
                }}
              >
                FAMILY
              </Text>

              {/* Spouse Section */}
              <>
                <Text wrap={true} style={{ fontSize: 12, marginTop: 20 }}>
                  1.1. I am {relationShipStatus(willData?.married_status)}{" "}
                  currently at the time of making this Will.
                </Text>
                {willData?.married_status != 0 && (
                  <Text
                    wrap={true}
                    style={{ fontSize: 12, marginTop: 10, paddingLeft: 30 }}
                  >
                    1.1.1 My spouse’s name is{" "}
                    {willData?.spouse_info?.first_name || ""}{" "}
                    {willData?.spouse_info?.middle_name || ""}{" "}
                    {willData?.spouse_info?.last_name || ""}, their date of
                    birth is {willData?.spouse_info?.date_of_birth}, and present address is{" "}
                    {address(willData?.spouse_info)}.
                  </Text>
                )}
              </>

              {/* Children Section */}
              <>
                <Text wrap={true} style={{ fontSize: 12, marginTop: 20 }}>
                  1.2 I {willData.have_children ? "HAVE" : "DO NOT HAVE"} living
                  children at the time of making this Will. In determining
                  whether any person is a child or descendant for purposes of
                  this instrument, legal adoption, single or successive, shall
                  be the equivalent of blood relationship; provided, however,
                  that no person adopted after attaining the age of 18 years
                  shall be deemed to be a descendant hereunder.
                </Text>
                {willData?.have_children &&
                  willData?.children_info.map((children, i) => (
                    <Text
                      wrap={true}
                      style={{ fontSize: 12, marginTop: 10, paddingLeft: 30 }}
                    >
                      1.2.{i + 1}. My child’s name is{" "}
                      {children?.c_first_name || ""} {children?.c_middle_name || ""} {children?.c_last_name || ""},
                      their date of birth is {children?.c_date_of_birth || ""},
                      and present address is {childAddress(children)}.
                    </Text>
                  ))}
              </>

              {/* Sibling Section */}
              <>
                <Text wrap={true} style={{ fontSize: 12, marginTop: 20 }}>
                  1.3 I {willData.have_sibling ? "HAVE" : "DO NOT HAVE"} living
                  children at the time of making this Will. In determining
                  whether any person is a child or descendant for purposes of
                  this instrument, legal adoption, single or successive, shall
                  be the equivalent of blood relationship; provided, however,
                  that no person adopted after attaining the age of 18 years
                  shall be deemed to be a descendant hereunder.
                </Text>
                {willData?.have_sibling &&
                  willData?.sibling_info.map((sibling, i) => (
                    <Text
                      wrap={true}
                      style={{ fontSize: 12, marginTop: 10, paddingLeft: 30 }}
                    >
                      1.3.{i + 1}. My child’s name is{" "}
                      {sibling?.s_first_name || ""} {sibling?.s_middle_name || ""} {sibling?.s_last_name || ""},
                      their date of birth is {sibling?.s_date_of_birth || ""},
                      and present address is {siblingAddress(sibling)}.
                    </Text>
                  ))}
              </>
            </Page>

            <Page size="A4" style={styles.page}>
              <Text
                wrap={true}
                style={{
                  fontSize: 12,
                  textAlign: "center",
                  fontWeight: "600",
                  textDecoration: "underline",
                  textDecorationColor: "#000000",
                  marginTop: 10,
                }}
                fontWeight={700}
              >
                ARTICLE II
              </Text>
              <Text
                wrap={true}
                fontWeight={700}
                style={{
                  fontSize: 12,
                  textAlign: "center",
                  fontWeight: "600",
                  textDecoration: "underline",
                  textDecorationColor: "#000000",
                  marginTop: 4,
                }}
              >
                DEVISE OF TANGIBLE PERSONAL PROPERTY
              </Text>

              <Text style={{ marginTop: 30, fontSize: 12 }}>
                2.1.{" "}
                <u style={{ textDecoration: "underline" }}>Separate Writing</u>.
                I give and devise all of my tangible personal property, personal
                and household effects, jewelry, furniture, automobiles and
                collections, and any insurance policies thereon, (“my tangible
                personal property”) in accordance with a written statement which
                I shall have prepared prior to my death in conformity with
                Florida law. My Personal Representative may assume that no
                written statement exists if none is found within 30 days after
                admission of this Will to probate.
              </Text>

              <>
                <Text style={{ marginTop: 20, fontSize: 12 }}>
                  2.2. Distributions. Except as otherwise provided in any such
                  written statement, I give and devise my tangible personal
                  property as follows:
                </Text>

                <Text style={{ marginTop: 20, fontSize: 12, paddingHorizontal: 30 }}>
                  2.2.1. I give and devise the balance of my tangible personal
                  property, including, but not limited to, my personal and
                  household effects, jewelry, furniture, automobiles and
                  collections, and any insurance policies thereon to: {lastWillAndTestamentInputs?.tangible_posession_representative} to use and
                  enjoy.
                </Text>

                <Text style={{ marginTop: 20, fontSize: 12, paddingHorizontal: 30 }}>
                  If the aforementioned individual(s) predecease me, then I give
                  and devise the balance of my tangible personal property
                  devised to them to: {lastWillAndTestamentInputs ? lastWillAndTestamentInputs["2nd_tangible_posession_representative"] : ''} then living at my death in equal shares.
                  Distribution is to be made per stirpes and not per capita.
                </Text>

                <Text style={{ marginTop: 20, fontSize: 12, paddingHorizontal: 30 }}>
                  2.2.2. Personal Representative to Resolve Disputes. In case of
                  any difference of opinion with respect to the property passing
                  under this Article, the decision of my Personal Representative
                  shall be final and conclusive upon all parties interested
                  hereunder.
                </Text>
              </>

              {/* Article 3 */}
              <>
                <Text
                  wrap={true}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 30,
                  }}
                  fontWeight={700}
                >
                  ARTICLE III
                </Text>
                <Text
                  wrap={true}
                  fontWeight={700}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 4,
                  }}
                >
                  DEVISE OF HOMESTEAD RESIDENCE
                </Text>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  3.1. I {lastWillAndTestamentInputs?.residence_status != "0" ? 'Own' : 'DO NOT OWN'} fee simple title in certain real
                  property that I constitute as my Homestead.
                </Text>

                {lastWillAndTestamentInputs?.residence_status != '0' ? (
                  <Text style={{ marginTop: 20, fontSize: 12, paddingHorizontal: 30 }}>
                    I own such property {lastWillAndTestamentInputs?.residence_status == "1" ? 'INDIVIDUALY' : 'JOINTLY'}.
                  </Text>
                ) : <Text></Text>}

                {isJointResidence() ? 
                  <Text style={{ fontSize: 12, paddingHorizontal: 30 }}>
                    with {lastWillAndTestamentInputs?.residence_surrogate}. Such property, located in {lastWillAndTestamentInputs?.residence_address || ""} County,
                    is properly described as follows: {lastWillAndTestamentInputs?.residence_address}. I give
                    my property to {lastWillAndTestamentInputs?.tangible_posession_representative}
                  </Text>
                 : <Text></Text>}
              </>
            </Page>

            <Page size="A4" style={styles.page}>
              {/* Article 3 */}
              <>
                <Text
                  wrap={true}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 30,
                  }}
                  fontWeight={700}
                >
                  ARTICLE IV
                </Text>
                <Text
                  wrap={true}
                  fontWeight={700}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 4,
                  }}
                >
                  DEVISE OF RESIDUARY ESTATE
                </Text>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  4.1. All the residue of my estate and property, wherever
                  situated, including lapsed gifts and devises, I give and
                  devise to: {lastWillAndTestamentInputs?.tangible_posession_representative}
                </Text>
                <Text style={{ marginTop: 20, fontSize: 12, paddingHorizontal: 30 }}>
                  If the aforementioned individual(s) predeceases me, then all
                  the residue of my estate and property, wherever situated,
                  devised to them, shall be distributed to: {lastWillAndTestamentInputs ? lastWillAndTestamentInputs['2nd_tangible_posession_representative'] : ''} then living at the time
                  of my death. Distribution to be made per stripes and not per
                  capita.
                </Text>
              </>

              <>
                <Text
                  wrap={true}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 30,
                  }}
                  fontWeight={700}
                >
                  ARTICLE V
                </Text>
                <Text
                  wrap={true}
                  fontWeight={700}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 4,
                  }}
                >
                  CERTAIN DISTRIBUTIONS TO PERSONS UNDER 21 YEARS OF AGE
                </Text>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  5.1. Distributions. With respect to any property of my estate
                  that vests in a beneficiary under the age of 21 years, and
                  would otherwise be distributable to the beneficiary, the
                  fiduciary holding such property shall (1) deliver such
                  property to the beneficiary’s guardian, if any, (2) establish
                  with such property a custodianship for the beneficiary under a
                  Uniform Gifts or Transfers to Minors Act, or (3) retain
                  possession of such property pursuant to a power in trust
                  hereby conferred upon the fiduciary.
                </Text>
                <Text style={{ marginTop: 20, fontSize: 12, paddingHorizontal: 30 }}>
                  5.2. Property Retained Pursuant to a Power in Trust. With
                  respect to property retained pursuant to a power in trust, the
                  fiduciary shall pay to or for the benefit of the beneficiary
                  so much or all of the income and principal of the property as
                  the fiduciary deems necessary or advisable for the
                  beneficiary’s maintenance in health and reasonable comfort and
                  education, and add to principal any income not so paid. The
                  fiduciary shall distribute the property to the beneficiary
                  when the beneficiary reaches the age of 21 years or to the
                  estate of the beneficiary if the beneficiary dies before
                  receiving the property in full.
                </Text>
              </>

              <>
                <Text
                  wrap={true}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 30,
                  }}
                  fontWeight={700}
                >
                  ARTICLE VI
                </Text>
                <Text
                  wrap={true}
                  fontWeight={700}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 4,
                  }}
                >
                  APPOINTMENT OF PERSONAL REPRESENTATIVE
                </Text>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  6.1. Appointment of Personal Representative. I appoint {lastWillAndTestamentInputs?.personal_representative} as
                  Personal Representative of my estate. If for any reason this
                  designated individual should fail or cease to act as Personal
                  Representative, I appoint {lastWillAndTestamentInputs?.alternate_representative}, as alternate or successor Personal
                  Representative.
                </Text>
                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  6.2. Waiver of Bond. No bond or other security shall be
                  required of any Personal Representative wherever acting, the
                  same being specifically waived hereby.
                </Text>
              </>

              <>
                <Text
                  wrap={true}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 10,
                  }}
                  fontWeight={700}
                >
                  ARTICLE VII
                </Text>
                <Text
                  wrap={true}
                  fontWeight={700}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 4,
                  }}
                >
                  PAYMENT OF TAXES, DEBTS, AND EXPENSES
                </Text>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  7.1. Payment of Expenses, Debts, and Costs. My Personal
                  Representative shall pay from the residuary estate all funeral
                  expenses, costs of administration, including ancillary, costs
                  of safeguarding and delivering devises, the expenses of my
                  last illness and other proper charges against my estate.
                </Text>
                <Text style={{ marginTop: 20, fontSize: 12, paddingHorizontal: 30 }}>
                  7.2. Payment of Taxes. Estate and inheritance taxes assessed
                  by reason of my death shall be paid and apportioned in
                  accordance with applicable Florida and Federal law in effect
                  at the time of my death. Interest and penalties concerning any
                  tax shall be paid and charged in the same manner as the tax.
                </Text>
                <Text style={{ marginTop: 20, fontSize: 12, paddingHorizontal: 30 }}>
                  7.3. Personal Representative’s Right to Select Assets and Make
                  Tax Elections. My Personal Representative’s selection of
                  assets to be sold to make the foregoing payments or to satisfy
                  any pecuniary devises, and the tax effects thereof, shall not
                  be subject to question by any beneficiary. My Personal
                  Representative shall make such elections under the tax laws as
                  my Personal Representative deems advisable, without regard to
                  the relative interests of the beneficiaries. No adjustment
                  shall be made between principal and income or in the relative
                  interests of the beneficiaries to compensate for the effect of
                  elections under the tax laws made by my Personal
                  Representative.
                </Text>
              </>

              <>
                <Text
                  wrap={true}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 10,
                  }}
                  fontWeight={700}
                >
                  ARTICLE VIII
                </Text>
                <Text
                  wrap={true}
                  fontWeight={700}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 4,
                  }}
                >
                  FIDUCIARY POWERS
                </Text>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  8.1. The Personal Representative and any donee of a power in
                  trust under this instrument (“fiduciary”) shall hold, manage,
                  care for and protect my estate and shall have all powers
                  granted to fiduciaries under the laws of the State of Florida
                  as well as the following powers:
                </Text>
                <Text style={{ marginTop: 15, fontSize: 12, paddingHorizontal: 30 }}>
                  8.1.1. To Retain Property. To retain any property originally
                  constituting my estate, although not of a type, quality or
                  diversification considered proper for investment by
                  fiduciaries;
                </Text>
                <Text style={{ marginTop: 15, fontSize: 12, paddingHorizontal: 30 }}>
                  8.1.2. To Manage Assets. To invest in bonds, stocks, notes or
                  other property; to lease, borrow with or without security from
                  any lender, including a fiduciary hereunder individually;
                </Text>
                <Text style={{ marginTop: 15, fontSize: 12, paddingHorizontal: 30 }}>
                  8.1.3. To Sell Assets. To sell or exchange all or any part of
                  my estate, real or personal for such prices and upon such
                  terms as the fiduciary deems proper;
                </Text>
                <Text style={{ marginTop: 15, fontSize: 12, paddingHorizontal: 30 }}>
                  8.1.4. To Deal with Claims. To compromise, contest, prosecute
                  or abandon claims in favor of or against my estate;
                </Text>
                <Text style={{ marginTop: 15, fontSize: 12, paddingHorizontal: 30 }}>
                  8.1.5. To Select and Allocate Assets. To select and allocate
                  the cash, securities and/or other property, which may include
                  real estate and interests therein, that shall constitute any
                  distribution under this instrument, employing for this purpose
                  values current at the time or times of distribution;
                </Text>
                <Text style={{ marginTop: 15, fontSize: 12, paddingHorizontal: 30 }}>
                  8.1.6. To Distribute in Cash or Kind. To distribute income and
                  principal in cash or in kind, or partly in each, and to
                  allocate or distribute undivided interests or different assets
                  or disproportionate interests in assets and to value my estate
                  in order to make allocation or distribution;
                </Text>
                <Text style={{ marginTop: 15, fontSize: 12, paddingHorizontal: 30 }}>
                  8.1.7. To Deal with Other Fiduciaries. To deal with or
                  purchase assets from the fiduciary of any trust made by me or
                  any member of my family or a trust or estate in which any
                  beneficiary under this instrument has an interest, though a
                  Trustee under this instrument is such fiduciary, and to retain
                  any property so purchased; and
                </Text>
                <Text style={{ marginTop: 15, fontSize: 12, paddingHorizontal: 30 }}>
                  8.1.8. To Perform Necessary or Appropriate Acts. To perform
                  other acts necessary or appropriate for the proper
                  administration of my estate, execute and deliver necessary
                  instruments and give full receipts and discharges.
                </Text>
                <Text style={{ marginTop: 20, fontSize: 12, paddingHorizontal: 30 }}>
                  8.2. The foregoing powers shall be exercised by the fiduciary
                  without authorization by any court and, as to property subject
                  to administration outside the State of Florida, only upon the
                  direction of my Florida Personal Representative.
                </Text>
              </>

              <>
                <Text
                  wrap={true}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 10,
                  }}
                  fontWeight={700}
                >
                  ARTICLE IX.
                </Text>
                <Text
                  wrap={true}
                  fontWeight={700}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 4,
                  }}
                >
                  ACCESS TO DIGITAL AND ELECTRONICALLY STORED INFORMATION
                </Text>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  9.1 Digital Devices, Assets and Accounts. My Personal
                  Representative(s) shall have the express authority to exercise
                  all powers that I may have over any digital assets, electronic
                  records, computer, telephone, digital devices, data storage
                  device, user account, electronically stored information, and
                  any domain name, whether the same are in my name or that I
                  own, or that I lawfully use jointly with anyone else. My
                  Personal Representative(s) have my express, lawful consent to:
                  (i) access, manage, control, delete and terminate any such
                  asset or account, including but not limited to, e-mail,
                  telephone, bank, brokerage, investment, insurance, social
                  networking, internet service provider, retail vendor,
                  utilities and other accounts; (ii) change my username and
                  password to gain access to such accounts and information;
                  (iii) transfer or withdraw funds or other assets among or from
                  such accounts; and (iv) open new accounts in the name of my
                  estate; all as my Personal Representative(s) determine is
                  necessary or advisable. I hereby give my lawful consent and
                  fully authorize my Personal Representative(s) to access,
                  manage, control, delete and terminate any electronically
                  stored information and communications to the fullest extent
                  allowable under the Electronic Communications Privacy Act of
                  1986, as amended, the Gramm-Leach-Bliley Act, as amended, and
                  any other applicable laws and to take any actions, I am
                  authorized to take under all applicable terms of service,
                  terms of use, licensing and other account agreements or laws.
                  To the extent a specific reference to any statute is required
                  in order to give effect to this provision, I specifically
                  provide that my intention is to so reference such statute,
                  whether it is not in existence or comes into existence or is
                  amended after the date of this document.
                </Text>
              </>

              <>
                <Text
                  wrap={true}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 10,
                  }}
                  fontWeight={700}
                >
                  ARTICLE X.
                </Text>
                <Text
                  wrap={true}
                  fontWeight={700}
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                    marginTop: 4,
                  }}
                >
                  BURIAL INSTRUCTIONS
                </Text>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  10.1 I wish to be {burialWish()}.
                  {"\n"}
                  <b>IN WITNESS WHEREOF</b>, I have hereunto affixed my hand and
                  seal, in the presence of the witnesses who shall hereafter
                  subscribe their names hereto as attesting witnesses, this
                  _____ day of ­­­­__________________ 20___.
                </Text>

                <Text style={{ marginTop: 20, fontSize: 12, paddingHorizontal: 30 }}>
                  _______________________­_____
                  {"\n"}{testatorName()}
                </Text>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  The foregoing instrument was signed, sealed, and declared by {testatorName()} as their Last Will and Testament in the presence of
                  us, the undersigned, who, at their special instance and
                  request, do attest as witnesses, immediately after the said
                  Testator has signed their name thereto, in their presence and
                  in the presence of each other, this the _____ day of
                  ___________________ 20___.
                </Text>

                <>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    Witness #1 Name: ___________________________
                  </Text>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    Witness #1 Signature: ___________________________
                  </Text>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    Witness #1 Address: ___________________________
                  </Text>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    ___________________________
                  </Text>
                </>

                <>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    Witness #2 Name: ___________________________
                  </Text>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    Witness #2 Signature: ___________________________
                  </Text>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    Witness #2 Address: ___________________________
                  </Text>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    ___________________________
                  </Text>
                </>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  STATE OF FLORIDA
                </Text>
                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  COUNTY OF __________________________
                </Text>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  I, {testatorName()}, declare to the officer taking my acknowledgment
                  of this instrument, and to the subscribing witnesses, that I
                  signed this instrument as my Will.
                </Text>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  ______________________________{"\n"}{testatorName()}
                </Text>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  We, the undersigned, have been sworn by the officer signing
                  below, and declare to that officer on our oaths that the
                  Testator declared the instrument to be their Will and signed
                  it in our presence and that we each signed the instrument as a
                  witness in the presence of the Testator and of each other.
                </Text>

                <>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    Witness #1 Name: ___________________________
                  </Text>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    Witness #1 Signature: ___________________________
                  </Text>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    Witness #1 Address: ___________________________
                  </Text>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    ___________________________
                  </Text>
                </>

                <>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    Witness #2 Name: ___________________________
                  </Text>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    Witness #2 Signature: ___________________________
                  </Text>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    Witness #2 Address: ___________________________
                  </Text>
                  <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                    ___________________________
                  </Text>
                </>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                  The foregoing instrument was acknowledged before me by means
                  of ☐ physical presence or ☐ online notarization, this ____ day
                  of _______________ 20___ by {testatorName()}, the foregoing, ☐
                  who is personally known to me or ☐ who has produced
                  ________________________ as identification and sworn to (or
                  affirmed) and subscribed before me by means of ☐ physical
                  presence or ☐ online notarization, this ____ day of
                  _______________ 20___, by the witnesses
                  ___________________________ (witness #1) and
                  __________________________ (witness #2) ☐ who are personally
                  known to me or ☐ who have produced ________________________ as
                  identification.
                </Text>

                <Text style={{ marginTop: 30, fontSize: 12, paddingHorizontal: 30 }}>
                ____________________________________________{"\n"}
                  [Notary Seal] Notary Public
                </Text>

                <Text style={{ fontSize: 12, paddingLeft: 15, paddingLeft: 30 }}>
                  ____________________________________________{'\n'}
                  Name typed, printed or stamped
                </Text>
                <Text style={{ fontSize: 12, marginBottom: 15, paddingLeft: 30 }}>
                  My Commission Expires: ____________
                </Text>
              </>
            </Page>
          </Document>
        </>
      )}
    </>
  );
};

export default MyDocument;
