import React from "react"
import { Route, Redirect } from "react-router-dom"
import { get } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import { useDispatch } from "react-redux"
import { setAuthUser } from "../../store/actions"


const Authmiddleware = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => {
  const dispatch = useDispatch()

  return (
    <Route
      {...rest}
      render={props => {
        get(url.VERIFY_TOKEN).then(res => {
          if (res.success) {
            dispatch(setAuthUser(res.user))
          //  console.log(setAuthUser);
          } else {
            localStorage.removeItem('access_token')
            return (
              <Redirect
                to={{ pathname: "/home", state: { from: props.location } }}
              />
            )
          }
        })
        .catch(error => {
          localStorage.removeItem('access_token')
            return (
              <Redirect
                to={{ pathname: "/home", state: { from: props.location } }}
              />
            )
        })
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

export default Authmiddleware;
