import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_ADMIN, LOGOUT_ADMIN, } from "./actionTypes"
import { apiError, loginSuccess } from "./actions"
import {setAuthAdmin} from '../register/actions'
import { loginAdminAsync } from "../../../helpers/backend_helper"
import { axiosApi } from '../../../helpers/api_helper'

function* loginAdmin({ payload: { user, history } }) {
  try {
    const response = yield call(loginAdminAsync, user)
    if (response.success) {
      localStorage.setItem('access_token', response.token)
      axiosApi.defaults.headers.common["Authorization"] = "Bearer " + response.token
      setAuthAdmin(response.user)
      yield put(loginSuccess(response.user))
      history.push("/admin/dashboard")
    }
  } catch (error) {
    yield put(apiError(error?.response?.data?.message))
  }
}//**//

function* logoutAdmin({ payload: { history } }) {
  try {
    axiosApi.defaults.headers.common["Authorization"] = undefined
    localStorage.removeItem("access_token")
    history.push("/admin/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_ADMIN, loginAdmin)
  yield takeEvery(LOGOUT_ADMIN, logoutAdmin)
}

export default authSaga
