import {
  LOGIN_ADMIN,
  LOGIN_SUCCESS,
  LOGOUT_ADMIN,
  API_ERROR,
} from "./actionTypes"

export const loginAdmin = (user, history) => {
  return {
    type: LOGIN_ADMIN,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutAdmin = history => {
  return {
    type: LOGOUT_ADMIN,
    payload: { history },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}


