export const REGISTER_WILL_USER = "REGISTER_WILL_USER"
export const REGISTER_WILL_USER_SUCCESS = "REGISTER_WILL_USER_SUCCESS"
export const REGISTER_WILL_USER_FAIL = "REGISTER_WILL_USER_FAIL"

export const LOGIN_WILL_USER = "LOGIN_WILL_USER"
export const LOGIN_WILL_USER_SUCCESS = "LOGIN_WILL_USER_SUCCESS"
export const LOGIN_WILL_USER_FAIL = "LOGIN_WILL_USER_FAIL"

export const SET_AUTH_USER = 'SET_AUTH_USER'

export const UPDATE_WILL_USER = "UPDATE_WILL_USER"
export const UPDATE_WILL_USER_SUCCESS = "UPDATE_WILL_USER_SUCCESS"
export const UPDATE_WILL_USER_FAIL = "UPDATE_WILL_USER_FAIL"

export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'

export const ADD_USER = 'ADD_USER'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'

export const LOGOUT_USER = 'LOGOUT_USER'    







