import { combineReducers } from "redux"
import Layout from "./layout/reducer"
// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import User from './user/reducer'
import Will from './will/reducer'

const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  User,
  Will
})

export default rootReducer
