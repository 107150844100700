import { Button } from "antd";
import React from "react";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import { loginWillUser } from "../store/actions"
import { Row, Col, Container, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"


const WillLogin = (props) => {

  const dispatch = useDispatch()
  const { loading, login_error } = useSelector(state => state.User)

  const handleValidSubmit = (event, values) => {
    dispatch(loginWillUser(values, props.history))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> Login Page </title>
      </MetaTags>
      <div style={{ margin: "45px 0px" }}>
        <Container>
          <h1 className="fw-normal txt-primary mb-4" style={{ fontSize: 40 }}>
            Please Login To Create the Will!
          </h1>
          <Row>
            <Col xs={12} md={6}>
              <AvForm
                className="custom-form mt-4 pt-2"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                {login_error ? <Alert color="danger">{login_error}</Alert> : null}
                
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email"
                    className="form-control"
                    placeholder="Enter email"
                    type="email"
                    required
                  />
                </div>
                <div className="mb-3">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                      <label className="form-label">Password</label>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="">
                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="password"
                      type="password"
                      className="form-control"
                      required
                      placeholder="Enter Password"
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="remember-check" />
                      <label className="form-check-label" htmlFor="remember-check">
                        Remember me
                      </label>
                    </div>
                  </div>

                </div>
                <div className="mb-3">
                  <Button className="btn btn-primary w-100 waves-effect waves-light" htmlType="submit" loading={loading} style={{ height: 45 }}> Log In</Button>
                </div>
              </AvForm>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default WillLogin;
