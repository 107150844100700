import { Button, Divider, Form, Input, Radio, Alert } from "antd";
import { useWatch } from "antd/es/form/Form";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Container, Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  getWillByUserId,
  saveWillByUserId,
  changeEditStatus,
  updateWillByUserId,
} from "../store/actions";
import { useHistory } from "react-router-dom";

const YourInfo = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [authUser, setAuthUser] = useState(null)
  const [c_initial, setCInitial] = useState([]);
  const [s_initial, setSInitial] = useState([]);
  const [nameError, setNameError] = useState(false);

  const [c_age, togleCAge] = useState({});
  const [s_age, togleSAge] = useState({});

  const [errors, setErrors] = useState([]);

  const [form] = Form.useForm();
  const { will, loading, edit_open } = useSelector((state) => state.Will);
  const { authUser } = useSelector((state) => state.User);

  const married_status = useWatch("married_status", form);
  const have_children = useWatch("have_children", form);
  const have_sibling = useWatch("have_sibling", form);

  useEffect(() => {
    if (authUser) {
      dispatch(getWillByUserId(authUser.id));
    }
  }, [authUser]);

  useEffect(() => {
    if (will) {
      form.setFieldsValue({
        married_status:
          will.married_status === "1" ||
          will.married_status === 1 ||
          will.married_status === true
            ? true
            : false,
        have_children:
          will.have_children === "1" ||
          will.have_children === 1 ||
          will.have_children === true
            ? true
            : false,
        have_sibling:
          will.have_sibling === "1" ||
          will.have_sibling === 1 ||
          will.have_sibling === true
            ? true
            : false,
      });

      if (
        will.married_status === "1" ||
        will.married_status === 1 ||
        will.married_status === true
      ) {
        let spouseInfo;
        if (typeof will.spouse_info === "string") {
          spouseInfo = JSON.parse(will.spouse_info);
        } else {
          spouseInfo = will.spouse_info;
        }
        form.setFieldsValue({
          first_name: spouseInfo.first_name,
          middle_name: spouseInfo.middle_name,
          last_name: spouseInfo.last_name,
          date_of_birth: spouseInfo.date_of_birth,
          email: spouseInfo.email,
          phone: spouseInfo.phone,
          address_1: spouseInfo.address_1,
          address_2: spouseInfo.address_2,
          city: spouseInfo.city,
          state: spouseInfo.state,
          county: spouseInfo.county,
          zip: spouseInfo.zip,
        });
      }

      if (
        will.have_children === "1" ||
        will.have_children === 1 ||
        will.have_children === true
      ) {
        let childrenInfo;
        if (typeof will.children_info === "string") {
          childrenInfo = JSON.parse(will.children_info);
        } else {
          childrenInfo = will.children_info;
        }
        // const childrenInfo = JSON.parse(will.children_info)
        setCInitial(childrenInfo);
      }

      if (
        will.have_sibling === "1" ||
        will.have_sibling === 1 ||
        will.have_sibling === true
      ) {
        let siblingInfo;
        if (typeof will.sibling_info === "string") {
          siblingInfo = JSON.parse(will.sibling_info);
        } else {
          siblingInfo = will.sibling_info;
        }
        // const siblingInfo = JSON.parse(will.sibling_info)
        setSInitial(siblingInfo);
      }
    }
  }, [will]);

  const handleSubmit = () => {
    const formErrors = form.getFieldsError().filter(field => {
      if(field.errors.length) return field.errors;
    });

    if(formErrors.length) {
      setErrors(formErrors);
      return;
    }else{
      setErrors([]);
    }

    let values = form.getFieldsValue();

    let validationNamesArray = [];

    if (values.have_children) {
      values.children_info.map((item) => {
        let c_fullName =
          item?.c_first_name +
          " " +
          item?.c_middle_name +
          " " +
          item?.c_last_name;
        validationNamesArray.push(c_fullName);
      });

      values.children_info = JSON.stringify(values.children_info);
    }

    if (values.have_sibling) {
      values.sibling_info.map((item) => {
        let s_fullName =
          item?.s_first_name +
          " " +
          item?.s_middle_name +
          " " +
          item?.s_last_name;
        validationNamesArray.push(s_fullName);
      });

      values.sibling_info = JSON.stringify(values.sibling_info);
    }

    if (values.married_status) {
      const spouse_info = {
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        date_of_birth: values.date_of_birth,
        email: values.email,
        phone: values.phone,
        address_1: values.address_1,
        address_2: values.address_2,
        city: values.city,
        state: values.state,
        county: values.county,
        zip: values.zip,
      };

      let spouse_fullName =
        spouse_info?.first_name +
        " " +
        spouse_info?.middle_name +
        " " +
        spouse_info?.last_name;
      validationNamesArray.push(spouse_fullName);

      values.spouse_info = JSON.stringify(spouse_info);
    }

    // console.log(values.spouse_info);
    // return;

    const willData = {
      user_id: authUser.id,
      married_status: values.married_status,
      spouse_info: values.married_status ? values.spouse_info : null,
      have_children: values.have_children,
      children_info: values.have_children ? values.children_info : null,
      have_sibling: values.have_sibling,
      sibling_info: values.have_sibling ? values.sibling_info : null,
    };

    if (hasDuplicateValue(validationNamesArray)) {
      setNameError(true);
    } else {
      if (edit_open) {
        dispatch(updateWillByUserId(willData, props.history));
        dispatch(changeEditStatus(false));
      } else {
        const selectedPlans = authUser.selected_wills.map(will => will.selected_plans);
        let redirect = "/my-choices";
        // if(selectedPlans.includes("5") || selectedPlans.includes("1")) {
        //   redirect = "/last-will-testament";
        // }
        dispatch(saveWillByUserId(willData, props.history, redirect));
      }
    }
  };

  function hasDuplicateValue(arr) {
    let set = new Set(arr);
    return set.size !== arr.length;
  }

  const goWillRegister = () => {
    history.push("/will-register");
  };

  const onCancelUpdate = () => {
    dispatch(changeEditStatus(false));
    history.push("/review");
  };

  const toggleChildrenAge = (index, value) => {
    togleCAge({...c_age, [index]: value});
  }

  const toggleSiblingAge = (index, value) => {
    togleSAge({...s_age, [index]: value});
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>YourInfo page</title>
      </MetaTags>
      <div style={{ margin: "30px 0px" }}>
        <Container>
          <div>
            <h1 className="fw-normal txt-primary mb-3" style={{ fontSize: 40 }}>
              Family
            </h1>
            <p>Let’s get to know you better</p>
          </div>

          {nameError && (
            <Alert
              message="Error Text"
              description="Names can not be same! please check the name again!"
              type="error"
            />
          )}

          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              name="married_status"
              rules={[{ required: true }]}
              label="Are you Married?"
            >
              <Radio.Group size="large">
                <Radio value={true}> Yes </Radio>
                <Radio value={false}> No </Radio>
              </Radio.Group>
            </Form.Item>

            {married_status === true && (
              <>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name="first_name"
                      rules={[{ required: true }]}
                      label="First Name"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type firstname"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="middle_name"
                      rules={[{}]}
                      label="Middle Name"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type middle name"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="last_name"
                      rules={[{ required: true }]}
                      label="Last Name"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type last name"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name={"date_of_birth"}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="Date of Birth"
                    >
                      <Input
                        size="large"
                        type="date"
                        className="rounded-2"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="E-mail"
                    >
                      <Input
                        size="large"
                        type="email"
                        className="rounded-2"
                        placeholder="Type e-mail"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="Phone"
                    >
                      <Input
                        size="large"
                        type="string"
                        className="rounded-2"
                        placeholder="Type Phone number"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="address_1"
                      rules={[{ required: true }]}
                      label="Address 1"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type Address 1"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item name="address_2" label="Address 2">
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type address 2"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="city"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="City"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type city"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="state"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="State"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type state"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="county"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="County"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type state"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="zip"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="ZIP"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type ZIP"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Form.Item
              name="have_children"
              rules={[{ required: true }]}
              label="Have children?"
            >
              <Radio.Group size="large">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

            {have_children === true && (
              <>
                <h5 className="mb-3">Do you have children?</h5>
                <Form.List initialValue={c_initial} name="children_info">
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map(
                        ({ key, name, value, ...restField }, indx) => (
                          <div
                            key={key}
                            className="d-flex justify-content-between"
                            style={{ gap: 12 }}
                          >
                            <div className="d-flex justify-content-between flex-column flex-grow-1">
                              <Row>
                                <Col xs={12} md={12}>
                                  <Form.Item
                                    name={[name, "c_is_minor"]}
                                    label=""
                                    initialValue="0"
                                  >
                                    <Radio.Group
                                      value="0"
                                      size="large"
                                      onChange={(e) => toggleChildrenAge(indx, e.target.value)}
                                    >
                                      <Radio value="0">Minor</Radio>
                                      <Radio value="1">Adult (18+)</Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row>
                                <Col xs={12} md={6}>
                                  <Form.Item
                                    name={[name, "c_first_name"]}
                                    rules={[{ required: true }]}
                                    label="First Name"
                                  >
                                    <Input
                                      size="large"
                                      className="rounded-2"
                                      placeholder="Type firstname"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={12} md={6}>
                                  <Form.Item
                                    name={[name, "c_middle_name"]}
                                    rules={[{}]}
                                    label="Middle Name"
                                  >
                                    <Input
                                      size="large"
                                      className="rounded-2"
                                      placeholder="Type middle name"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={12} md={6}>
                                  <Form.Item
                                    name={[name, "c_last_name"]}
                                    rules={[{ required: true }]}
                                    label="Last Name"
                                  >
                                    <Input
                                      size="large"
                                      className="rounded-2"
                                      placeholder="Type last name"
                                    />
                                  </Form.Item>
                                </Col>

                                <>
                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      name={[name, "c_email"]}
                                      rules={[
                                        {
                                          required: (c_age[indx] && c_age[indx] != "0"),
                                        },
                                      ]}
                                      label="E-mail"
                                    >
                                      <Input
                                        size="large"
                                        type="email"
                                        className="rounded-2"
                                        placeholder="Type e-mail"
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col xs={12} md={6}>
                                    <Form.Item
                                      name={[name, "c_phone"]}
                                      rules={[
                                        {
                                          required: (c_age[indx] && c_age[indx] != "0"),
                                        },
                                      ]}
                                      label="Phone"
                                    >
                                      <Input
                                        size="large"
                                        type="string"
                                        className="rounded-2"
                                        placeholder="Type Phone number"
                                      />
                                    </Form.Item>
                                  </Col>
                                </>

                                <Col xs={12} md={6}>
                                  <Form.Item
                                    name={[name, "c_date_of_birth"]}
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                    label="Date Of Birth"
                                  >
                                    <Input
                                      size="large"
                                      type="date"
                                      className="rounded-2"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={12} md={6}>
                                  <Form.Item
                                    name={[name, "c_address_1"]}
                                    rules={[{ required: true }]}
                                    label="Address 1"
                                  >
                                    <Input
                                      size="large"
                                      className="rounded-2"
                                      placeholder="Type Address 1"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={12} md={6}>
                                  <Form.Item
                                    name={[name, "c_address_2"]}
                                    label="Address 2"
                                  >
                                    <Input
                                      size="large"
                                      className="rounded-2"
                                      placeholder="Type address 2"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={12} md={6}>
                                  <Form.Item
                                    name={[name, "c_city"]}
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                    label="City"
                                  >
                                    <Input
                                      size="large"
                                      className="rounded-2"
                                      placeholder="Type city"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={12} md={6}>
                                  <Form.Item
                                    name={[name, "c_state"]}
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                    label="State"
                                  >
                                    <Input
                                      size="large"
                                      className="rounded-2"
                                      placeholder="Type state"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={12} md={6}>
                                  <Form.Item
                                    name={[name, "c_county"]}
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                    label="County"
                                  >
                                    <Input
                                      size="large"
                                      className="rounded-2"
                                      placeholder="Type state"
                                    />
                                  </Form.Item>
                                </Col>

                                <Col xs={12} md={6}>
                                  <Form.Item
                                    name={[name, "c_zip"]}
                                    rules={[
                                      {
                                        required: true,
                                      },
                                    ]}
                                    label="ZIP"
                                  >
                                    <Input
                                      size="large"
                                      className="rounded-2"
                                      placeholder="Type ZIP"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Divider className="mt-0 mb-4 pb-2" />
                            </div>

                            <Button
                              type="primary primary-btn"
                              size="large"
                              onClick={() => remove(indx)}
                              icon={
                                <FeatherIcon
                                  role="button"
                                  icon="x"
                                  className="icon-lg flex-shrink-0"
                                />
                              }
                            />
                          </div>
                        )
                      )}

                      <Button
                        type="primary primary-btn px-3 mb-4"
                        size="large"
                        onClick={() => add({})}
                      >
                        + ADD NEW
                      </Button>
                    </div>
                  )}
                </Form.List>
              </>
            )}

            <Form.Item
              name="have_sibling"
              rules={[{ required: true }]}
              label="Do you have siblings?"
            >
              <Radio.Group size="large">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

            {have_sibling === true && (
              <>
                <h5 className="mb-3">List Siblings</h5>
                <Form.List initialValue={s_initial} name="sibling_info">
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map(({ key, name, ...restField }, indx) => (
                        <div
                          key={key}
                          className="d-flex justify-content-between"
                          style={{ gap: 12 }}
                        >
                          <div className="d-flex justify-content-between flex-column flex-grow-1">
                            <Row>
                              <Col xs={12} md={12}>
                                <Form.Item
                                  name={[name, "s_is_minor"]}
                                  label=""
                                  initialValue="0"
                                >
                                  <Radio.Group
                                    value="0"
                                    size="large"
                                    onChange={(e) => toggleSiblingAge(indx, e.target.value)}
                                  >
                                    <Radio value="1">Minor</Radio>
                                    <Radio value="0">Adult (18+)</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[name, "s_first_name"]}
                                  rules={[{ required: true }]}
                                  label="First Name"
                                >
                                  <Input
                                    size="large"
                                    className="rounded-2"
                                    placeholder="Type firstname"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[name, "s_middle_name"]}
                                  rules={[{}]}
                                  label="Middle Name"
                                >
                                  <Input
                                    size="large"
                                    className="rounded-2"
                                    placeholder="Type middle name"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[name, "s_last_name"]}
                                  rules={[{ required: true }]}
                                  label="Last Name"
                                >
                                  <Input
                                    size="large"
                                    className="rounded-2"
                                    placeholder="Type last name"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[name, "s_email"]}
                                  rules={[
                                    {
                                      required: (!s_age[indx] || s_age[indx] == '0'),
                                    },
                                  ]}
                                  label="E-mail"
                                >
                                  <Input
                                    size="large"
                                    type="email"
                                    className="rounded-2"
                                    placeholder="Type e-mail"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[name, "s_phone"]}
                                  rules={[
                                    {
                                      required: (!s_age[indx] || s_age[indx] == '0'),
                                    },
                                  ]}
                                  label="Phone"
                                >
                                  <Input
                                    size="large"
                                    type="string"
                                    className="rounded-2"
                                    placeholder="Type Phone number"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[name, "s_date_of_birth"]}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                  label="Date of Birth"
                                >
                                  <Input
                                    size="large"
                                    type="date"
                                    className="rounded-2"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[name, "s_address_1"]}
                                  rules={[{ required: true }]}
                                  label="Address 1"
                                >
                                  <Input
                                    size="large"
                                    className="rounded-2"
                                    placeholder="Type Address 1"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[name, "s_address_2"]}
                                  label="Address 2"
                                >
                                  <Input
                                    size="large"
                                    className="rounded-2"
                                    placeholder="Type address 2"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[name, "s_city"]}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                  label="City"
                                >
                                  <Input
                                    size="large"
                                    className="rounded-2"
                                    placeholder="Type city"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[name, "s_state"]}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                  label="State"
                                >
                                  <Input
                                    size="large"
                                    className="rounded-2"
                                    placeholder="Type state"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[name, "s_county"]}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                  label="County"
                                >
                                  <Input
                                    size="large"
                                    className="rounded-2"
                                    placeholder="Type County"
                                  />
                                </Form.Item>
                              </Col>

                              <Col xs={12} md={6}>
                                <Form.Item
                                  name={[name, "s_zip"]}
                                  rules={[
                                    {
                                      required: true,
                                    },
                                  ]}
                                  label="ZIP"
                                >
                                  <Input
                                    size="large"
                                    className="rounded-2"
                                    placeholder="Type ZIP"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Divider className="mt-0 mb-4 pb-2" />
                          </div>

                          <Button
                            type="primary primary-btn"
                            size="large"
                            onClick={() => remove(indx)}
                            icon={
                              <FeatherIcon
                                role="button"
                                icon="x"
                                className="icon-lg flex-shrink-0"
                              />
                            }
                          />
                        </div>
                      ))}

                      <Button
                        type="primary primary-btn px-3 mb-4"
                        size="large"
                        onClick={() => add({})}
                      >
                        + ADD NEW
                      </Button>
                    </div>
                  )}
                </Form.List>
              </>
            )}

            {errors.length ? (
              <div className="errors-block">
                <ul>
                  {errors.map((error, i) => {
                    return <li key={i}>{error.errors[0]}</li>
                  })}
                </ul>
              </div>
            ) : ''}

            {edit_open ? (
              <div className="d-flex">
                <Button
                  type="primary px-3 fw-bold mt-3"
                  size="large"
                  danger
                  style={{ marginRight: 20 }}
                  onClick={onCancelUpdate}
                >
                  CANCEL
                </Button>
                <Button
                  htmlType="submit"
                  type="primary primary-btn px-3 fw-bold mt-3"
                  size="large"
                  loading={loading}
                >
                  UPDATE
                </Button>
              </div>
            ) : (
              <div className="footer-button d-flex justify-content-between mt-4">
                <Button
                  type="primary primary-btn px-3 fw-bold mt-2"
                  size="large"
                  onClick={goWillRegister}
                >
                  Previous
                </Button>

                <Button
                  type="primary primary-btn px-3 fw-bold mt-2"
                  size="large"
                  htmlType="button"
                  loading={loading}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </div>
            )}
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default YourInfo;
