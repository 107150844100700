import { Button, Checkbox, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Col, Container, Row } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  registerWillUser,
  updateWillUser,
  changeEditStatus,
} from "../store/actions";

const WillRegister = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [birthday, setBirthday] = useState(null);
  const [formData, setFormData] = useState({});
  const [tAndC, toggleTandC] = useState(false);

  const { authUser, register_error } = useSelector((state) => state.User);
  const { edit_open } = useSelector((state) => state.Will);

  const [formErrors, setFormErrors] = useState([]);

  const onFinishFailed = (errorInfo) => {
    const errors = errorInfo.errorFields.map(error => {
      return error.errors[0];
    })
    setFormErrors(errors);
  };

  useEffect(() => {
    if(register_error){
      const keys = Object.keys(register_error);

      const errors = keys.map(key => {
        return register_error[key][0];
      });
      setFormErrors(errors);
    }
  }, register_error);

  useEffect(() => {
    if (edit_open) {
      const formData = {
        first_name: authUser?.first_name,
        middle_name: authUser?.middle_name,
        last_name: authUser?.last_name,
        suffix: authUser?.suffix,
        email: authUser?.email,
        phone: authUser?.phone,
        residential_address: authUser?.residential_address,
        address2: authUser?.address2,
        city: authUser?.city,
        state: authUser?.state,
        zip: authUser?.zip,
      };
      form.setFieldsValue(formData);
      setFormData(formData);
      setBirthday(authUser?.date_of_birth);
    }
  }, [edit_open]);

  const [form] = Form.useForm();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (values) => {
    formData.date_of_birth = birthday;

    if (edit_open) {
      formData.id = authUser.id;
      dispatch(updateWillUser(formData, props.history));
      dispatch(changeEditStatus(false));
    } else {
      dispatch(registerWillUser(formData, props.history));
    }
  };

  const handleBirthdayChange = (event) => {
    setBirthday(event.target.value);
  };

  const onCancelUpdate = () => {
    dispatch(changeEditStatus(false));
    history.push("/review");
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title> WillRegister page </title>
      </MetaTags>
      <div style={{ margin: "45px 0px" }}>
        <Container>
          <h1 className="fw-medium txt-primary mb-4" style={{ fontSize: 30 }}>
            Let's get started!
          </h1>

          <Form
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Row>
              <Col xs={12} md={6}>
                <Form.Item
                  name="first_name"
                  rules={[{ required: true }]}
                  label="First Name"
                >
                  <Input
                    name="first_name"
                    size="large"
                    className="rounded-2"
                    placeholder="Type firstname"
                    onChange={handleInputChange}
                  />
                  {register_error?.first_name &&
                    register_error?.first_name?.map((error, index) => {
                      return (
                        <label className="error-text" key={index}>
                          {error}
                        </label>
                      );
                    })}
                </Form.Item>
              </Col>

              <Col xs={12} md={6}>
                <Form.Item name="middle_name" rules={[{}]} label="Middle Name">
                  <Input
                    name="middle_name"
                    size="large"
                    className="rounded-2"
                    placeholder="Type middle name"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} md={6}>
                <Form.Item
                  name="last_name"
                  rules={[{ required: true }]}
                  label="Last Name"
                >
                  <Input
                    name="last_name"
                    size="large"
                    className="rounded-2"
                    placeholder="Type last name"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} md={6}>
                <Form.Item name="suffix" label="Suffix">
                  <Input
                    name="suffix"
                    size="large"
                    className="rounded-2"
                    placeholder="Type suffix"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} md={6}>
                <label>Date of BirthDay</label>
                <Input
                  className="form-control"
                  type="date"
                  defaultValue={edit_open ? authUser?.date_of_birth : ""}
                  id="date_of_birth"
                  onChange={handleBirthdayChange}
                />
                {register_error?.date_of_birth &&
                  register_error?.date_of_birth?.map((error, index) => {
                    return (
                      <label className="error-text" key={index}>
                        {error}
                      </label>
                    );
                  })}
              </Col>

              <Col xs={12} md={6}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label="E-mail"
                >
                  <Input
                    name="email"
                    size="large"
                    type="email"
                    className="rounded-2"
                    placeholder="Type e-mail"
                    onChange={handleInputChange}
                  />

                  {register_error?.email &&
                    register_error?.email?.map((error, index) => {
                      return (
                        <label className="error-text" key={index}>
                          {error}
                        </label>
                      );
                    })}
                </Form.Item>
              </Col>

              <Col xs={12} md={6}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label="Phone"
                >
                  <Input
                    name="phone"
                    size="large"
                    type="string"
                    className="rounded-2"
                    placeholder="Type Phone number"
                    onChange={handleInputChange}
                  />

                  {register_error?.phone &&
                    register_error?.phone?.map((error, index) => {
                      return (
                        <label className="error-text" key={index}>
                          {error}
                        </label>
                      );
                    })}
                </Form.Item>
              </Col>

              <Col xs={12} md={6}>
                <Form.Item
                  name="residential_address"
                  //  rules={[{ required: true }]}
                  label="Residential Address"
                >
                  <Input
                    name="residential_address"
                    size="large"
                    className="rounded-2"
                    placeholder="Type Residential Address"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} md={6}>
                <Form.Item name="address2" label="Address 2">
                  <Input
                    name="address2"
                    size="large"
                    className="rounded-2"
                    placeholder="Type address 2"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} md={6}>
                <Form.Item
                  name="city"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label="City"
                >
                  <Input
                    name="city"
                    size="large"
                    className="rounded-2"
                    placeholder="Type city"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} md={6}>
                <Form.Item
                  name="state"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label="State"
                >
                  <Input
                    name="state"
                    size="large"
                    className="rounded-2"
                    placeholder="Type state"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} md={6}>
                <Form.Item
                  name="zip"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label="ZIP"
                >
                  <Input
                    name="zip"
                    size="large"
                    className="rounded-2"
                    placeholder="Type ZIP"
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Col>

              {!edit_open && (
                <>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="Password"
                    >
                      <Input.Password
                        name="password"
                        size="large"
                        className="rounded-2"
                        placeholder="Type password"
                        onChange={handleInputChange}
                      />
                      {register_error?.password &&
                        register_error?.password?.map((error, index) => {
                          return (
                            <label className="error-text" key={index}>
                              {error}
                            </label>
                          );
                        })}
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="password_confirmation"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="Confirm Password"
                    >
                      <Input.Password
                        name="password_confirmation"
                        size="large"
                        className="rounded-2"
                        placeholder="Type Confirm Password"
                        onChange={handleInputChange}
                      />
                      {register_error?.password &&
                        register_error?.password?.map((error, index) => {
                          return (
                            <label className="error-text" key={index}>
                              {error}
                            </label>
                          );
                        })}
                    </Form.Item>
                  </Col>

                  <Col md={12}>
                    {!edit_open && (
                      <h5 className="fw-normal">
                        <input type="checkbox" onClick={() => toggleTandC(!tAndC)} /> I agree to{" "}
                        <a href="#">Terms and Conditions</a>
                      </h5>
                    )}
                  </Col>
                </>
              )}

              <Col xs={12}>
                <Form.Item>
                  {edit_open ? (
                    <div className="d-flex">
                      <Button
                        type="primary px-3 fw-bold mt-3"
                        size="large"
                        danger
                        style={{ marginRight: 20 }}
                        onClick={onCancelUpdate}
                      >
                        CANCEL
                      </Button>
                      <Button
                        htmlType="submit"
                        type="primary primary-btn px-3 fw-bold mt-3"
                        size="large"
                      >
                        UPDATE
                      </Button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <Button
                        htmlType="submit"
                        type="primary primary-btn px-5 py-4 fw-bold mt-3"
                        size="large"
                        disabled={!tAndC}
                      >
                        {/* Step 2 | CHOOSE YOUR DOCUMENTS */}
                        Step 2 | CHOOSE YOUR PLAN
                      </Button>
                    </div>
                  )}
                </Form.Item>
              </Col>

              <Col xs={12}>
                {formErrors.length ? (
                  <div className="errors-block">
                    <ul>
                      {formErrors.map((error, i) => {
                        return <li key={i}>{error}</li>;
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            {!edit_open && (
              <div className="text-center">
                <p
                  className="mb-0"
                  style={{ color: "#000000", fontSize: 18, paddingTop: 60 }}
                >
                  Do you have an account?
                  <Link
                    to="/login"
                    style={{ color: "#0A81A9", fontWeight: "bold" }}
                  >
                    {" "}
                    Login
                  </Link>
                </p>
              </div>
            )}
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WillRegister;
