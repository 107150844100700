import {
  GET_WILL_BY_USERID,
  GET_WILL_BY_USERID_SUCCESS,
  GET_WILL_BY_USERID_FAIL,
  SAVE_WILL_BY_USERID,
  SAVE_WILL_BY_USERID_SUCCESS,
  CHANGE_EDIT_STATUS,
  UPDATE_WILL_BY_USERID,
  UPDATE_USER_SELECTED_PLANS
} from "./actionTypes"

const INIT_STATE = {
  wills: [],
  will: null,
  error: null,
  loading: false,
  currentStep: 0,
  edit_open: false,
  selected_plans: [],
}

const wills = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WILL_BY_USERID:
      return {
        ...state,
        loading: true,
      }
    case GET_WILL_BY_USERID_SUCCESS:
      return {
        ...state,
        loading: false,
        will: action.payload
      }

    case GET_WILL_BY_USERID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case SAVE_WILL_BY_USERID:
      return {
        ...state,
        loading: true,
      }
    case SAVE_WILL_BY_USERID_SUCCESS:
      return {
        ...state,
        loading: false,
        will: action.payload
      }

    case CHANGE_EDIT_STATUS:
      return {
        ...state,
        edit_open: action.payload
      }

    case UPDATE_WILL_BY_USERID:
      return {
        ...state,
        loading: true,
      }
    
    case UPDATE_USER_SELECTED_PLANS:
      return {
        ...state,
        selected_plans: action.payload
      }

    default:
      return state
  }
}

export default wills
