import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const LivingWill = ({ willData, authUser }) => {
  const [surrogate1Data, setSurrogate1Data] = useState(null);
  const [surrogate2Data, setSurrogate2Data] = useState(null);

  useEffect(() => {
    findSurrogateData(willData);
  }, []);

  const findSurrogateData = (willData) => {
    const surrogate_1 = willData.surrogate_1;
    const surrogate_2 = willData.surrogate_2;

    if (
      willData.married_status === "1" ||
      willData.married_status === 1 ||
      willData.married_status === true
    ) {
      let spouse_full_name =
        willData.spouse_info.first_name +
        " " +
        willData.spouse_info.middle_name +
        " " +
        willData.spouse_info.last_name;

      if (surrogate_1 === spouse_full_name) {
        const surroage1_data = {
          name: surrogate_1,
          address: `${willData.spouse_info.address_1}, ${
            willData.spouse_info.city || ""
          }, ${willData.spouse_info.state || ""}, ${
            willData.spouse_info.country || ""
          } - ${
            willData.spouse_info.zip || ""
          }`,
          phone: willData.spouse_info.phone,
          email: willData.spouse_info.email,
        };
        setSurrogate1Data(surroage1_data);
      }

      if (surrogate_2 === spouse_full_name) {
        const surroage2_data = {
          name: surrogate_2,
          address: `${willData.spouse_info.address_1}, ${
            willData.spouse_info.city || ""
          }, ${willData.spouse_info.state || ""}, ${
            willData.spouse_info.country || ""
          } - ${
            willData.spouse_info.zip || ""
          }`,
          phone: willData.spouse_info.phone,
          email: willData.spouse_info.email,
        };
        setSurrogate2Data(surroage2_data);
      }
    }

    if (
      willData.have_children === "1" ||
      willData.have_children === 1 ||
      willData.have_children === true
    ) {
      willData.children_info.map((item) => {
        const c_full_name =
          item.c_first_name +
          " " +
          (item.c_middle_name || "") +
          " " +
          item.c_last_name;

        if (surrogate_1 === c_full_name) {
          const surroage1_data = {
            name: surrogate_1,
            address: `${item.c_address_1}, ${item?.c_city}, ${item?.c_state}, ${item?.c_county} - ${item?.c_zip}`,
            phone: item.c_phone,
            email: item.c_email,
          };
          setSurrogate1Data(surroage1_data);
        }

        if (surrogate_2 === c_full_name) {
          const surroage2_data = {
            name: surrogate_2,
            address: `${item.c_address_1}, ${item?.c_city}, ${item?.c_state}, ${item?.c_county} - ${item?.c_zip}`,
            phone: item.c_phone,
            email: item.c_email,
          };
          setSurrogate2Data(surroage2_data);
        }
      });
    }

    if (
      willData.have_sibling === "1" ||
      willData.have_sibling === 1 ||
      willData.have_sibling === true
    ) {
      willData.sibling_info.map((item) => {
        const s_full_name =
          item.s_first_name +
          " " +
          (item.s_middle_name || "") +
          " " +
          item.s_last_name;
          console.log(s_full_name, surrogate_1, surrogate_2);

        if (surrogate_1 === s_full_name) {
          const surroage1_data = {
            name: surrogate_1,
            address: `${item.s_address_1}, ${item?.s_city}, ${item?.s_state}, ${item?.s_county} - ${item?.s_zip}`,
            phone: item.s_phone,
            email: item.s_email,
          };
          setSurrogate1Data(surroage1_data);
        }

        if (surrogate_2 === s_full_name) {
          const surroage2_data = {
            name: surrogate_2,
            address: `${item.s_address_1}, ${item?.s_city}, ${item?.s_state}, ${item?.s_county} - ${item?.s_zip}`,
            phone: item.s_phone,
            email: item.s_email,
          };
          setSurrogate2Data(surroage2_data);
        }
      });
    }
  };

  const styles = StyleSheet.create({
    title: {
    //  fontFamily: "TimesNewRomen",
      fontSize: 16,
      textAlign: "center",
      textDecorationColor: "#000000",
      textDecoration: "underline",
      fontWeight: "800",
    },
    points: {
      fontSize: 13,
      lineHeight: 1.4,
      marginVertical: 10,
    },
    page: {
      paddingHorizontal: 20,
      paddingVertical: 40,
    },
    pageFooter: {
      fontSize: 9,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
    },
    pageBreak: {
      pageBreakBefore: "always",
    },
    check_container: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 5,
    },
    checkbox: {
      width: 10,
      height: 10,
      marginRight: 5,
      borderWidth: 1,
      borderColor: "black",
    },
  });

  const Checkbox = ({ label }) => {
    return (
      <View style={styles.check_container}>
        <View style={styles.checkbox} fixed />
        <Text style={{ fontSize: 15 }}>{label}</Text>
      </View>
    );
  };

  const relationShipStatus = (married_status) => {
    if (married_status == 0) {
      return "SINGLE";
    } else if (married_status == 1) {
      return "MARRIED";
    } else {
      return "WIDOWED";
    }
  };

  const address = (data) => {
    let add = data?.address_1
      .concat(`, ${data?.city}`)
      .concat(`, ${data?.state}`)
      .concat(`, ${data?.zip}`);

    return add;
  };

  const childAddress = (data) => {
    let add = data?.c_address_1
      .concat(`, ${data?.c_city}`)
      .concat(`, ${data?.c_state}`)
      .concat(`, ${data?.c_zip}`);

    return add;
  };

  const siblingAddress = (data) => {
    let add = data?.s_address_1
      .concat(`, ${data?.s_city}`)
      .concat(`, ${data?.s_state}`)
      .concat(`, ${data?.s_zip}`);

    return add;
  };

  const testatorName = () => {
    return `${authUser.first_name} ${authUser.middle_name || ""} ${
      authUser.last_name
    }`;
  };

  const testatorAddress = () => {
    return `${authUser.residential_address}, ${authUser.city}, ${authUser.state}, ${authUser.county} - ${authUser.zip}`;
  };

  return (
    <>
      {authUser && willData && (
        <>
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <Text
                  wrap={true}
                  style={{
                    fontSize: 20,
                    textAlign: "center",
                    textDecoration: "underline",
                    textDecorationColor: "#000000",
                  }}
                >
                  DECLARATION OF LIVING WILL
                </Text>

                <Text wrap={true} style={styles.points}>
                  To my physician and my family:
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`I, ${testatorName() || ""}, of ${
                    testatorAddress() || ""
                  }, am currently in good health and am of sound mind and fully competent. However, I am aware of the vicissitudes of life and the possibility that, through accident or sickness, I may become so ill that my life could be prolonged only by "heroic measures" and "unnatural” means such as the use of life sustaining equipment. I willfully and voluntarily make known my desire that my dying shall not be artificially prolonged under the circumstances set forth below and do hereby declare:`}
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`I strongly believe that the prolonging of my life by such "unnatural" means in certain circumstances would be of no benefit to myself and would be an emotional and financial hardship on my family.`}
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`Therefore, pursuant to the provisions of Section 765.303 of the Florida Statutes I expressly declare my intentions and desire that if at any time I should have a terminal condition and my physician has determined that there can be no reasonable expectation of recovery from such condition and my death is imminent, where the application of life prolonging procedures would serve only to artificially prolong the dying process or a condition of grave physical illness which is accompanied by irreversible mental deterioration, I direct and order my physician, or any physician in charge of my care, that such procedures be withheld or withdrawn and that I be permitted to die naturally with only the administration of medication or the performance of any medical procedure deemed necessary to provide me with comfort care or to alleviate pain, even though this may hasten the moment of my death.`}
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`Although I may be considered mentally incompetent at such a time, I must nevertheless be informed of my situation and, if I express the desire to be treated, I am to be treated in spite of this direction.`}
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`In the absence of my ability to give directions regarding the use of such life prolonging procedures, it is my intention and desire that this Declaration shall be honored by my family and physician as the final expression of my legal right to refuse medical or surgical treatment and accept the consequences for such refusal.`}
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`If there is any reasonable doubt of the diagnosis of my illness and prognosis, then consultation with available specialists is suggested but is not mandatory.`}
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`This directive to my physician also applies to any hospital or sanitarium in which I may be at the time of my illness, and I (individually and on behalf of my legal representatives, heirs, and assigns) hereby release and relieve such institution of any and all responsibility in the action or lack of action of any physician acting in accordance with this directive.`}
                </Text>

                <View style={styles.pageBreak} />

                <Text
                  wrap={true}
                  style={{ lineHeight: 1.2, fontSize: 14, marginTop: 40 }}
                >
                  {`If any action is taken contrary to these expressed directions) I hereby request that my next-of-kin or legal representative consider (and, if necessary, take) legal action against those person involved in such action.`}
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`If any of my next-of-kin oppose this directive, their objection is to be considered without legal grounds, since I hereby negate any right of such next-of-kin who oppose my desires as herein set forth to speak on my behalf.`}
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`I (individually and on behalf of my legal representatives, heirs and assigns) hereby expressly absolve my physician, or any physician taking care of me, and my nest-of-kin from any liability pertaining to compliance with the directives stated herein.`}
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`In the event that I have been determined to be unable to provide express and informed consent regarding the withholding, withdrawal, or continuation of life-prolonging procedures, I wish to designate ${willData?.surrogate_1}, as my surrogate to carry out the provisions of the declaration:`}
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`Name:     ${surrogate1Data?.name || ""}`}
                  {`\nAddress:     ${surrogate1Data?.address || ""}`}
                  {`\nPhone:     ${surrogate1Data?.phone || ""}`}
                  {`\nEmail:     ${surrogate1Data?.email || ""}`}
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`If my surrogate is unwilling or unable to perform his or her duties, I wish to designate ${
                    willData?.surrogate_2 || ""
                  }, as my alternate surrogate:`}
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`Name:     ${surrogate2Data?.name || ""}`}
                  {`\nAddress:     ${surrogate2Data?.address || ""}`}
                  {`\nPhone:     ${surrogate2Data?.phone || ""}`}
                  {`\nEmail:     ${surrogate2Data?.email || ""}`}
                </Text>

                <Text
                  wrap={true}
                  style={{ lineHeight: 1.2, fontSize: 14 }}
                ></Text>
                <Text
                  wrap={true}
                  style={{ lineHeight: 1.2, fontSize: 14 }}
                ></Text>
                <Text
                  wrap={true}
                  style={{ lineHeight: 1.2, fontSize: 14 }}
                ></Text>

                <Text wrap={true} style={styles.points}>
                  {`The undersigned hereby makes this anatomical gift, if medically acceptable, to take effect on death. The words and marks below indicate my desires: I give:`}
                  {"\n"}a) NONE. 
                  {"\n"}b) __________________________
                  {"\n"}&nbsp;&nbsp;&nbsp;&nbsp;Specify the organ(s) or part(s). 
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`I understand the full import of this Declaration and am emotionally and mentally competent to make this Declaration. This Declaration shall remain in full force and effect unless revoked by me physical destruction of this document.`}
                </Text>

                <Text wrap={true} style={styles.points}>
                  {`I HAVE DECLARED this instrument to be my declaration, and I have signed it at this ___ day of __________________20___. `}
                </Text>

                <Text
                  wrap={true}
                  style={{
                    ...styles.points,
                    float: "right",
                    textAlign: "right"
                  }}
                >
                  {`___________________________________`}
                  {`\n${testatorName() || ""}`}
                </Text>
              </View>

              <View style={styles.section}>
                <Text wrap={true} style={styles.points}>
                  {`On this ___ day of __________________20___, and in our presence, the foregoing instrument was declared by ${
                    testatorName() || ""
                  } to be their Declaration, and it was signed by them. We have subscribed our names as witnesses at the request of the declarant, in the presence of the declarant and in the presence of each other after the declarant has signed their name. The declarant is known to us and we believe that they are of sound mind.`}
                </Text>

                <Text
                  wrap={true}
                  style={styles.points}
                >{`SIGNED, SEALED, AND DELIVERED \n IN THE PRESENCE OF:`}</Text>
                <Text
                  wrap={true}
                  style={{ lineHeight: 1.2, fontSize: 16 }}
                >{``}</Text>

                <Text
                  wrap={true}
                  style={styles.points}
                >{`Witness #1 Name:		        ___________________________`}
                {`\nWitness #1 Signature:		  ___________________________`}
                {`\nWitness #1 Address: 			  ___________________________`}
                </Text>

                <Text
                  wrap={true}
                  style={styles.points}
                >{`Witness #2 Name: 			      ___________________________`}
                {`\nWitness #2 Signature: 		  ___________________________`}
                {`\nWitness #2 Address: 	 		  ___________________________`}</Text>
              </View>
            </Page>

            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <Text
                  wrap={true}
                  style={styles.points}
                >{`STATE OF FLORIDA\nCOUNTY OF _______________________`}</Text>
                <Text
                  wrap={true}
                  style={{ lineHeight: 1.2, fontSize: 15 }}
                ></Text>

                <Text wrap={true} style={styles.points}>
                  {`We, ${
                    testatorName() || ""
                  }, ___________________________ (witness #1) and __________________________ (witness #2), the declarant and the witnesses respectively, whose names are signed to the attached or foregoing instrument, having been sworn, declared to the undersigned officer that the declarant signed the instrument as their Declaration, that they signed, and that each of the witnesses at the request of the declarant, in the presence of the declarant and in the presence of each other, signed the Declaration as a witness. `}
                </Text>
                <Text
                  wrap={true}
                  style={styles.points}
                >
                  {`${testatorName() || ""}`}
                  {`\nWitness #1`}
                  {`\nWitness #2`}
                </Text>

                <Text
                  wrap={true}
                  style={styles.points}
                >{`STATE OF FLORIDA\nCOUNTY OF _______________________`}</Text>

                <Text
                  wrap={true}
                  style={styles.points}
                >{`The foregoing instrument was acknowledged before me by means of `}</Text>
                <Checkbox label="physical presence or " />
                <Checkbox label={`online notarization, this ____ day of _______________ 20___ by ${testatorName()}, the foregoing,`}/>

                <Text
                  wrap={true}
                  style={{
                    lineHeight: 1.2,
                    fontSize: 14,
                    marginTop: 20,
                    marginLeft: 200,
                  }}
                >{`_____________________________________`}</Text>
                <Text
                  wrap={true}
                  style={{ lineHeight: 1.2, fontSize: 14, marginLeft: 200 }}
                >{`Notary Public`}</Text>

                <Text
                  wrap={true}
                  style={{
                    lineHeight: 1.2,
                    fontSize: 14,
                    marginTop: 20,
                    marginLeft: 200,
                  }}
                >{`_____________________________________`}</Text>
                <Text
                  wrap={true}
                  style={{ lineHeight: 1.2, fontSize: 14, marginLeft: 200 }}
                >{`Name typed, printed or stamped`}</Text>
                <Text
                  wrap={true}
                  style={{ lineHeight: 1.2, fontSize: 14, marginLeft: 200 }}
                >{`My Commission Expires: ____________`}</Text>
              </View>
            </Page>
          </Document>
        </>
      )}
    </>
  );
};

export default LivingWill;
