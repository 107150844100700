import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import {adminRoutes, userAuthRoutes, userRoutes, adminAuthRoutes } from "./routes/allRoutes";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import AdminAuthmiddleware from "./routes/middleware/AdminAuthmiddleware";
import AdminNoAuthmiddleware from './routes/middleware/AdminNoAuthmiddleware'
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import VerticalLayout from "./components/VerticalLayout/";
import "./assets/scss/preloader.scss";
import "./assets/scss/theme.scss";
import UserLayout from "./components/UserLayout/index";

import { useLocation } from 'react-router-dom';

function ScrollToTopOnRouteChange() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null; // This component doesn't render anything
}

const App = (props) => {

  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Router>
        <ScrollToTopOnRouteChange />
        <Switch>
          {userAuthRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={UserLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={UserLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}

          {adminRoutes.map((route, idx) => (
            <AdminAuthmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}

          {adminAuthRoutes.map((route, idx) => (
            <AdminNoAuthmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
