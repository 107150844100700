import { Button } from "antd";
import React from "react";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"
// import { loginWillUser } from "../store/actions"
import { Row, Col, Container, Alert } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"


const ForgotPassword = (props) => {

  const { loading } = useSelector(state => state.User)

  const handleValidSubmit = (event, values) => {
    console.log('values =>', values)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> Fogot Password Page </title>
      </MetaTags>
      <div style={{ margin: "45px 0px" }}>
        <Container>
          <h1 className="fw-normal txt-primary mb-4" style={{ fontSize: 40 }}>
            Please Send Email To Recover Password!
          </h1>
          <Row>
            <Col xs={12} md={6}>
              <AvForm
                className="custom-form mt-4 pt-2"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email"
                    className="form-control"
                    placeholder="Enter email"
                    type="email"
                    required
                  />
                </div>

                <div className="mb-3">
                  <Button className="btn btn-primary w-100 waves-effect waves-light" htmlType="submit" loading={loading} style={{ height: 45 }}> Reset </Button>
                </div>
                <div className="mt-5 text-center">
                  <p className="text-muted mb-0">Remember Password ?  <Link to="/login">
                    <a href="#"
                      className="text-primary fw-semibold"> Sign In </a>
                  </Link> </p>
                </div>
              </AvForm>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
