import React from "react";
import { Redirect } from "react-router-dom";
//Dashboard
import Dashboard from "../pages/admin/Dashboard/index";
import UserList from "../pages/admin/UserList/index";

// Authentication related pages
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
import userProfile from "../pages/Authentication/user-profile";

import Home from "../pages/Home.jsx";
import WillRegister from "../pages/WillRegister.jsx";
import UserLogin from "../pages/UserLogin.jsx";
import ForgotPassword from '../pages/ForgotPassword.jsx'
import Error404 from '../pages/Error404.jsx'

import YourInfo from "../pages/YourInfo";
import Surrogate from '../pages/Surrogate'
import Review from '../pages/Review'
import Preview from '../pages/Preview'
import Profile from '../pages/Profile'
import ChoosePlan from "../pages/ChoosePlan";

import LastWillAndTestament from "../pages/LastWillAndTestament.jsx";
import MyChoices from "../pages/MyChoices.jsx";

const adminRoutes = [
  { path: "/admin/dashboard", component: Dashboard },
  { path: "/admin/users", component: UserList },
  { path: "/admin/profile", component: userProfile },
  { path: "/admin", exact: true, component: () => <Redirect to="/admin/dashboard" /> },
];

const adminAuthRoutes = [
  { path: "/admin/logout", component: Logout },
  { path: "/admin/login", component: Login },
  { path: "/admin/forgot-password", component: ForgetPwd },
  // { path: "/admin/register", component: Register },
];

const userRoutes = [
  { path: "/choose-plan", component: ChoosePlan },
  { path: "/your-info", component: YourInfo },
  { path: "/last-will-testament", component: LastWillAndTestament },
  { path: "/my-choices", component: MyChoices },
  { path: "/surrogate", component: Surrogate },
  { path: "/review", component: Review },
  { path: '/pre-view', component: Preview},
  { path: '/profile', component: Profile}
];

const userAuthRoutes = [
  { path: "/home", component: Home },
  { path: "/will-register", component: WillRegister },
  { path: "/login", component: UserLogin },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/pages-404", component: Error404 },
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },
];

export { adminRoutes, userAuthRoutes, userRoutes, adminAuthRoutes };
