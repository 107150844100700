import { Button } from "antd";
import { useEffect, useState } from "react";
import { get } from "../helpers/api_helper";
import { GET_WILL_PLANS } from "../helpers/url_helper";
import { updateWillPlanAsync } from "../helpers/backend_helper";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ChoosePlan = () => {
  const history = useHistory();
  const [plans, setPlans] = useState([]);
  const [masterPlan, setMasterPlans] = useState();
  const [userPlans, setUserPlans] = useState([]);
  const [userMasterPlans, setUserMasterPlans] = useState([]);
  const {authUser} = useSelector(state => state.User);

  useEffect(async () => {
    getSelectedPlans();
  }, [authUser]);

  const getSelectedPlans = async (selectedPlan) => {
    const result = await get(GET_WILL_PLANS);

    setPlans(result.data);
    findSelectedMasterPlan(result.data,selectedPlan);

  }
  let selected_plans = [];
  const findSelectedMasterPlan = async (data,plan = null) => {
     let selected_master_plan = 0;
     setMasterPlans(selected_master_plan);

     if(authUser && authUser.selected_wills){
        authUser.selected_wills.forEach((will, index) => {
           selected_plans.push(parseInt(will.selected_plans))
        });
     }else{
        setUserPlans([]);
        setUserMasterPlans([]);

     }
     if(plan){
           selected_plans.push(parseInt(plan.id))
     }

     data.forEach((plan, index) => {
        if(plan.is_master_plan === 1 && plan.is_active === 1 && selected_plans.includes(plan.id)){
          selected_master_plan = 1;
        }
     });
     setMasterPlans(selected_master_plan);
  }

  const updateWillPlan = async (selectedPlan) => {
    await updateWillPlanAsync(authUser.id, selectedPlan);
    getSelectedPlans(selectedPlan);
  }

  return (
    <>
      <div className="container" style={{ marginTop: 60, marginBottom: 60 }}>
        <div className="row">
          <div className="col-sm-12 text-center">
            <h2 style={{ color: "#1b74bd", fontSize: "2rem", fontWeight: 500 }}>
              Individual Plans ($99 each)
            </h2>
            <p style={{ color: "#000", fontSize: 16 }}>
              Choose any of the individual plans to address specific aspects of
              your estate planning needs.
            </p>
          </div>
        </div>

        <div className="row">
          {plans.map((plan, i) => {
            if(plan.is_master_plan == 1) return false;
            return (
            <div className="col-sm-3" key={i}>
              <div className="payment-plan-card">
                <div className="payment-card-header">
                  {plan.title}:
                </div>
                <div className="payment-card-content">
                  <p>
                    {plan.description}
                  </p>
                </div>
                <div>
                  <Button disabled={plan.is_selected || masterPlan} onClick={() => updateWillPlan(plan)} type={`${plan.is_selected || masterPlan ? 'default' : 'primary primary-btn py-3'} px-4 fw-bold hover-zoom-effect w-75 m-auto mb-3`}>
                    {plan.is_selected || masterPlan ? "Already Added" : "Add to Cart"}
                  </Button>
                </div>
              </div>
            </div>
            )
          })}
        </div>

        <div className="row mt-5">
          {plans.map((plan, i) => {
          //  console.log(authUser);
            if(plan.is_master_plan == 0) return false;
            return (
            <div className="col-sm-10 m-auto" key={i}>
              <div className="payment-plan-card">
                <div className="payment-card-header position-relative"
                style={{ fontSize: 36, fontWeight: 700, padding: "20px 0px" }} dangerouslySetInnerHTML={{__html: plan.title}}>
                </div>
                <div className="payment-card-content">
                  <p>
                    {plan.description}
                  </p>
                </div>
                <div>
                  <Button disabled={plan.is_selected} onClick={() => updateWillPlan(plan)} type={`${plan.is_selected ? 'default' : 'primary primary-btn py-3'} px-4 fw-bold hover-zoom-effect w-25 m-auto mb-3`}>
                    {plan.is_selected ? "Already Added" : "Add to Cart"}
                  </Button>
                </div>
              </div>
            </div>
            )
          })}
        </div>

        {
          plans.find(plan => plan.is_selected) && (
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => {history.push("/your-info")}}
              type="primary primary-btn px-5 py-4 fw-bold mt-3"
              size="large"
            >
              Step 2 | CHOOSE YOUR DOCUMENTS
            </Button>
          </div>
          )
        }
      </div>
    </>
  );
};

export default ChoosePlan;
