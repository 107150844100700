import React from "react"
import { Route } from "react-router-dom"

const AdminNoAuthmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest 
}) => {

  return (
    <Route
      {...rest}
      render={props => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

export default AdminNoAuthmiddleware;
