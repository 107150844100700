import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input
} from "reactstrap"

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { AvForm, AvField } from "availity-reactstrap-validation"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import {
  getAllUsers,
  onAddNewUser,
  onUpdateUser,
  onDeleteUser
} from "../../../store/actions"
import { isEmpty } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { Spin } from 'antd';
import { Button, Popconfirm  } from "antd"
import moment from "moment"
import { toggleUserDocStatusAsync } from "../../../helpers/backend_helper"

const UsersList = props => {
  const dispatch = useDispatch()

  const { users, loading, register_error } = useSelector(state => state.User);
  const [userList, setUserList] = useState([])
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [birthday, setBirthday] = useState(null);

  const { SearchBar } = Search

  useEffect(() => {
    dispatch(getAllUsers())
  }, [])

  useEffect(() => {
    setUserList(users)
  }, [users])

  const pageOptions = {
    sizePerPage: 10,
    totalSize: users.length, // replace later with size(users),
    custom: true,
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  const updateDocStatus = async (user) => {
    await toggleUserDocStatusAsync(user);

    dispatch(getAllUsers())
  }

  const contactListColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      formatter: (cellContent, user) => <>{user.id}</>,
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          <p className="text-muted mb-0">{user?.first_name + " " + user?.last_name}</p>
        </>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone Number",
      sort: true,
    },
    {
      dataField: 'doc_sent',
      text: 'Document',
      sort: false,
      formatter: (content, user) => (
        <input type="checkbox" checked={user.doc_sent} onClick={() => {updateDocStatus(user)}} />
      )
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleUserClick(user)}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <Popconfirm
              title="Delete the User"
              description="Are you sure to delete this User?"
              onConfirm={() => handleDeleteUser(user)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <i
                className="mdi mdi-delete font-size-18"
                id="deletetooltip"
              ></i>
            </Popconfirm>

          </Link>
        </div>
      ),
    },
  ]

  const toggle = () => {
    setModal(!modal)
    if (!modal && !isEmpty(users) && !!isEdit) {
      setTimeout(() => {
        setUserList(users)
        setIsEdit(false)
      }, 500)
    }
  }

  const handleUserClick = arg => {
    const user = arg
    setUserList({
      id: user.id,
      first_name: user.first_name,
      middle_name: user.middle_name,
      last_name: user.last_name,
      suffix: user.suffix,
      email: user.email,
      residential_address: user.residential_address,
      address2: user.address2,
      password: user.password,
      phone: user.phone,
      city: user.city,
      state: user.state,
      zip: user.state,
      date_of_birth: user.date_of_birth
    })
    setBirthday(user?.date_of_birth)
    setIsEdit(true)
    setModal(true)
  }

  const handleDeleteUser = user => {
    dispatch(onDeleteUser(user.id))
  }

  const handleValidUserSubmit = (e, values) => {
    if (isEdit) {
      const updateUser = {
        id: userList.id,
        first_name: values.first_name,
        middle_name: values.middle_name,
        last_name: values.last_name,
        suffix: values.suffix,
        email: values.email,
        // password: values.password,
        residential_address: values.residential_address,
        address2: values.address2,
        phone: values.phone,
        city: values.city,
        state: values.state,
        zip: values.state,
        date_of_birth: birthday
      }
      dispatch(onUpdateUser(updateUser))
      setIsEdit(false)
    } else {
      values.date_of_birth = birthday
      dispatch(onAddNewUser(values))
    }
    toggle()
  }
  const handleUserClicks = () => {
    setUserList("")
    setIsEdit(false)
    toggle()
  }

  const handleBirthdayChange = (event) => {
    setBirthday(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> User List | Admin </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={contactListColumns}
                    data={users}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={users}
                        columns={contactListColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">

                              <div className="row align-ite  ms-center">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <h5 className="card-title"> User List <span className="text-muted fw-normal ms-2">(834) </span></h5>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                    <div>
                                      <Link to="#" className="btn btn-light" onClick={handleUserClicks}><i className="bx bx-plus me-1"></i> Add New</Link>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon-search" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  {loading ? (
                                    <Spin />
                                  ) : (
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                    />
                                  )}
                                  <Modal isOpen={modal} toggle={toggle}>
                                    <ModalHeader toggle={toggle} tag="h4">
                                      {!!isEdit ? "Edit User" : "Add User"}
                                    </ModalHeader>
                                    <ModalBody>
                                      <AvForm
                                        onValidSubmit={handleValidUserSubmit}
                                      >
                                        <Row form>
                                          <Col xs={12}>
                                            <div className="mb-3">
                                              <AvField
                                                name="first_name"
                                                label="First Name"
                                                type="text"
                                                errorMessage="Invalid First Name"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={userList.first_name || ""}
                                              />
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="middle_name"
                                                label="Middle Name"
                                                type="text"
                                                errorMessage="Invalid Middle Name"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={userList.middle_name || ""}
                                              />
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="last_name"
                                                label="Last Name"
                                                type="text"
                                                errorMessage="Invalid Last Name"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={userList.last_name || ""}
                                              />
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="suffix"
                                                label="Suffix"
                                                type="text"
                                                errorMessage="Invalid Suffix"
                                                value={userList.suffix || ""}
                                              />
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="email"
                                                label="Email"
                                                type="email"
                                                errorMessage="Invalid Email"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={userList.email || ""}
                                              />
                                              {register_error?.email && register_error.email?.map((error, index) => {
                                                return (
                                                  <label className="error-text" key={index}>{error}</label>
                                                )
                                              })}
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="phone"
                                                label="Phone Number"
                                                type="text"
                                                errorMessage="Invalid Phone Number"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={userList.phone || ""}
                                              />
                                              {register_error?.phone && register_error.phone?.map((error, index) => {
                                                return (
                                                  <label className="error-text" key={index}>{error}</label>
                                                )
                                              })}
                                            </div>

                                            <div className="mb-3">
                                              <label>Date of BirthDay</label>
                                              <Input className="form-control" type="date" defaultValue={isEdit ? userList?.date_of_birth : ''} id="date_of_birth" onChange={handleBirthdayChange}/>
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="residential_address"
                                                label="Residential Address"
                                                type="text"
                                                errorMessage="Invalid Residential Address"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={userList.residential_address || ""}
                                              />
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="address2"
                                                label="Address 2"
                                                type="text"
                                                errorMessage="Invalid Address2"
                                                value={userList.address2 || ""}
                                              />
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="city"
                                                label="City"
                                                type="text"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                errorMessage="Invalid City"
                                                value={userList.city || ""}
                                              />
                                            </div>

                                            <div className="mb-3">
                                              <AvField
                                                name="state"
                                                label="State"
                                                type="text"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                errorMessage="Invalid State"
                                                value={userList.state || ""}
                                              />
                                            </div>
                                            {!isEdit && (
                                              <div className="mb-3">
                                                <AvField
                                                  name="password"
                                                  label="Password"
                                                  type="password"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  errorMessage="Invalid Password"
                                                  value={userList?.password || ""}
                                                />
                                              </div>
                                            )}
                                            <div className="mb-3">
                                              <AvField
                                                name="zip"
                                                label="Zip"
                                                type="text"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                errorMessage="Invalid Zip"
                                                value={userList.zip || ""}
                                              />
                                            </div>

                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <Button
                                                htmlType="submit"
                                                type="primary primary-btn px-3 fw-bold mt-3"
                                                size="large"
                                                loading={loading}
                                              >
                                                {isEdit ? 'UPDATE' : 'SAVE'}
                                              </Button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </AvForm>
                                    </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UsersList)
