import {
  REGISTER_ADMIN,
  REGISTER_ADMIN_SUCCESS,
  REGISTER_ADMIN_FAILED,
  SET_AUTH_ADMIN
} from "./actionTypes"

export const registerAdmin = (user, history) => {
  return {
    type: REGISTER_ADMIN,
    payload: { user, history },
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_ADMIN_SUCCESS,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_ADMIN_FAILED,
    payload: user,
  }
}

export const setAuthAdmin = (admin) => {
  return {
    type: SET_AUTH_ADMIN,
    payload: admin,
  }
}
