import {
  GET_WILL_BY_USERID,
  GET_WILL_BY_USERID_SUCCESS,
  GET_WILL_BY_USERID_FAIL,
  SAVE_WILL_BY_USERID,
  SAVE_WILL_BY_USERID_SUCCESS,
  CHANGE_EDIT_STATUS,
  UPDATE_WILL_BY_USERID,
  UPDATE_WILL_BY_USERID_SUCCESS,
  UPDATE_USER_SELECTED_PLANS,
  UPDATE_LAST_WILL_TESTAMENT,
  UPDATE_WILL_SURROGATES
} from "./actionTypes"


export const getWillByUserId = (userId) => {
  return {
    type: GET_WILL_BY_USERID,
    payload: { userId },
  }
}

export const getWillByUserIdSuccess = willData => {
  return {
    type: GET_WILL_BY_USERID_SUCCESS,
    payload: willData,
  }
}

export const getWillByUserIdFail = error => {
  return {
    type: GET_WILL_BY_USERID_FAIL,
    payload: error,
  }
}

export const saveWillByUserId = (willData, history, link) => {
  return {
    type: SAVE_WILL_BY_USERID,
    payload: { willData, history, link },
  }
}

export const saveWillByUserIdSuccess = willData => {
  return {
    type: SAVE_WILL_BY_USERID_SUCCESS,
    payload: willData,
  }
}

export const changeEditStatus = status => {
  return {
    type: CHANGE_EDIT_STATUS,
    payload: status,
  }
}

export const updateWillByUserId = (willData, history) => {
  return {
    type: UPDATE_WILL_BY_USERID,
    payload: { willData, history },
  }
}

export const updateWillSurrogates = (willData, history) => {
  return {
    type: UPDATE_WILL_SURROGATES,
    payload: { willData, history },
  }
}

export const updateWillByUserIdSuccess = willData => {
  return {
    type: UPDATE_WILL_BY_USERID_SUCCESS,
    payload: willData,
  }
}

export const udpateLastWillTestament = (data) => {
  return {
    type: UPDATE_LAST_WILL_TESTAMENT,
    payload: { data },
  }
}