import React, { useEffect } from "react"
import { Route } from "react-router-dom"
import { get } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import { useDispatch } from "react-redux"
import { setAuthAdmin } from "../../store/actions"
import { useHistory } from "react-router-dom";

const AdminAuthmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest 
}) => {

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    get(url.VERIFY_ADMIN_TOKEN).then(res => {
      console.log('res =>', res)
      if (res.success) {
        dispatch(setAuthAdmin(res.admin))
      } else {
        localStorage.removeItem('access_token')
        history.push('/admin/login')
      }
    })
    .catch(error => {
      localStorage.removeItem('access_token')
      history.push('/admin/login') 
    })
  }, [])

  return (
    <Route
      {...rest}
      render={props => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}



export default AdminAuthmiddleware;
