import { call, put, takeEvery } from "redux-saga/effects"
import { GET_WILL_BY_USERID, SAVE_WILL_BY_USERID, UPDATE_WILL_BY_USERID, UPDATE_LAST_WILL_TESTAMENT, UPDATE_WILL_SURROGATES } from "./actionTypes"
import {
  getWillByUserIdFail,
  getWillByUserIdSuccess, saveWillByUserIdSuccess, updateWillByUserIdSuccess
} from "./actions"
import { getWillByUserIdAsync, saveWillByUserIdAsync, updateWillByUserIdAsync, udpateLastWillTestament, updateWillSurrogatesAsync } from "../../helpers/backend_helper"

function* getWillByUserId({ payload: { userId } }) {
  try {
    const response = yield call(getWillByUserIdAsync, userId)
    if(response.success) {
      yield put(getWillByUserIdSuccess(response.data))
    } 
  } catch (error) {
    yield put(getWillByUserIdFail(error.response.data.message))
  }
}

function* saveWillByUserId({ payload: { willData, history, link } }) {
  try {
    const response = yield call(saveWillByUserIdAsync, willData)
    if(response.success) {
      yield put(saveWillByUserIdSuccess(response.data))
      history.push(link)
    } 
  } catch (error) {
    console.log('error =>', error.response.data.message)
  }
}

function* updateWillByUserId({ payload: { willData, history } }) {
  try {
    const response = yield call(updateWillByUserIdAsync, willData)
    if(response.success) {
      yield put(updateWillByUserIdSuccess(response.data))
      history.push('/review')
    } 
  } catch (error) {
    console.log('error =>', error.response.data.message)
  }
}

function* updateWillSurrogates({ payload: { willData, history } }) {
  try {
    const response = yield call(updateWillSurrogatesAsync, willData)
    if(response.success) {
      yield put(updateWillByUserIdSuccess(response.data))
      history.push('/review')
    } 
  } catch (error) {
    console.log('error =>', error.response.data.message)
  }
}

function* udpateLastWillTestamentSaga({payload: {data}}){
  try{
    const response = yield call(udpateLastWillTestament, data)
  }catch(e){
    console.log(e);
  }
}

function* willSaga() {
  yield takeEvery(GET_WILL_BY_USERID, getWillByUserId)
  yield takeEvery(SAVE_WILL_BY_USERID, saveWillByUserId)
  yield takeEvery(UPDATE_WILL_BY_USERID, updateWillByUserId)
  yield takeEvery(UPDATE_LAST_WILL_TESTAMENT, udpateLastWillTestamentSaga)
  yield takeEvery(UPDATE_WILL_SURROGATES, updateWillSurrogates)
}

export default willSaga
