import {
  Button,
  Divider,
  Form,
  Input,
  Radio,
  Alert,
  Select,
  Space,
  Modal,
} from "antd";
import { useWatch } from "antd/es/form/Form";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Container, Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  getWillByUserId,
  saveWillByUserId,
  changeEditStatus,
  updateWillByUserId,
} from "../store/actions";
import { useHistory } from "react-router-dom";

import { udpateLastWillTestament } from "../store/actions";
import { updateOtherRelationWillByIdAsync } from "../helpers/backend_helper";
import Surrogate from "./Surrogate";

const MyChoices = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [authUser, setAuthUser] = useState(null)
  const [nameError, setNameError] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const [surrogates, setSurrogates] = useState([]);
  const [tanginbleSurrogates, setTanginbleSurrogates] = useState([]);
  const [surrogateFor, setSurrogateFor] = useState(null);
  const [isModalOpen, toggleModal] = useState(false);
  const [burialChoice, toggleBurialChoice] = useState(false);

  const [errors, setErrors] = useState([]);

  const [form] = Form.useForm();
  const [otherRelationForm] = Form.useForm();

  const { will, edit_open } = useSelector((state) => state.Will);
  const { authUser } = useSelector((state) => state.User);

  const residence_status = useWatch("residence_status", form);

  useEffect(() => {
    if (authUser) {
      dispatch(getWillByUserId(authUser.id));
    }
  }, [authUser]);

  useEffect(() => {
    if (will) {
      if (will.last_will_and_testament) {
        if (!will.last_will_and_testament.residence_address) {
          let residence_address = `${authUser?.residential_address || ""}, ${
            authUser?.address2 || ""
          }, ${authUser?.city || ""}, ${authUser?.state || ""}, ${
            authUser?.county || ""
          } - ${authUser?.zip || ""}`;

          form.setFieldsValue({
            residence_address,
          });
        }
      } else {
        let residence_address = `${authUser?.residential_address || ""}, ${
          authUser?.address2 || ""
        }, ${authUser?.city || ""}, ${authUser?.state || ""}, ${
          authUser?.county || ""
        } - ${authUser?.zip || ""}`;
        form.setFieldsValue({
          residence_address,
        });
      }
    }
  }, [residence_status]);

  useEffect(() => {
    let surrogates = [];
    let tangSurrs = [];
    if (will) {
      if (will.married_status && will.married_status != "0") {
        let spouse =
          typeof will.spouse_info == "object"
            ? will.spouse_info
            : JSON.parse(will.spouse_info);
        surrogates.push(
          `${spouse.first_name} ${spouse.middle_name || ""} ${spouse.last_name}`
        );
        tangSurrs.push(`${spouse.first_name} ${spouse.middle_name || ""} ${spouse.last_name}`);
      }

      if (will.have_children && will.have_children != "0") {
        let children =
          typeof will.children_info == "object"
            ? will.children_info
            : JSON.parse(will.children_info);
        const childrenNames = children.map((child) => {
          const name = `${child.c_first_name} ${child.c_middle_name || ""} ${
            child.c_last_name
          }`;
          tangSurrs.push(name);
          if (!child.c_is_minor) {
            surrogates.push(name);
          }
        });
      }

      if (will.have_sibling && will.have_sibling != "0") {
        let siblings =
          typeof will.sibling_info == "object"
            ? will.sibling_info
            : JSON.parse(will.sibling_info);
        const siblingNames = siblings.map((sibling) => {
          const name = `${sibling.s_first_name} ${sibling.s_middle_name || ""} ${
            sibling.s_last_name
          }`;
          tangSurrs.push(name);
          if (!sibling.c_is_minor) {
            surrogates.push(name);
          }
        });
      }

      if (will.other_relations && will.other_relations.length) {
        let other_relations =
          typeof will.other_relations == "object"
            ? will.other_relations
            : JSON.parse(will.other_relations);
        const otherName = other_relations.map((other) => {
          const name = `${other.first_name} ${other.middle_name || ""} ${other.last_name}`;
          tangSurrs.push(name);
          surrogates.push(name);
        });
      }

      setSurrogates(surrogates);
      setTanginbleSurrogates(tangSurrs);
    }
  }, [will]);

  const handleSubmit = async () => {
    toggleLoading(true);
    const formErrors = form.getFieldsError().filter((field) => {
      if (field.errors.length) return field.errors;
    });

    if (formErrors.length) {
      setErrors(formErrors);
      return;
    } else {
      setErrors([]);
    }

    let values = form.getFieldsValue();
    values.will_id = will.id;

    new Promise(() => {
      dispatch(udpateLastWillTestament(values));
    });
    toggleLoading(false);

    history.push("/review");
  };

  const handleAddNewSelection = (event, type) => {
    if (!event) {
      toggleModal(true);
    } else {
      toggleModal(false);
    }
    setSurrogateFor(type);
  };

  const handleCloseModal = () => {
    toggleLoading(false);
    toggleModal(false);
  };

  const handleRelationSubmit = async (values) => {
    toggleLoading(true);
    await updateOtherRelationWillByIdAsync({ ...values, id: will.id });

    setSurrogates([
      ...surrogates,
      `${values.first_name} ${values.middle_name || ""} ${values.last_name}`,
    ]);
    toggleModal(false);
  };

  const doesHaveLastWillAndTestament = () => {
    const lastWill = authUser?.selected_wills.find(
      (will) => will.will_details.title == "Last Will and Testament"
    );

    return lastWill;
  };
  return (
    <React.Fragment>
      <MetaTags>
        <title>My Choices</title>
      </MetaTags>
      <div style={{ margin: "30px 0px" }}>
        <Container>
          <div>
            <h1 className="fw-normal txt-primary mb-3" style={{ fontSize: 40 }}>
              My Choices
            </h1>
            <p>
              Now let’s fill in the blanks for the Documents. Just a few more
              questions and we’re done.
            </p>
          </div>

          {doesHaveLastWillAndTestament() ? (
            <>
              <Form form={form} onFinish={handleSubmit} layout="vertical">
                <h5 className="choice-heading">Last Will & Testament</h5>
                <Row>
                  <Col xs={12}>
                    <Form.Item
                      name="residence_status"
                      rules={[{ required: true }]}
                      label="Do you own your residence? (where you live)"
                    >
                      <Radio.Group
                        size="large"
                        value={will?.last_will_and_testament?.residence_status.toString()}
                      >
                        <Space direction="vertical">
                          <Radio value={"1"}>I own my residence.</Radio>
                          <Radio value={"2"}>
                            I own the my residence jointly.
                          </Radio>
                          <Radio value={"0"}>I do not own my residence.</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  {residence_status == 2 || residence_status == 1 ? (
                    <Col xs={12}>
                      <Form.Item
                        label="Residence Address"
                        name={"residence_address"}
                        rules={[{ required: true }]}
                        defaultValue={`${
                          authUser?.residential_address || ""
                        }, ${authUser?.address2 || ""}, ${
                          authUser?.city || ""
                        }, ${authUser?.state || ""}, ${
                          authUser?.county || ""
                        } - ${authUser?.zip || ""}`}
                      >
                        <Input
                          placeholder="Residence Address"
                          size="large"
                          type="text"
                          className="rounded-2"
                          value={`${authUser?.residential_address || ""}, ${
                            authUser?.address2 || ""
                          }, ${authUser?.city || ""}, ${
                            authUser?.state || ""
                          }, ${authUser?.county || ""} - ${
                            authUser?.zip || ""
                          }`}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    ""
                  )}

                  {residence_status == 2 ? (
                    <Col xs={12} md={6}>
                      <Form.Item
                        name={`residence_surrogate`}
                        key={`residence_surrogate`}
                        rules={[{ required: true }]}
                        label="Choose Joint owner of residence"
                      >
                        <Select
                          className="surrogate-select"
                          onChange={(event) =>
                            handleAddNewSelection(event, "residence_surrogate")
                          }
                          placeholder="Select Name"
                          allowClear
                        >
                          <Select.Option value="">Add New</Select.Option>
                          {surrogates.map((item, index) => {
                            return (
                              <Select.Option value={item} key={index}>
                                {item}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                <Divider />

                <Row>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={`personal_representative`}
                      key={`personal_representative`}
                      rules={[{ required: true }]}
                      label="Choose a Personal Representative."
                    >
                      <Select
                        className="surrogate-select"
                        onChange={(event) =>
                          handleAddNewSelection(
                            event,
                            "personal_representative"
                          )
                        }
                        placeholder="Select Name"
                        allowClear
                      >
                        <Select.Option value="">Add New</Select.Option>
                        {surrogates.map((item, index) => {
                          return (
                            <Select.Option value={item} key={index}>
                              {item}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name={`alternate_representative`}
                      key={`alternate_representative`}
                      rules={[{ required: true }]}
                      label="Choose a Alternate Representative."
                    >
                      <Select
                        className="surrogate-select"
                        onChange={(event) =>
                          handleAddNewSelection(
                            event,
                            "alternate_representative"
                          )
                        }
                        placeholder="Select Name"
                        allowClear
                      >
                        <Select.Option value="">Add New</Select.Option>
                        {surrogates.map((item, index) => {
                          return (
                            <Select.Option value={item} key={index}>
                              {item}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />

                <Row>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={`burial_choice`}
                      key={`burial_choice`}
                      rules={[{ required: true }]}
                      label="My Burial Wishes"
                    >
                      <Radio.Group
                        value={burialChoice}
                        size="large"
                        onChange={(e) => toggleBurialChoice(e.target.value)}
                      >
                        <Space direction="vertical">
                          <Radio value="1">I wish to Be Buried</Radio>
                          <Radio value="2">I wish to be Cremated</Radio>
                          <Radio value="0">
                            Let my Personal Representative Choose.
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  {burialChoice === "0" ? (
                    <Col xs={12} md={6}>
                      <Form.Item
                        name={`burial_representative`}
                        key={`burial_representative`}
                        rules={[{ required: true }]}
                        label="Burial Representative"
                      >
                        <Select
                          className="surrogate-select"
                          onChange={(event) =>
                            handleAddNewSelection(
                              event,
                              "burial_representative"
                            )
                          }
                          placeholder="Select Name"
                          allowClear
                        >
                          <Select.Option value="">Add New</Select.Option>
                          {surrogates.map((item, index) => {
                            return (
                              <Select.Option value={item} key={index}>
                                {item}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                <Divider />
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name={`tangible_posession_representative`}
                      key={`tangible_posession_representative`}
                      rules={[{ required: true }]}
                      label="I leave my tangible possessions and properties to:"
                    >
                      <Select
                        className="surrogate-select"
                        onChange={(event) =>
                          handleAddNewSelection(
                            event,
                            "tangible_posession_representative"
                          )
                        }
                        placeholder="Select Name"
                        allowClear
                      >
                        <Select.Option value="">Add New</Select.Option>
                        {tanginbleSurrogates.map((item, index) => {
                          return (
                            <Select.Option value={item} key={index}>
                              {item}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name={`2nd_tangible_posession_representative`}
                      key={`2nd_tangible_posession_representative`}
                      rules={[{ required: true }]}
                      label="If the person I chose is deceased. My second choice is:"
                    >
                      <Select
                        className="surrogate-select"
                        onChange={(event) =>
                          handleAddNewSelection(
                            event,
                            "2nd_tangible_posession_representative"
                          )
                        }
                        placeholder="Select Name"
                        allowClear
                      >
                        <Select.Option value="">Add New</Select.Option>
                        {surrogates.map((item, index) => {
                          return (
                            <Select.Option value={item} key={index}>
                              {item}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                {/* {edit_open ? (
              <div className="d-flex">
                <Button
                  type="primary px-3 fw-bold mt-3"
                  size="large"
                  danger
                  style={{ marginRight: 20 }}
                  // onClick={onCancelUpdate}
                >
                  CANCEL
                </Button>
                <Button
                  htmlType="submit"
                  type="primary primary-btn px-3 fw-bold mt-3"
                  size="large"
                  // loading={loading}
                >
                  UPDATE
                </Button>
              </div>
            ) : (
              <div className="footer-button d-flex justify-content-between mt-4">
                <Button
                  type="primary primary-btn px-3 fw-bold mt-2"
                  size="large"
                  // onClick={goWillRegister}
                >
                  Previous
                </Button>

                <Button
                  type="primary primary-btn px-3 fw-bold mt-2"
                  size="large"
                  htmlType="button"
                  loading={loading}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </div>
            )} */}
                <Divider />
              </Form>
            </>
          ) : (
            <p></p>
          )}
        </Container>

        <Surrogate submitCallBack={handleSubmit} />
      </div>

      <Modal
        title="Add Surrogate"
        open={isModalOpen}
        // onOk={handleCloseModal}
        onCancel={handleCloseModal}
      >
        <Form
          form={otherRelationForm}
          onFinish={handleRelationSubmit}
          scrollToFirstError={true}
          layout="vertical"
        >
          <Row>
            <Col xs={12} md={6}>
              <Form.Item
                name="first_name"
                rules={[{ required: true }]}
                label="First Name"
              >
                <Input
                  size="large"
                  className="rounded-2"
                  placeholder="Type firstname"
                />
              </Form.Item>
            </Col>

            <Col xs={12} md={6}>
              <Form.Item name="middle_name" rules={[{}]} label="Middle Name">
                <Input
                  size="large"
                  className="rounded-2"
                  placeholder="Type middle name"
                />
              </Form.Item>
            </Col>

            <Col xs={12} md={6}>
              <Form.Item
                name="last_name"
                rules={[{ required: true }]}
                label="Last Name"
              >
                <Input
                  size="large"
                  className="rounded-2"
                  placeholder="Type last name"
                />
              </Form.Item>
            </Col>

            <Col xs={12} md={6}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Date of Birth"
              >
                <Input
                  size="large"
                  type="date"
                  className="rounded-2"
                  placeholder="DOB"
                />
              </Form.Item>
            </Col>

            <Col xs={12} md={6}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="E-mail"
              >
                <Input
                  size="large"
                  type="email"
                  className="rounded-2"
                  placeholder="Type e-mail"
                />
              </Form.Item>
            </Col>

            <Col xs={12} md={6}>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Phone"
              >
                <Input
                  size="large"
                  type="string"
                  className="rounded-2"
                  placeholder="Type Phone number"
                />
              </Form.Item>
            </Col>

            <Col xs={12} md={6}>
              <Form.Item
                name="address_1"
                rules={[{ required: true }]}
                label="Address 1"
              >
                <Input
                  size="large"
                  className="rounded-2"
                  placeholder="Type Address 1"
                />
              </Form.Item>
            </Col>

            <Col xs={12} md={6}>
              <Form.Item name="address_2" label="Address 2">
                <Input
                  size="large"
                  className="rounded-2"
                  placeholder="Type address 2"
                />
              </Form.Item>
            </Col>

            <Col xs={12} md={6}>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="City"
              >
                <Input
                  size="large"
                  className="rounded-2"
                  placeholder="Type city"
                />
              </Form.Item>
            </Col>

            <Col xs={12} md={6}>
              <Form.Item
                name="state"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="State"
              >
                <Input
                  size="large"
                  className="rounded-2"
                  placeholder="Type state"
                />
              </Form.Item>
            </Col>

            <Col xs={12} md={6}>
              <Form.Item
                name="zip"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="ZIP"
              >
                <Input
                  size="large"
                  className="rounded-2"
                  placeholder="Type ZIP"
                />
              </Form.Item>
            </Col>
          </Row>

          <Button
            htmlType="submit"
            type="primary primary-btn px-3 fw-bold mt-2"
            size="large"
            loading={loading}
          >
            Add Relation
          </Button>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default MyChoices;
