import { Button, Divider, Form, Input, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getWillByUserId,
  saveWillByUserId,
  changeEditStatus,
  updateWillByUserId,
  updateWillSurrogates,
} from "../store/actions";

import { useWatch } from "antd/es/form/Form";
import FeatherIcon from "feather-icons-react";
import { updateOtherRelationWillByIdAsync } from "../helpers/backend_helper";
const { Option } = Select;

const Surrogate = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [otherRelationForm] = Form.useForm();

  const [surrogate1List, setSurrogate1List] = useState([]);
  const [surrogate2List, setSurrogate2List] = useState([]);
  const [mainFilterList, setMainFilterList] = useState([]);
  const [otherRelationsList, setOtherRelationsList] = useState([]);
  const [body_part_initial, setBodyPartInitial] = useState([]);
  const [selectedSurrogate, setSelectedSurrogate] = useState(null);

  const [surrogatesSelection, setSurrogatesSelection] = useState({});

  const donate_body = useWatch("donate_body", form);
  const donate_full_body = useWatch("donate_full_body", form);
  const surrogate_1 = useWatch("surrogate_1", form);
  const surrogate_2 = useWatch("surrogate_2", form);

  const { will, loading, edit_open } = useSelector((state) => state.Will);
  const { authUser } = useSelector((state) => state.User);

  useEffect(() => {
    if (authUser) {
      dispatch(getWillByUserId(authUser.id));
    }
  }, [authUser]);

  useEffect(() => {
    if (authUser) {
      form.setFieldsValue({
        testator:
          authUser?.first_name +
          " " +
          (authUser?.middle_name || "") +
          " " +
          authUser?.last_name,
      });
    }
  }, [authUser]);

  useEffect(() => {
    if (will) {
      form.setFieldsValue({
        donate_body:
          will.donate_body === "1" ||
          will.donate_body === 1 ||
          will.donate_body === true
            ? true
            : false,
        donate_full_body:
          will.donate_full_body === "1" ||
          will.donate_full_body === 1 ||
          will.donate_full_body === true
            ? true
            : false,
      });

      if (will.body_part_detail) {
        let bodyPartInfo;
        if (typeof will.body_part_detail === "string") {
          bodyPartInfo = JSON.parse(will.body_part_detail);
        } else {
          bodyPartInfo = will.body_part_detail;
        }
        setBodyPartInitial(bodyPartInfo);
      }

      let surrogate1 = [];

      if (
        will.married_status === "1" ||
        will.married_status === 1 ||
        will.married_status === true
      ) {
        let spouseInfo;
        if (typeof will.spouse_info === "string") {
          spouseInfo = JSON.parse(will.spouse_info);
        } else {
          spouseInfo = will.spouse_info;
        }
        const spouseName =
          spouseInfo.first_name +
          " " +
          (spouseInfo.middle_name || "") +
          " " +
          spouseInfo.last_name;
        surrogate1.push(spouseName);
      }

      if (
        will.have_children === "1" ||
        will.have_children === 1 ||
        will.have_children === true
      ) {
        let childrenInfo;
        if (typeof will.children_info === "string") {
          childrenInfo = JSON.parse(will.children_info);
        } else {
          childrenInfo = will.children_info;
        }

        childrenInfo.map((item) => {
          let children_name =
            item.c_first_name +
            " " +
            (item.c_middle_name || "") +
            " " +
            item.c_last_name;
          if (!parseInt(item.c_is_minor)) surrogate1.push(children_name);
        });
      }

      if (
        will.have_sibling === "1" ||
        will.have_sibling === 1 ||
        will.have_sibling === true
      ) {
        let siblingInfo;
        if (typeof will.sibling_info === "string") {
          siblingInfo = JSON.parse(will.sibling_info);
        } else {
          siblingInfo = will.sibling_info;
        }


        siblingInfo.map((item) => {
          let sibling_name =
            item.s_first_name +
            " " +
            (item.s_middle_name || "") +
            " " +
            item.s_last_name;
          if (!parseInt(item.s_is_minor)) surrogate1.push(sibling_name);
        });
      }

      if (will.other_relations && will.other_relations.length) {
        let otherRelationInfo;
        if (typeof will.other_relations === "string") {
          otherRelationInfo = JSON.parse(will.other_relations);
        } else {
          otherRelationInfo = will.other_relations;
        }

        otherRelationInfo.map((item) => {
          let sibling_name =
            item.first_name +
            " " +
            (item.middle_name || "") +
            " " +
            item.last_name;
          surrogate1.push(sibling_name);
        });
      }
      if (authUser && authUser.selected_wills.length) {
        authUser.selected_wills.forEach((plan, index) => {
          // if(plan.is_master_plan === 1 && plan.is_active === 1){
          plan.surrogate_options = surrogate1;
          plan.surrogated_selected = null;
          //}
        });
      }

      setSurrogate1List(surrogate1);
      setMainFilterList(surrogate1);

      if(authUser.selected_wills){
        const selected_wills = Object.keys(authUser.selected_wills);
        let selection = {};
        selected_wills.map((will, i) => {
          const willData = authUser.selected_wills[will];
          onChangeSurrogate1(willData.surrogate_1, willData);
          onChangeSurrogate2(willData.surrogate_2, willData);
          // setSurrogatesSelection({
          //   ...surrogatesSelection,
          //   [willData.id]: {...surrogatesSelection[willData.id], surrogate_1: willData.surrogate_1, surrogate_2: willData.surrogate_2},
          // })
          selection = {...selection, [willData.id]: {...selection[willData.id], surrogate_1: willData.surrogate_1, surrogate_2: willData.surrogate_2}};
        });

        setSurrogatesSelection(selection);
      }
    }
  }, [will]);

  // const handleSubmit = (values) => {
  //   if (values.body_part_detail) {
  //     values.body_part_detail = JSON.stringify(values.body_part_detail);
  //   }
  //   values.user_id = authUser.id;
  //   if (edit_open) {
  //     dispatch(updateWillByUserId(values, props.history));
  //     dispatch(changeEditStatus(false));
  //   } else {
  //     dispatch(saveWillByUserId(values, props.history, "/review"));
  //   }
  // };

  const goYourInfo = () => {
    history.push("/your-info");
  };

  const onChangeSurrogate1 = (event, will_item) => {
    let filtered_new_data = mainFilterList;
    will_item.surrogate_options = filtered_new_data.filter(
      (item) => item !== event
    );
    will_item.surrogated_selected = event;

    setSelectedSurrogate(event);
    const fieldName = `surrogate_1_${will_item.will_details.id}`;

    const { setFieldsValue, getFieldValue } = form;
    let LastValue = getFieldValue(fieldName);

    setFieldsValue({
      [fieldName]: event,
    });
  };

  const onChangeSurrogate2 = (event, will_item) => {
    let filtered_new_data = mainFilterList;
    will_item.surrogate_options = filtered_new_data.filter(
      (item) => item !== event
    );
    will_item.surrogated_selected = event;

    setSelectedSurrogate(event);
    const fieldName = `surrogate_2_${will_item.will_details.id}`;

    const { setFieldsValue, getFieldValue } = form;
    let LastValue = getFieldValue(fieldName);

    setFieldsValue({
      [fieldName]: event,
    });
  };

  const onCancelUpdate = () => {
    dispatch(changeEditStatus(false));
    history.push("/review");
  };

  const handleRelationSubmit = async (values) => {
    await updateOtherRelationWillByIdAsync({ ...values, id: will.id });

    setSurrogate1List([...surrogate1List, values]);
    form.setFieldValue(
      "surrogate_1",
      `${values.first_name} ${values.last_name}`
    );
  };

  const handleSurrogateSubmit = async () => {
    if (edit_open) {
      dispatch(updateWillSurrogates(surrogatesSelection, props.history));
      dispatch(changeEditStatus(false));
      props.submitCallBack();
    } else {
      dispatch(updateWillSurrogates(surrogatesSelection, props.history));
      props.submitCallBack();
    }
    // history.push("/review");
  };

  const handleSurrogateSelection = async (e, will, surrogate) => {
    let selections = surrogatesSelection;
    selections = {
      ...surrogatesSelection,
      [will.id]: { ...surrogatesSelection[will.id], [surrogate]: e },
    };

    setSurrogatesSelection(selections);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title> Surrogate Page </title>
      </MetaTags>
      <div style={{ margin: "45px 0px" }}>
        <Container>
          <Form
            form={form}
            onFinish={handleSurrogateSubmit}
            scrollToFirstError={true}
            layout="vertical"
          >
            {authUser &&
              authUser.selected_wills.map((will) => {
                if (parseInt(will.will_details.is_master_plan)) return;
                const willType = will.will_details.title;
                const sectionTitle = willType.split("&")[0];
                const description = will.will_details.description;
                if (will.will_details.title == "Last Will and Testament")
                  return;
                return (
                  <>
                    <h5 className="choice-heading">{sectionTitle}</h5>
                    <p className="sub-title-choice">{description}</p>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Item
                          name={`surrogate_1_${will.will_details.id}`}
                          key={`surrogate_1_${will.will_details.id}`}
                          rules={[{ required: true }]}
                          label={`Who would you like to name as your ${sectionTitle} representative?`}
                        >
                          <br />
                          <Select
                            className="surrogate-select"
                            placeholder="Select Name"
                            onChange={(event) => {
                              onChangeSurrogate1(event, will);
                              handleSurrogateSelection(
                                event,
                                will,
                                "surrogate_1"
                              );
                            }}
                            defaultValue={will?.surrogate_1}
                            allowClear
                          >
                            <Option value="">Add New</Option>
                            {will &&
                            will.surrogate_options &&
                            will.surrogate_options.length
                              ? will.surrogate_options.map((item, index) => {
                                  return (
                                    <Option value={item} key={index}>
                                      {item}
                                    </Option>
                                  );
                                })
                              : ""}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col xs={12} md={6}>
                        <Form.Item
                          className="surrogate-select"
                          name={`surrogate_2_${will.will_details.id}`}
                          key={`surrogate_2_${will.will_details.id}`}
                          label={`If ${
                            will?.surrogate_1 || "______"
                          } cannot or declines to serve, who would you like to name as your second successor ${sectionTitle} representative?`}
                        >
                          <Select
                            placeholder="Select Name"
                            onChange={(event) => {
                              onChangeSurrogate2(event, will);
                              handleSurrogateSelection(
                                event,
                                will,
                                "surrogate_2"
                              );
                            }}
                            defaultValue={will?.surrogate_2}
                            allowClear
                          >
                            <Option value="">Add New</Option>

                            {will &&
                            will.surrogate_options &&
                            will.surrogate_options.length
                              ? will.surrogate_options.map((item, index) => {
                                  return (
                                    <Option value={item} key={index}>
                                      {item}
                                    </Option>
                                  );
                                })
                              : ""}
                          </Select>
                        </Form.Item>
                      </Col>

                      {/* {
                        will.will_details.title == "Living Will" ? (
                          <Col xs={12} md={6}>
                          <Form.Item
                            className="surrogate-select"
                            name={`${will.id}.will_meta`}
                            key={`surrogate_2_${will.will_details.id}`}
                            label={`Parts Donation`}
                          >
                            <Input onInput={(e) => handleSurrogateSelection(e.target.value, will, 'will_meta')}/>
                          </Form.Item>
                        </Col>
                        ) : ""
                      } */}
                    </Row>
                    <hr />
                  </>
                );
              })}

            {/* <Row>
              <Col xs={12} md={6}>
                <Form.Item
                  name="donate_body"
                  rules={[{ required: true }]}
                  label="Donate Body/Parts?"
                >
                  <Radio.Group size="large">
                    <Radio value={true}> Yes </Radio>
                    <Radio value={false}> No </Radio>
                  </Radio.Group>
                </Form.Item>
                {donate_body === true && (
                  <Form.Item
                    name="donate_full_body"
                    rules={[{ required: true }]}
                    label="Whole Body or Parts? "
                  >
                    <Radio.Group size="large">
                      <Radio value={true}> Whole Body </Radio>
                      <Radio value={false}> Parts  </Radio>
                    </Radio.Group>
                  </Form.Item>
                )}

                {donate_full_body === false && (
                  <>
                    <label className="mb-3">Body Part Details</label>
                    <Form.List initialValue={body_part_initial} name="body_part_detail">
                      {(fields, { add, remove }) => (
                        <div>
                          {fields.map(({ key, name, value, ...restField }, indx) => (
                            <div
                              key={key}
                              className="d-flex justify-content-between"
                              style={{ gap: 12 }}
                            >
                              <div className="d-flex justify-content-between flex-column flex-grow-1">
                                <Row>
                                  <Col xs={12}>
                                    <Form.Item
                                      name={[name, "body_part_name"]}
                                      rules={[{ required: true }]}
                                      label="Body Part Name"
                                    >
                                      <Input
                                        size="large"
                                        className="rounded-2"
                                        placeholder="Type Body Part Name"
                                      />

                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Divider className="mt-0 mb-4 pb-2" />
                              </div>

                              <Button
                                type="primary primary-btn"
                                size="large"
                                onClick={() => remove(indx)}
                                icon={
                                  <FeatherIcon
                                    role="button"
                                    icon="x"
                                    className="icon-lg flex-shrink-0"
                                  />
                                }
                              />
                            </div>
                          ))}

                          <Button
                            type="primary primary-btn px-3 mb-4"
                            size="large"
                            onClick={() => add({})}
                          >
                            + ADD NEW
                          </Button>
                        </div>
                      )}
                    </Form.List>
                  </>
                )}
              </Col>
            </Row> */}

            {edit_open ? (
              <div className="d-flex">
                <Button
                  type="primary px-3 fw-bold mt-3"
                  size="large"
                  danger
                  style={{ marginRight: 20 }}
                  onClick={onCancelUpdate}
                >
                  CANCEL
                </Button>
                <Button
                  htmlType="submit"
                  type="primary primary-btn px-3 fw-bold mt-3"
                  size="large"
                  loading={loading}
                >
                  UPDATE
                </Button>
              </div>
            ) : (
              <div className="footer-button d-flex justify-content-between mt-4">
                <Button
                  type="primary primary-btn px-3 fw-bold mt-2"
                  size="large"
                  onClick={goYourInfo}
                >
                  Previous
                </Button>

                <Button
                  type="primary primary-btn px-3 fw-bold mt-2"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                >
                  Next
                </Button>
              </div>
            )}
          </Form>

          {(surrogate_1 == "" || surrogate_2 == "") && (
            <>
              <Form
                form={otherRelationForm}
                onFinish={handleRelationSubmit}
                scrollToFirstError={true}
                layout="vertical"
              >
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name="first_name"
                      rules={[{ required: true }]}
                      label="First Name"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type firstname"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="middle_name"
                      rules={[{}]}
                      label="Middle Name"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type middle name"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="last_name"
                      rules={[{ required: true }]}
                      label="Last Name"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type last name"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="E-mail"
                    >
                      <Input
                        size="large"
                        type="email"
                        className="rounded-2"
                        placeholder="Type e-mail"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="Phone"
                    >
                      <Input
                        size="large"
                        type="string"
                        className="rounded-2"
                        placeholder="Type Phone number"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="address_1"
                      rules={[{ required: true }]}
                      label="Address 1"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type Address 1"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item name="address_2" label="Address 2">
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type address 2"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="city"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="City"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type city"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="state"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="State"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type state"
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Item
                      name="zip"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      label="ZIP"
                    >
                      <Input
                        size="large"
                        className="rounded-2"
                        placeholder="Type ZIP"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* htmlType="submit"  */}
                <Button
                  htmlType="submit"
                  type="primary primary-btn px-3 fw-bold mt-2"
                  size="large"
                  loading={loading}
                >
                  Add Relation
                </Button>
              </Form>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Surrogate;
