import { Button, Divider, Input } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import FeatherIcon from "feather-icons-react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-nav">
        <Container>
          <div
            className="d-flex align-items-center justify-content-between flex-wrap"
            style={{
              padding: "20px 0px",
              gap: 16,
            }}
          >
            <Link to="/home">
              <img
                src="/images/layout/do-your-will-logo-vertical.png"
                alt=""
                style={{ height: 90, cursor: "pointer" }}
                className="hover-zoom-effect"
              />
            </Link>
            <Link to="/will-register">
            <Button
              type="primary primary-btn px-4 px-sm-4 fw-bold hover-zoom-effect"
              size="large"
            >
              GET STARTED!
            </Button>
          </Link>
          </div>
        </Container>

        {/* <Container>
          <div style={{ paddingTop: 20 }}>
            <Row style={{ margin: -10 }}>
              <Col style={{ padding: "10px 10px 30px" }} xs={12} sm={6} lg={3}>
                <nav
                  className="nav-wrapper d-flex flex-column"
                  style={{ gap: 10 }}
                >
                  <h5 className="title">ESTATE PLANNING</h5>

                  <div>
                    <h6 className="nav-item">Trust</h6>
                    <h6 className="nav-item">Will</h6>
                    <h6 className="nav-item">Attorney Support</h6>
                    <h6 className="nav-item">Deed Transfer</h6>
                    <h6 className="nav-item">Pricing</h6>
                  </div>
                </nav>
              </Col>

              <Col style={{ padding: "10px 10px 30px" }} xs={12} sm={6} lg={3}>
                <nav
                  className="nav-wrapper d-flex flex-column"
                  style={{ gap: 10 }}
                >
                  <h5 className="title">ESTATE PLANNING</h5>

                  <div>
                    <h6 className="nav-item">Trust</h6>
                    <h6 className="nav-item">Will</h6>
                    <h6 className="nav-item">Attorney Support</h6>
                    <h6 className="nav-item">Deed Transfer</h6>
                    <h6 className="nav-item">Pricing</h6>
                  </div>
                </nav>
              </Col>

              <Col style={{ padding: "10px 10px 30px" }} xs={12} sm={6} lg={3}>
                <nav
                  className="nav-wrapper d-flex flex-column"
                  style={{ gap: 10 }}
                >
                  <h5 className="title">ESTATE PLANNING</h5>

                  <div>
                    <h6 className="nav-item">Trust</h6>
                    <h6 className="nav-item">Will</h6>
                    <h6 className="nav-item">Attorney Support</h6>
                    <h6 className="nav-item">Deed Transfer</h6>
                    <h6 className="nav-item">Pricing</h6>
                  </div>
                </nav>
              </Col>

              <Col style={{ padding: "10px 10px 30px" }} xs={12} sm={6} lg={3}>
                <nav
                  className="nav-wrapper d-flex flex-column"
                  style={{ gap: 10 }}
                >
                  <h5 className="title">ESTATE PLANNING</h5>

                  <div>
                    <h6 className="nav-item">Trust</h6>
                    <h6 className="nav-item">Will</h6>
                    <h6 className="nav-item">Attorney Support</h6>
                    <h6 className="nav-item">Deed Transfer</h6>
                    <h6 className="nav-item">Pricing</h6>
                  </div>
                </nav>
              </Col>
            </Row>
          </div>
        </Container> */}

        <Container>
          <Row>
            <Col xs={12}>
              <Divider
                style={{
                  borderTop: "1px solid #1B74BD",
                }}
                className="my-0"
              />
            </Col>

            <Col style={{ padding: "10px 10px 30px" }} xs={12} md={6}>
              <div className="d-flex flex-column" style={{ gap: 10 }}>
                <h4
                  className="title mb-0"
                  style={{ fontSize: 30, color: "#42AAFF", fontWeight: 400 }}
                >
                  Estate Planning
                </h4>

                <ul className="footer-navbar">
                  <li>
                    <a href="#">Last Will and Testiment</a>
                  </li>
                  <li>
                    <a href="#">Durable Power of Attorney</a>
                  </li>
                  <li>
                    <a href="#">Designation of Healthcare Surrogate</a>
                  </li>
                  <li>
                    <a href="#">Living Will</a>
                  </li>
                </ul>
              </div>
            </Col>

            <Col style={{ padding: "10px 10px 30px" }} xs={12} md={6}>
              <div className="d-flex flex-column" style={{ gap: 10 }}>
                <h4
                  className="title mb-0"
                  style={{ fontSize: 30, color: "#42AAFF", fontWeight: 400 }}
                >
                  Contact Us
                </h4>

                <form>
                  <div className="form-group my-3">
                    <input
                      type="text"
                      placeholder="Name"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group my-3">
                    <input
                      type="text"
                      placeholder="Email"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group my-3">
                    <textarea
                      className="form-control"
                      placeholder="Message"
                    ></textarea>
                  </div>

                  <Button
                    type="primary primary-btn px-4 fw-bold hover-zoom-effect w-100"
                    style={{ height: 38 }}
                  >
                    Send
                  </Button>

                  <div className="mt-4">
                  <a
                    style={{ color: "#fff", fontSize: 16 }}
                    href="tel:305-788-7607"
                  >
                    <FeatherIcon
                      icon="phone"
                      strokeWidth={3}
                      style={{ color: "#42aaff" }}
                    />{" "}
                    Call us at: <b>305-788-7607</b>
                  </a>
                  </div>
                </form>
              </div>
            </Col>

            <Col xs={12}>
              <Divider
                style={{
                  borderTop: "1px solid #1B74BD",
                }}
                className="my-2"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bottom-footer text-center">
          <span>© 2023  | Do Your Will | All rights reserved</span>
      </div>
    </footer>
  );
};

export default Footer;
