import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import MyDocument from "./Components/MyDocument";
import { PDFViewer } from "@react-pdf/renderer";
import { useSelector, useDispatch } from "react-redux";
import { getWillByUserId } from "../store/actions";
import { Button, Tabs } from "antd";
import DesignationOfHealthSurrogate from "./Components/DesignationOfHealthSurrogate";
import DurablePowerOfAttorney from "./Components/DurablePowerOfAttorney";
import LivingWill from "./Components/LivingWill";
import { GET_WILL_PLANS } from "../helpers/url_helper";
import { get } from "../helpers/api_helper";

const Preview = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [willData, setWillData] = useState(null);
  const { will } = useSelector((state) => state.Will);
  const { authUser } = useSelector((state) => state.User);

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    if (authUser) {
      dispatch(getWillByUserId(authUser.id));
    }
  }, [authUser]);

  useEffect(async () => {
    getSelectedPlans();
  }, []);

  const getSelectedPlans = async () => {
    const result = await get(GET_WILL_PLANS);

    if (result.data) {
      const planIds = result.data.map((plan) => plan.id);
      setPlans(planIds);
    }
  };

  useEffect(() => {
    if (will) {
      let will_data = will;
      if (
        will_data.married_status === "1" ||
        will_data.married_status === 1 ||
        will_data.married_status === true
      ) {
        if (typeof will_data.spouse_info === "string") {
          will_data.spouse_info = JSON.parse(will_data.spouse_info);
        }
      }

      if (
        will_data.have_children === "1" ||
        will_data.have_children === 1 ||
        will_data.have_children === true
      ) {
        if (typeof will_data.children_info === "string") {
          will_data.children_info = JSON.parse(will_data?.children_info);
        }
      }

      if (
        will_data.have_sibling === "1" ||
        will_data.have_sibling === 1 ||
        will_data.have_sibling === true
      ) {
        if (typeof will_data.sibling_info === "string") {
          will_data.sibling_info = JSON.parse(will_data?.sibling_info);
        }
      }

      if (will_data.body_part_detail) {
        if (typeof will_data.body_part_detail === "string") {
          will_data.body_part_detail = JSON.parse(will_data?.body_part_detail);
        }
      }

      setWillData(will_data);
    }
  }, [will]);

  const styles = {
    viewer: {
      width: "100%",
      height: "100vh",
    },
    page: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#ffffff",
      width: "100%",
      height: 600,
    },
    text: {
      fontSize: 20,
      textAlign: "center",
    },
    margin_class: {
      marginTop: "50px",
    },
  };

  const goReview = () => {
    history.push("/review");
  };

  const willItems = () => {
    let pdfItems = authUser.selected_wills.map((will, i) => {
      if (parseInt(will.will_details.is_master_plan)) return;
      const willType = will.will_details.title;
      const sectionTitle = willType.split("&")[0];

      switch (sectionTitle) {
        case "Last Will and Testament":
          return {
            key: 0,
            label: "Last Will and Testament",
            children: (
              <PDFViewer style={styles.page}>
                <MyDocument willData={willData} authUser={authUser} willId={will.id} />
              </PDFViewer>
            ),
          };
        case "Power of Attorney":
          return {
            key: 1,
            label: "Power of Attorney",
            children: (
              <PDFViewer style={styles.page}>
                <DurablePowerOfAttorney
                  willData={willData}
                  authUser={authUser}
                  willId={will.id}
                />
              </PDFViewer>
            ),
          };
        case "Living Will":
          return {
            key: 2,
            label: "Living Will",
            children: (
              <PDFViewer style={styles.page}>
                <LivingWill willData={willData} authUser={authUser} willId={will.id} />
              </PDFViewer>
            ),
          };
        case "Healthcare Surrogate":
          return {
            key: 3,
            label: "Healthcare Surrogate",
            children: (
              <PDFViewer style={styles.page}>
                <DesignationOfHealthSurrogate
                  willData={willData}
                  authUser={authUser}
                  willId={will.id}
                />
              </PDFViewer>
            ),
          };
      }
    });

    return pdfItems;

    return [
      {
        key: 0,
        label: "Last Will and Testament",
        children: (
          <PDFViewer style={styles.page}>
            <MyDocument willData={willData} authUser={authUser} />
          </PDFViewer>
        ),
      },
      {
        key: 1,
        label: "Power of Attorney",
        children: (
          <PDFViewer style={styles.page}>
            <DurablePowerOfAttorney willData={willData} authUser={authUser} />
          </PDFViewer>
        ),
      },
      {
        key: 2,
        label: "Living Will",
        children: (
          <PDFViewer style={styles.page}>
            <LivingWill willData={willData} authUser={authUser} />
          </PDFViewer>
        ),
      },
      {
        key: 3,
        label: "Healthcare Surrogate",
        children: (
          <PDFViewer style={styles.page}>
            <DesignationOfHealthSurrogate
              willData={willData}
              authUser={authUser}
            />
          </PDFViewer>
        ),
      },
    ];
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title> Preview Page </title>
      </MetaTags>
      <div style={{ margin: "45px 0px" }}>
        <Container>
          {/* <div  */}

          {willData && (
            <Tabs
              defaultActiveKey="1"
              style={{ height: 600 }}
              items={willItems()}
              onChange={() => {}}
            />
          )}

          {/* <MyDocument willData={willData} authUser={authUser} /> */}
          {/* <div style={styles.viewer}>
            {willData && (
              <>
                <PDFViewer style={styles.page}>
                  <DurablePowerOfAttorney
                    willData={willData}
                    authUser={authUser}
                  />
                </PDFViewer>

                <PDFViewer style={styles.page}>
                  <DesignationOfHealthSurrogate
                    willData={willData}
                    authUser={authUser}
                  />
                </PDFViewer>

                <PDFViewer style={styles.page}>
                  <LivingWill willData={willData} authUser={authUser} />
                </PDFViewer>
              </>
            )}
          </div> */}
          <div
            className="footer-button d-flex justify-content-between mt-8"
            style={{ marginTop: 80 }}
          >
            <Button
              type="primary primary-btn px-3 fw-bold mt-8"
              size="large"
              onClick={goReview}
            >
              Previous
            </Button>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Preview;
