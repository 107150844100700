import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";


const PdfStyles = StyleSheet.create({
  title: {
  //  fontFamily: "TimesNewRomen",
    fontSize: 16,
    textAlign: "center",
    textDecorationColor: "#000000",
    textDecoration: "underline",
    fontWeight: "600",
  },
  points: {
    fontSize: 13,
    lineHeight: 1.4,
    marginVertical: 10,
  },
  page: {
    paddingHorizontal: 20,
    paddingVertical: 40,
  },
  pageFooter: {
    fontSize: 9, bottom: 0, left: 0, right: 0, margin: "auto"  },
  section: {
    margin: 40,
  },
  pageBreak: {
    pageBreakBefore: "always",
  },
  check_container: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  checkbox: {
    width: 10,
    height: 10,
    marginRight: 5,
    borderWidth: 1,
    borderColor: "black",
  },
});

const DesignationOfHealthSurrogate = ({ willData, authUser, willId }) => {
  const [surrogate1Data, setSurrogate1Data] = useState(null);
  const [surrogate2Data, setSurrogate2Data] = useState(null);

  useEffect(() => {
    findSurrogateData(willData);
  }, []);

  const findSurrogateData = (willData) => {
    // console.log()
    const findWIll = authUser.selected_wills.find(will => will.id == willId);
    const surrogate_1 = findWIll.surrogate_1;
    const surrogate_2 = findWIll.surrogate_2;

    if (
      willData.married_status === "1" ||
      willData.married_status === 1 ||
      willData.married_status === true
    ) {
      let spouse_full_name =
        willData.spouse_info.first_name +
        " " +
        (willData.spouse_info.middle_name || "") +
        " " +
        willData.spouse_info.last_name;

      if (surrogate_1 === spouse_full_name) {
        const surroage1_data = {
          name: surrogate_1,
          address: `${willData.spouse_info.address_1}, ${
            willData.spouse_info.city || ""
          }, ${willData.spouse_info.state || ""}, ${
            willData.spouse_info.country || ""
          } - ${
            willData.spouse_info.zip || ""
          }`,
          phone: willData.spouse_info.phone,
          email: willData.spouse_info.email,
        };
        setSurrogate1Data(surroage1_data);
      }

      if (surrogate_2 === spouse_full_name) {
        const surroage2_data = {
          name: surrogate_2,
          address: `${willData.spouse_info.address_1}, ${
            willData.spouse_info.city || ""
          }, ${willData.spouse_info.state || ""}, ${
            willData.spouse_info.country || ""
          } - ${
            willData.spouse_info.zip || ""
          }`,
          phone: willData.spouse_info.phone,
          email: willData.spouse_info.email,
        };
        setSurrogate2Data(surroage2_data);
      }
    }

    if (
      willData.have_children === "1" ||
      willData.have_children === 1 ||
      willData.have_children === true
    ) {
      willData.children_info.map((item) => {
        const c_full_name =
          item.c_first_name +
          " " +
          (item.c_middle_name || "") +
          " " +
          item.c_last_name;

        if (surrogate_1 === c_full_name) {
          const surroage1_data = {
            name: surrogate_1,
            address: `${item.c_address_1}, ${item?.c_city}, ${item?.c_state}, ${item?.c_county} - ${item?.c_zip}`,
            phone: item.c_phone,
            email: item.c_email,
          };
          setSurrogate1Data(surroage1_data);
        }

        if (surrogate_2 === c_full_name) {
          const surroage2_data = {
            name: surrogate_2,
            address: `${item.c_address_1}, ${item?.c_city}, ${item?.c_state}, ${item?.c_county} - ${item?.c_zip}`,
            phone: item.c_phone,
            email: item.c_email,
          };
          setSurrogate2Data(surroage2_data);
        }
      });
    }

    if (
      willData.have_sibling === "1" ||
      willData.have_sibling === 1 ||
      willData.have_sibling === true
    ) {
      willData.sibling_info.map((item) => {
        const s_full_name =
          item.s_first_name +
          " " +
          (item.s_middle_name || "") +
          " " +
          item.s_last_name;
          console.log(s_full_name, surrogate_1, surrogate_2);

        if (surrogate_1 === s_full_name) {
          const surroage1_data = {
            name: surrogate_1,
            address: `${item.s_address_1}, ${item?.s_city}, ${item?.s_state}, ${item?.s_county} - ${item?.s_zip}`,
            phone: item.s_phone,
            email: item.s_email,
          };
          setSurrogate1Data(surroage1_data);
        }

        if (surrogate_2 === s_full_name) {
          const surroage2_data = {
            name: surrogate_2,
            address: `${item.s_address_1}, ${item?.s_city}, ${item?.s_state}, ${item?.s_county} - ${item?.s_zip}`,
            phone: item.s_phone,
            email: item.s_email,
          };
          setSurrogate2Data(surroage2_data);
        }
      });
    }
  };

  const relationShipStatus = (married_status) => {
    if (married_status == 0) {
      return "SINGLE";
    } else if (married_status == 1) {
      return "MARRIED";
    } else {
      return "WIDOWED";
    }
  };

  const getTestatorAddress = () => {
    let address = "";
    if (authUser.residential_address)
      address = address.concat(`${authUser.residential_address}, `);
    if (authUser.address2) address = address.concat(`${authUser.address2}, `);
    if (authUser.city) address = address.concat(`${authUser.city}, `);
    if (authUser.state) address = address.concat(`${authUser.state}`);
    if (authUser.zip) address = address.concat(` - ${authUser.zip}`);

    return address;
  };

  const testatorName = () => {
    return `${authUser.first_name} ${authUser.middle_name || ""} ${
      authUser.last_name
    }`;
  };

  return (
    <>
      {authUser && willData && (
        <>
          <Document>
            <Page size="A4" style={PdfStyles.page} pageNumber={1}>
              <Text wrap={true} style={PdfStyles.title}>
                DESIGNATION OF HEALTH CARE SURROGATE
                {"\n"}AND GUARDIAN
              </Text>

              <Text style={PdfStyles.points}>
                To all those interested and concerned:
              </Text>

              <>
                <Text style={PdfStyles.points}>
                  I, {testatorName()}, of {getTestatorAddress()}, in the event
                  that I have been determined to be incapacitated to provide
                  informed consent for medical treatment, surgical and
                  diagnostic procedures, and can not due to my incapacity, care
                  for myself, I wish to designate {surrogate1Data?.name} as my
                  health care surrogate for health decisions, and to be guardian
                  of my person and property:
                </Text>

                <Text style={PdfStyles.points}>
                  Name: {surrogate1Data?.name}
                  {"\n"}Address: {surrogate1Data?.address}
                  {"\n"}Phone: {surrogate1Data?.phone}
                  {"\n"}Email: {surrogate1Data?.email}
                </Text>
              </>

              <>
                <Text style={PdfStyles.points}>
                  If my surrogate and designated guardian, is unwilling or
                  unable to perform their duties, I wish to designate{" "}
                  {surrogate2Data?.name} as the successor surrogate and
                  guardian:
                </Text>

                <Text style={PdfStyles.points}>
                  Name: {surrogate2Data?.name}
                  {"\n"}
                  Address: {surrogate2Data?.address}
                  {"\n"}
                  Phone: {surrogate2Data?.phone}
                  {"\n"}
                  Email: {surrogate2Data?.email}
                </Text>
              </>

              <Text style={PdfStyles.points}>
                I authorize my healthcare surrogate to receive any of my health
                information, whether oral or recorded in any form or medium,
                that: (1) is created by a health care provider, health care
                facility, health plan, public health authority, employer, life
                insurer, school or university, or health care clearinghouse; and
                (2) relates to my past, present, or future physical or mental
                health or condition; the provision of health care to me; or the
                past, present, or future payment for the provisions of health
                care to me.
              </Text>

              <Text style={PdfStyles.points}>
                I further authorize my health care surrogate to make all health
                care decisions for me, which means he or she has the authority
                to: (1) provide informed consent, refusal of consent, or
                withdrawal of consent to any and all of my health care,
                including life-prolonging procedures; (2) apply on my behalf for
                private, public, government, or veterans’ benefits to defray the
                cost of health care; (3) access my health information reasonably
                necessary for the health care surrogate to make decisions
                involving my health care and to apply for benefits for me; and
                (4) decide to make an anatomical gift pursuant to part V of
                Chapter 765, Florida Statutes.
              </Text>

              <Text style={PdfStyles.points}>
                While I have decision making capacity, my wishes are controlling
                and my physicians and health care providers must clearly
                communicate to me the treatment plan or any change to the
                treatment plan prior to its implementation.
              </Text>

              <Text
                style={PdfStyles.pageFooter}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>

            <Page size="A4" style={PdfStyles.page}>
              <Text style={PdfStyles.points}>
                To the extent I am capable of understanding, my health care
                surrogate shall keep me reasonably informed of all decisions
                that he or she has made on my behalf and matters concerning to
                me.
              </Text>

              <Text style={PdfStyles.points}>
                THIS HEALTH CARE SURROGATE DESIGNATION IS NOT AFFECTED BY MY
                SUBSEQUENT INCAPACITY EXCEPT AS PROVIDED IN CHAPTER 765, FLORIDA
                STATUTES.
              </Text>

              <Text style={PdfStyles.points}>
                PURSUANT TO SECTION 765.104, FLORIDA STATUTES, I UNDERSTAND THAT
                I MAY, AT ANY TIME WHILE I RETAIN MY CAPACITY, REVOKE OR AMEND
                THIS DESIGNATION BY: (1) SIGNING A WRITTEN AND DATED INSTRUMENT
                WHICH EXPRESSES MY INTENT TO AMEND OR REVOKE THIS DESIGNATION;
                (2) PHYSICALLY DESTROYING THIS DESIGNATION THROUGH MY OWN ACTION
                OR BY THAT OF ANOTHER PERSON IN MY PRESENCE AND UNDER MY
                DIRECTION; (3) VERBALLY EXPRESSING MY INTENTION TO AMEND OR
                REVOKE THIS DESIGNATION; OR (4) SIGNING A NEW DESIGNATION THAT
                IS MATERIALLY DIFFERENT FROM THIS DESIGNATION.
              </Text>

              <Text style={PdfStyles.points}>
                MY HEALTH CARE SURROGATE’S AUTHORITY BECOMES EFFECTIVE WHEN MY
                PRIMARY PHYSICIAN DETERMINES THAT I AM UNABLE TO MAKE MY OWN
                HEALTH CARE DECISIONS UNLESS I INITIAL EITHER OR BOTH OF THE
                FOLLOWING BOXES:
              </Text>

              <Text style={PdfStyles.points}>
                IF I INITIAL THIS BOX [ ], MY HEALTH CARE SURROGATE’S AUTHORITY
                TO RECEIVE MY HEALTH INFORMATION TAKES EFFECT IMMEDIATELY.
              </Text>

              <Text style={PdfStyles.points}>
                IF I INITIAL THIS BOX [ ], MY HEALTH CARE SURROGATE’S AUTHORITY
                TO MAKE HEALTH CARE DECISIONS FOR ME TAKES EFFECT IMMEDIATELY.
                PURSUANT TO SECTION 765.204(3), FLORIDA STATUTES, ANY WRITING
                INSTRUCTIONS OR HEALTH CARE DECISIONS I MAKE, EITHER VERBALLY OR
                IN WRITING, WHILE I POSSESS CAPACITY SHALL SUPERSEDE ANY
                INSTRUCTIONS OR HEALTH CARE DECISIONS MADE BY MY SURROGATE THAT
                ARE IN MATERIAL CONFLICT WITH THOSE MADE BY ME.
              </Text>

              <Text style={PdfStyles.points}>
                I have declared this instrument to be my Designation of Health
                Care Surrogate and Guardian of the Person and Property for
                myself, and I have signed and executed on this ___ day of
                __________________20___.
              </Text>

              <Text style={PdfStyles.points}>
                ____________________________________
                {"\n"}
                {testatorName()}
              </Text>
              <Text style={PdfStyles.points}></Text>

              <Text
                style={PdfStyles.pageFooter}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>

            <Page size="A4" style={PdfStyles.page}>
              <Text style={PdfStyles.points}>
                On this ___ day of __________________20___, in our presence, the
                foregoing instrument declared by {testatorName()} to be their
                Designation of Health Care Surrogate and Guardian of the Person
                and Property, was signed and executed by them. We have signed
                and subscribed our names as witnesses to their signature at
                their request, in their presence, and in the presence of each
                other after they signed their name. {testatorName()} is known to
                us and we believe them to be of sound mind.
              </Text>

              <Text style={PdfStyles.points}>
                SIGNED, SEALED, AND DELIVERED
              </Text>
              <Text style={PdfStyles.points}>IN THE PRESENCE OF:</Text>

              <>
                <Text style={PdfStyles.points}>
                  Witness #1 Name:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;___________________________
                </Text>
                <Text style={PdfStyles.points}>
                  Witness #1 Signature:
                  &nbsp;&nbsp;&nbsp;&nbsp;___________________________
                </Text>
                <Text style={PdfStyles.points}>
                  Witness #1 Address:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;___________________________
                  {"\n"}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  ___________________________
                </Text>
              </>

              <>
                <Text style={PdfStyles.points}>
                  Witness #2 Name:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;___________________________
                </Text>
                <Text style={PdfStyles.points}>
                  Witness #2 Signature:
                  &nbsp;&nbsp;&nbsp;&nbsp;___________________________
                </Text>
                <Text style={PdfStyles.points}>
                  Witness #2 Address:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;___________________________
                  {"\n"}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  ___________________________
                </Text>
              </>

              <Text style={PdfStyles.points}>
                STATE OF FLORIDA{"\n"}COUNTY OF __________________________
              </Text>

              <Text style={PdfStyles.points}>
                We, {testatorName()}, ___________________________ (witness #1)
                and __________________________ (witness #2), the designator and
                witnesses, respectively, whose names are signed to this
                instrument, having been sworn, declared to the undersigned
                officer that the designator signed the instrument as their
                designation of health care surrogate and guardian of the person,
                and that they signed in the presence of the witnesses, who then
                signed in the presence of each other.
              </Text>

              <Text style={PdfStyles.points}>
                ___________________________________
                {"\n"}
                {testatorName()}
              </Text>

              <Text
                style={PdfStyles.pageFooter}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>

            <Page size="A4" style={PdfStyles.page}>
              <Text style={PdfStyles.points}>
                ___________________________________{"\n"}Witness #1
              </Text>
              <Text style={PdfStyles.points}>
                ___________________________________{"\n"}Witness #2
              </Text>

              <Text style={PdfStyles.points}>
                STATE OF FLORIDA{"\n"}COUNTY OF __________________________
              </Text>

              <Text style={PdfStyles.points}>
                The foregoing instrument was acknowledged before me by means of
                ☐ physical presence or ☐ online notarization, this ____ day of
                _______________ 20___ by {testatorName()}, the foregoing, ☐ who
                is personally known to me or ☐ who has produced
                ________________________ as identification and sworn to (or
                affirmed) and subscribed before me by means of ☐ physical
                presence or ☐ online notarization, this ____ day of
                _______________ 20___, by the witnesses
                ___________________________ (witness #1) and
                __________________________ (witness #2) ☐ who are personally
                known to me or ☐ who have produced ________________________ as
                identification.
              </Text>

              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <View>
                  <Text style={PdfStyles.points}>[Notary Seal]</Text>
                </View>

                <View>
                  <Text style={PdfStyles.points}>
                    ____________________________________________{"\n"}Notary
                    Public{"\n"}____________________________________________
                  </Text>
                  <Text style={PdfStyles.points}>
                    Name typed, printed or stamped
                  </Text>
                  <Text style={PdfStyles.points}>
                    My Commission Expires: ____________
                  </Text>
                </View>
              </View>

              <Text
                style={PdfStyles.pageFooter}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>
          </Document>
        </>
      )}
    </>
  );
};

export default DesignationOfHealthSurrogate;
