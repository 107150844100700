import {
  REGISTER_WILL_USER,
  REGISTER_WILL_USER_SUCCESS,
  REGISTER_WILL_USER_FAIL,
  LOGIN_WILL_USER,
  LOGIN_WILL_USER_SUCCESS,
  LOGIN_WILL_USER_FAIL,
  SET_AUTH_USER,
  UPDATE_WILL_USER,
  UPDATE_WILL_USER_SUCCESS,
  UPDATE_WILL_USER_FAIL,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  ADD_USER,
  ADD_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  LOGOUT_USER
} from "./actionTypes"


export const registerWillUser = (user, history) => {
  return {
    type: REGISTER_WILL_USER,
    payload: { user, history },
  }
}

export const registerWillUserSuccess = user => {
  return {
    type: REGISTER_WILL_USER_SUCCESS,
    payload: user,
  }
}

export const registerWillUserFail = error => {
  return {
    type: REGISTER_WILL_USER_FAIL,
    payload: error,
  }
}

export const updateWillUser = (user, history) => {
  return {
    type: UPDATE_WILL_USER,
    payload: { user, history },
  }
}

export const updateWillUserSuccess = user => {
  return {
    type: UPDATE_WILL_USER_SUCCESS,
    payload: user,
  }
}

export const updateWillUserFail = error => {
  return {
    type: UPDATE_WILL_USER_FAIL,
    payload: error,
  }
}

export const loginWillUser = (user, history) => {
  return {
    type: LOGIN_WILL_USER,
    payload: { user, history },
  }
}

export const loginWillUserSuccess = user => {
  return {
    type: LOGIN_WILL_USER_SUCCESS,
    payload: user,
  }
}

export const loginWillUserFail = error => {
  return {
    type: LOGIN_WILL_USER_FAIL,
    payload: error,
  }
}

export const setAuthUser = (user) => {
  return {
    type: SET_AUTH_USER,
    payload: user,
  }
}


export const getAllUsers = () => {
  return {
    type: GET_ALL_USERS,
  }
}

export const getAllUserSuccess = (users) => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: users,
  }
}


export const onAddNewUser = (user) => {
  return {
    type: ADD_USER,
    payload: {user}
  }
}

export const onAddNewUserSuccess = (user) => {
  return {
    type: ADD_USER_SUCCESS,
    payload: user,
  }
}

export const onUpdateUser = (user) => {
  return {
    type: UPDATE_USER,
    payload: {user}
  }
}

export const onUpdateUserSuccess = (user) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: user,
  }
}

export const onDeleteUser = (id) => {
  return {
    type: DELETE_USER,
    payload: {id}
  }
}

export const onDeleteUserSuccess = (id) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: id,
  }
}


export const logout = () => {
  return {
    type: LOGOUT_USER,
  }
}












