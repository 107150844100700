export const VERIFY_TOKEN = '/verify-token'
export const VERIFY_ADMIN_TOKEN = '/admin/verify-token'
export const REGISTER_WILL_USER = '/register'
export const LOGIN_WILL_USER = '/login'

export const REGISTER_ADMIN = '/admin/register'//**//
export const LOGIN_ADMIN = '/admin/login'//**//

export const GET_WILL_BY_USERID = '/wills'
export const SAVE_WILL_BY_USERID = '/wills'
export const UPDATE_WILL_BY_USERID = '/wills'
export const UPDATE_OTHER_RELATIONS_IN_WILL_BY_ID = '/update-other-relation'

export const UPDATE_WILL_USER = '/users'

export const GET_ALL_USERS = '/admin/users/all'
export const ADD_NEW_USER = '/admin/users'
export const UPDATE_USER = '/admin/users'
export const TOGGLE_USER_DOC_STATUS = '/admin/user/toggleDocSent'
export const DEL_USER = '/admin/users'
export const LOGOUT_USER = '/logout'


export const GET_WILL_PLANS = '/plans';
export const UPDATE_WILL_PLANS = '/update-plans';
export const UPDATE_LAST_WILL_TESTAMENT = '/last-will-and-testament';
export const UPDATE_SURROGATES = '/add-surrogates';