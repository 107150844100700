import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import { useSelector, useDispatch } from "react-redux"
import { getWillByUserId, changeEditStatus } from "../store/actions"
import { useHistory } from "react-router-dom";
import { EditOutlined } from '@ant-design/icons';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Row, Col } from "reactstrap"

const Review = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [willData, setWillData] = useState(null)
  const { will } = useSelector(state => state.Will)
  const { authUser } = useSelector(state => state.User)

  useEffect(() => {
    if (authUser) {
      dispatch(getWillByUserId(authUser.id))
    }
  }, [authUser])

  useEffect(() => {
    if (will) {
      let will_data = will
      if (will_data.married_status === '1' || will_data.married_status === 1 || will_data.married_status === true) {
        if (typeof will_data.spouse_info === 'string') {
          will_data.spouse_info = JSON.parse(will_data.spouse_info)
        }
      }

      if (will_data.have_children === '1' || will_data.have_children === 1 || will_data.have_children === true) {

        if (typeof will_data.children_info === 'string') {
          will_data.children_info = JSON.parse(will_data?.children_info)
        }

      }

      if (will_data.have_sibling === '1' || will_data.have_sibling === 1 || will_data.have_sibling === true) {

        if (typeof will_data.sibling_info === 'string') {
          will_data.sibling_info = JSON.parse(will_data?.sibling_info)
        }

      }

      if (will_data.body_part_detail) {
        if (typeof will_data.body_part_detail === 'string') {
          will_data.body_part_detail = JSON.parse(will_data?.body_part_detail)
        }

      }

      setWillData(will_data)
    }
  }, [will])

  const goBack = () => {
    history.push('/my-choices')
  }

  const goPreview = () => {
    history.push('/pre-view')
  }

  const editInfo = () => {
    dispatch(changeEditStatus(true))
    history.push('/will-register')
  }

  const editFamily = () => {
    dispatch(changeEditStatus(true))
    history.push('/your-info')
  }

  const editSurrogate = () => {
    dispatch(changeEditStatus(true))
    history.push('/surrogate')
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> Review Page </title>
      </MetaTags>
      <div style={{ margin: "45px 0px" }}>
        <Container>
          <h3 className="review-title" >Review your responses</h3>
          <p style={{ color: '#000000', fontSize: 20, marginRight: 5, lineHeight: 'normal' }}>
            Please review everything on this page to make sure it is in accordance
            with your wishes.
          </p>

          {authUser && (
            <div className="review-card">
              <div className="d-flex justify-content-between">
                <label className="review-card-title">Your Information</label>
                <div className="review-edit" onClick={editInfo}>
                  <label style={{ marginTop: 10, marginRight: 10 }}>Edit</label>
                  <EditOutlined style={{ fontSize: '24px', color: '#08c' }} />
                </div>
              </div>
              <div className="review-content mt-2">
                <div className="row">
                  <div className="col-md-4 review-item">
                    <p className="review-title">FIRST NAME</p>
                    <p className="review-result">{authUser?.first_name}</p>
                  </div>
                  <div className="col-md-4 review-item">
                    <p className="review-title">MIDDLE NAME</p>
                    <p className="review-result">{authUser?.middle_name}</p>
                  </div>
                  <div className="col-md-4 review-item">
                    <p className="review-title">LAST NAME</p>
                    <p className="review-result">{authUser?.last_name}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 review-item">
                    <p className="review-title">EMAIL</p>
                    <p className="review-result">{authUser?.email}</p>
                  </div>
                  <div className="col-md-4 review-item">
                    <p className="review-title">PHONE</p>
                    <p className="review-result">{authUser?.phone}</p>
                  </div>
                  <div className="col-md-4 review-item">
                    <p className="review-title">BIRTHDAY</p>
                    <p className="review-result">{authUser?.date_of_birth}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 review-item">
                    <p className="review-title">ADDRESS1</p>
                    <p className="review-result">{authUser?.residential_address}</p>
                  </div>
                  <div className="col-md-4 review-item">
                    <p className="review-title">ADDRESS2</p>
                    <p className="review-result">{authUser?.address2}</p>
                  </div>
                  <div className="col-md-4 review-item">
                    <p className="review-title">CITY</p>
                    <p className="review-result">{authUser?.city}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 review-item">
                    <p className="review-title">STATE</p>
                    <p className="review-result">{authUser?.state}</p>
                  </div>
                  <div className="col-md-4 review-item">
                    <p className="review-title">ZIP</p>
                    <p className="review-result">{authUser?.zip}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {willData && (
            <div className="review-card mt-4">
              <div className="d-flex justify-content-between">
                <label className="review-card-title"> Surrogate Information </label>
                <div className="review-edit" onClick={editSurrogate}>
                  <label style={{ marginTop: 10, marginRight: 10 }}>Edit</label>
                  <EditOutlined style={{ fontSize: '24px', color: '#08c' }} />
                </div>
              </div>
              <div className="review-content mt-2">
                <div className="row">
                  <div className="col-md-6 review-item">
                    <p className="review-title"> Surrogate 1 </p>
                    <p className="review-result">{willData?.surrogate_1}</p>
                  </div>
                  <div className="col-md-6 review-item">
                    <p className="review-title"> Surrogate 2 </p>
                    <p className="review-result">{willData?.surrogate_2}</p>
                  </div>

                </div>

              </div>
            </div>
          )}

          {willData && (
            <div className="review-card mt-4">
              <div className="d-flex justify-content-between">
                <label className="review-card-title"> Donate Body Information </label>
                <div className="review-edit" onClick={editSurrogate}>
                  <label style={{ marginTop: 10, marginRight: 10 }}>Edit</label>
                  <EditOutlined style={{ fontSize: '24px', color: '#08c' }} />
                </div>
              </div>
              <div className="review-content mt-2">
                {willData?.donate_body === '1' || willData?.donate_body === 1 || willData?.donate_body === true ? (
                  <div className="review-content mt-2">
                    <p>I am going to donate my body</p>
                    {willData?.donate_full_body === '0' || willData?.donate_full_body === 0 || willData?.donate_full_body === true ? (
                      <>
                        {willData?.body_part_detail?.map((item, index) => {
                          return (
                            <ul key={index}>{item.body_part_name}</ul>
                          )
                        })}
                      </>
                    ) : (
                      <label>I will donate my full body</label>
                    )}
                  </div>
                ) : (
                  <div>
                    <label> I don't want to donate my body </label>
                  </div>
                )}

              </div>
            </div>
          )}


          <div className="review-card mt-4">
            <div className="d-flex justify-content-between">
              <label className="review-card-title"> Marital Status </label>

              <div className="review-edit" onClick={editFamily}>
                <label style={{ marginTop: 10, marginRight: 10 }}>Edit</label>
                <EditOutlined style={{ fontSize: '24px', color: '#08c' }} />
              </div>
            </div>

            {(willData?.married_status === 1 || willData?.married_status === '1') ? (
              <div className="review-content mt-2">
                <div className="row">
                  <div className="col-md-4 review-item">
                    <p className="review-title">FIRST NAME</p>
                    <p className="review-result">{willData?.spouse_info?.first_name}</p>
                  </div>
                  <div className="col-md-4 review-item">
                    <p className="review-title">MIDDLE NAME</p>
                    <p className="review-result">{authUser?.spouse_info?.middle_name}</p>
                  </div>
                  <div className="col-md-4 review-item">
                    <p className="review-title">LAST NAME</p>
                    <p className="review-result">{authUser?.spouse_info?.last_name}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 review-item">
                    <p className="review-title">EMAIL</p>
                    <p className="review-result">{authUser?.spouse_info?.email}</p>
                  </div>
                  <div className="col-md-4 review-item">
                    <p className="review-title">PHONE</p>
                    <p className="review-result">{authUser?.spouse_info?.phone}</p>
                  </div>
                  <div className="col-md-4 review-item">
                    <p className="review-title">ADDRESS1</p>
                    <p className="review-result">{authUser?.spouse_info?.address_1}</p>
                  </div>
                </div>

                <div className="row">

                  <div className="col-md-4 review-item">
                    <p className="review-title">ADDRESS2</p>
                    <p className="review-result">{authUser?.spouse_info?.address_2}</p>
                  </div>
                  <div className="col-md-4 review-item">
                    <p className="review-title">CITY</p>
                    <p className="review-result">{authUser?.spouse_info?.city}</p>
                  </div>

                  <div className="col-md-4 review-item">
                    <p className="review-title">STATE</p>
                    <p className="review-result">{authUser?.spouse_info?.state}</p>
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-4 review-item">
                    <p className="review-title">ZIP</p>
                    <p className="review-result">{authUser?.spouse_info?.zip}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <label>I am single</label>
              </div>
            )}
          </div>

          {willData && (
            <div className="review-card mt-4">
              <div className="d-flex justify-content-between">
                <label className="review-card-title">Children Information</label>
                <div className="review-edit" onClick={editFamily}>
                  <label style={{ marginTop: 10, marginRight: 10 }}>Edit</label>
                  <EditOutlined style={{ fontSize: '24px', color: '#08c' }} />
                </div>
              </div>

              {willData?.have_children === '1' || willData?.have_children === 1 || willData?.have_children === true ? (
                <div className="review-content mt-2">
                  <p>I have {willData?.children_info?.lenth} children</p>
                  <div className="row">
                    <Row>
                      <Col>
                        <div className="table-rep-plugin">
                          <div
                            className="table-responsive mb-0"
                            data-pattern="priority-columns"
                          >
                            <Table
                              id="sbling-table"
                              className="table table-striped table-bordered"
                            >
                              <Thead>
                                <Tr>
                                  <Th>Full Name</Th>
                                  <Th data-priority="1">Email</Th>
                                  <Th data-priority="3">Phone</Th>
                                  <Th data-priority="1">Address</Th>
                                  <Th data-priority="3">City</Th>
                                  <Th data-priority="3">State</Th>
                                  <Th data-priority="6">Zip</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {
                                  willData?.children_info?.map((item, index) => {
                                    return (
                                      <Tr key={index}>
                                        <Td>{item?.c_first_name + ' ' + item?.c_last_name}</Td>
                                        <Td>{item?.c_email}</Td>
                                        <Td>{item?.c_phone}</Td>
                                        <Td>{item?.c_address_1}</Td>
                                        <Td>{item?.c_city}</Td>
                                        <Td>{item?.c_state}</Td>
                                        <Td>{item?.c_zip}</Td>
                                      </Tr>
                                    )
                                  })
                                }
                              </Tbody>
                            </Table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              ) : (
                <div>
                  <label>I have no child</label>
                </div>
              )}
            </div>
          )}

          {willData && (
            <div className="review-card mt-4">
              <div className="d-flex justify-content-between">
                <label className="review-card-title" > Sibling Information </label>

                <div className="review-edit" onClick={editFamily}>
                  <label style={{ marginTop: 10, marginRight: 10 }}>Edit</label>
                  <EditOutlined style={{ fontSize: '24px', color: '#08c' }} />
                </div>
              </div>
              {willData?.have_sibling === '1' || willData?.have_sibling === 1 || willData?.have_sibling === true ? (
                <div className="review-content mt-2">
                  <p>I have {willData?.sibling_info?.length} siblings</p>
                  <Row>
                    <Col>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="sbling-table"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>Full Name</Th>
                                <Th data-priority="1">Email</Th>
                                <Th data-priority="3">Phone</Th>
                                <Th data-priority="1">Address</Th>
                                <Th data-priority="3">City</Th>
                                <Th data-priority="3">State</Th>
                                <Th data-priority="6">Zip</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {
                                willData?.sibling_info?.map((item, index) => {
                                  return (
                                    <Tr key={index}>
                                      <Td>{item?.s_first_name + ' ' + item?.s_last_name}</Td>
                                      <Td>{item?.s_email}</Td>
                                      <Td>{item?.s_phone}</Td>
                                      <Td>{item?.s_address_1}</Td>
                                      <Td>{item?.s_city}</Td>
                                      <Td>{item?.s_state}</Td>
                                      <Td>{item?.s_zip}</Td>
                                    </Tr>
                                  )
                                })
                              }
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div>
                  <label> I have no sibling </label>
                </div>
              )}
            </div>
          )}



          <div className="review-footer mt-4">
            <Button type="text" onClick={goBack}> Back </Button>
            <Button type="primary" onClick={goPreview}> Preview PDF </Button>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Review;
