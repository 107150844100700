import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

export const registerWillUserAsync = data => post(url.REGISTER_WILL_USER, data)
export const loginWillUserAsync = data => post(url.LOGIN_WILL_USER, data)
export const updateWillUserAsync = user => put(`${url.UPDATE_WILL_USER}/${user.id}`, user)


export const registerAdminAsync = data => post(url.REGISTER_ADMIN, data)//**//
export const loginAdminAsync = data => post(url.LOGIN_ADMIN, data)//**//

export const getWillByUserIdAsync = userId => get(`${url.GET_WILL_BY_USERID}/${userId}`)
export const saveWillByUserIdAsync = willData => post(url.SAVE_WILL_BY_USERID, willData)
export const updateWillByUserIdAsync = willData => put(url.UPDATE_WILL_BY_USERID, willData)
export const updateWillSurrogatesAsync = willData => post(url.UPDATE_SURROGATES, willData)
export const updateOtherRelationWillByIdAsync = willData => {
  post(`${url.UPDATE_OTHER_RELATIONS_IN_WILL_BY_ID}`, willData)
}


//admin apis
export const getAllUsersAsync = () => get(`${url.GET_ALL_USERS}`)
export const addUserAsync = (user) => post(url.ADD_NEW_USER, user)
export const updateUserAsync = (user) => put(`${url.UPDATE_USER}/${user.id}`, user)
export const toggleUserDocStatusAsync = (user) => put(`${url.TOGGLE_USER_DOC_STATUS}/${user.id}`, {})
export const deleteUserAsync = (id) => del(`${url.DEL_USER}/${id}`)
export const logoutUserAsync = () => get(`${url.LOGOUT_USER}`)

export const updateWillPlanAsync = (id, data) => post(`${url.UPDATE_WILL_PLANS}/${id}`, data)
export const udpateLastWillTestament = (data) => post(`${url.UPDATE_LAST_WILL_TESTAMENT}`, data)
export const updateWillSurrogates = (data) => post(`${url.UPDATE_SURROGATES}`, data)