import { takeEvery, fork, put, all, call } from "redux-saga/effects"
//Account Redux states
import { REGISTER_ADMIN } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"
import { registerAdminAsync } from "../../../helpers/backend_helper"

function* registerAdmin({ payload: { user, history }}) {
  try{
    const response = yield call(registerAdminAsync, user)
    if(response.success) {
      yield put(registerUserSuccessful(response.user))
      history.push('/admin/login')
    } 
  } catch (error) {
    yield put(registerUserFailed(error.response.data))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_ADMIN, registerAdmin)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
