
import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const PdfStyles = StyleSheet.create({
  title: {
   // fontFamily: "TimesNewRomen",
    fontSize: 16,
    textAlign: "center",
    textDecorationColor: "#000000",
    textDecoration: "underline",
    fontWeight: "800",
  },
  points: {
    fontSize: 13,
    lineHeight: 1.4,
    marginVertical: 10,
  },
  page: {
    paddingHorizontal: 20,
    paddingVertical: 40,
  },
  pageFooter: {
    fontSize: 9,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  section: {
    margin: 40,
  },
  pageBreak: {
    pageBreakBefore: "always",
  },
  check_container: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  checkbox: {
    width: 10,
    height: 10,
    marginRight: 5,
    borderWidth: 1,
    borderColor: "black",
  },
});

const DurablePowerOfAttorney = ({ willData, authUser }) => {
  const [surrogate1Data, setSurrogate1Data] = useState(null);
  const [surrogate2Data, setSurrogate2Data] = useState(null);

  useEffect(() => {
    findSurrogateData(willData);
  }, []);

  const findSurrogateData = (willData) => {
    const surrogate_1 = willData.surrogate_1;
    const surrogate_2 = willData.surrogate_2;
    console.log(surrogate_1);
    if (
      willData.married_status === "1" ||
      willData.married_status === 1 ||
      willData.married_status === true
    ) {
      let spouse_full_name =
        willData.spouse_info.first_name +
        " " +
        (willData.spouse_info.middle_name || "") +
        " " +
        willData.spouse_info.last_name;

      if (surrogate_1 === spouse_full_name) {
        const surroage1_data = {
          name: surrogate_1,
          address: willData.spouse_info.address_1,
          phone: willData.spouse_info.phone,
          email: willData.spouse_info.email,
        };
        setSurrogate1Data(surroage1_data);
      }

      if (surrogate_2 === spouse_full_name) {
        const surroage2_data = {
          name: surrogate_2,
          address: willData.spouse_info.address_1,
          phone: willData.spouse_info.phone,
          email: willData.spouse_info.email,
        };
        setSurrogate2Data(surroage2_data);
      }
    }

    if (
      willData.have_children === "1" ||
      willData.have_children === 1 ||
      willData.have_children === true
    ) {
      willData.children_info.map((item) => {
        const c_full_name =
          item.c_first_name +
          " " +
          (item.c_middle_name || "") +
          " " +
          item.c_last_name;

        if (surrogate_1 === c_full_name) {
          const surroage1_data = {
            name: surrogate_1,
            address: item.c_address_1,
            phone: item.c_phone,
            email: item.c_email,
          };
          setSurrogate1Data(surroage1_data);
        }

        if (surrogate_2 === c_full_name) {
          const surroage2_data = {
            name: surrogate_2,
            address: item.c_address_1,
            phone: item.c_phone,
            email: item.c_email,
          };
          setSurrogate2Data(surroage2_data);
        }
      });
    }

    if (
      willData.have_sibling === "1" ||
      willData.have_sibling === 1 ||
      willData.have_sibling === true
    ) {
      willData.sibling_info.map((item) => {
        const s_full_name =
          item.s_first_name +
          " " +
          (item.s_middle_name || "") +
          " " +
          item.s_last_name;

        if (surrogate_1 === s_full_name) {
          const surroage1_data = {
            name: surrogate_1,
            address: item.s_address_1,
            phone: item.s_phone,
            email: item.s_email,
          };
          setSurrogate1Data(surroage1_data);
        }

        if (surrogate_2 === s_full_name) {
          const surroage2_data = {
            name: surrogate_2,
            address: item.s_address_1,
            phone: item.s_phone,
            email: item.s_email,
          };
          setSurrogate2Data(surroage2_data);
        }
      });
    }
  };

  const relationShipStatus = (married_status) => {
    if (married_status == 0) {
      return "SINGLE";
    } else if (married_status == 1) {
      return "MARRIED";
    } else {
      return "WIDOWED";
    }
  };

  const getTestatorAddress = () => {
    let address = "";
    if (authUser.residential_address)
      address = address.concat(`${authUser.residential_address}, `);
    if (authUser.address2) address = address.concat(`${authUser.address2}, `);
    if (authUser.city) address = address.concat(`${authUser.city}, `);
    if (authUser.state) address = address.concat(`${authUser.state}`);
    if (authUser.zip) address = address.concat(` - ${authUser.zip}`);

    return address;
  };

  const testatorName = () => {
    return `${authUser.first_name} ${authUser.middle_name || ""} ${
      authUser.last_name
    }`;
  };

  return (
    <>
      {authUser && willData && (
        <>
          <Document>
            <Page size="A4" style={PdfStyles.page}>
              <Text wrap={true} style={PdfStyles.title}>
                DURABLE POWER OF ATTORNEY
              </Text>

              <Text style={PdfStyles.points}>
                I, {testatorName()}, of {getTestatorAddress()} (hereinafter
                referred to as PRINCIPAL), designate {surrogate1Data?.name}, who
                resides at {surrogate1Data?.address}, (hereinafter referred to
                as AGENT), to be my attorney-in-fact and agent. If this
                designated individual is unwilling or unable to perform their
                duty as attorney-in-fact, I wish to designate{" "}
                {surrogate2Data?.name}, who resides at {surrogate2Data?.address}
                , as my alternate and successor attorney-in-fact and agent.
              </Text>

              <Text style={PdfStyles.points}>
                Authority of Agent. I hereby grant to AGENT full power and
                authority to act for me in any lawful way with respect to the
                powers enumerated below.
              </Text>

              <Text style={PdfStyles.points}>
                Except as otherwise limited by applicable law or by this durable
                power of attorney, AGENT has full power and authority to
                perform, without prior court approval, and may take all
                necessary actions to exercise, any power herein granted as fully
                as I might or could do if personally present. This power is not
                diminished even though AGENT may be acting individually or on
                behalf of any other person or entity interested in the same
                matters. All acts done under this power by AGENT shall bind me,
                my heirs, devisees, and personal representatives. This power is
                nondelegable. I hereby ratify and confirm that AGENT shall
                lawfully have, by virtue of this durable power of attorney, the
                powers herein granted, specifically, the authority to:
              </Text>

              <Text style={PdfStyles.points}>
                a. forgive, request, demand, sue for, recover, collect, receive,
                and hold all sums of money, debts, dues, commercial paper,
                checks, drafts, accounts, deposits, legacies, bequests, devises,
                notes, interests, stock certificates, bonds, dividends,
                certificates of deposit, annuities, pensions, profit sharing,
                retirement, social security, insurance, and other contractual
                benefits and proceeds, intangible and tangible property and
                property rights and any demands whatsoever, liquidated or
                unliquidated, that I now or hereafter own or that are due,
                owing, or payable or belonging to me or in which I may now have
                or hereafter acquire an interest.
              </Text>

              <Text style={PdfStyles.points}>
                b. have, use, and take all lawful means and equitable and legal
                remedies and proceedings in my name for the collection and
                recovery of any property now or hereafter owned by me, and
                adjust, sell, compromise, and agree for the same, and execute
                and deliver for me, on my behalf and in my name, all
                endorsements, releases, receipts, or other sufficient discharges
                for the same.
              </Text>

              <Text style={PdfStyles.points}>
                c. conduct investment transactions as provided in section
                709.2208(2), Florida Statutes; and acquire, purchase, invest,
                reinvest, exchange, grant options to sell, and sell and convey
                personal property, tangible or intangible, or interests therein,
                for such price and on such terms and conditions as AGENT shall
                deem proper, including, without limitation, stocks, bonds,
                warrants, debentures, commodities, precious metals, futures,
                currencies, and investment funds, including common trust funds,
                in domestic and foreign markets.
              </Text>

              <Text
                style={PdfStyles.pageFooter}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>

            <Page size="A4" style={PdfStyles.page}>
              <Text style={PdfStyles.points}>
                d. execute stock powers or similar documents and delegate to a
                transfer agent or similar person the authority to register any
                stocks, bonds, or other securities either into or out of my name
                or my nominee's name.
              </Text>

              <Text style={PdfStyles.points}>
                e. redeem bonds issued by the United States Government or any of
                its agencies or any other bonds.
              </Text>

              <Text style={PdfStyles.points}>
                f. acquire, purchase, exchange, grant options to sell, and sell
                and convey any and all of my real estate, lands, tenements,
                leases, leaseholds or other property in the nature of real
                estate, or any part or parcel thereof, which I now own or may
                hereafter acquire, or interests therein, including my homestead
                real property, at public or private sale, for such price and on
                such terms and conditions as AGENT shall deem proper, and
                execute any and all documents necessary to effectuate the same,
                including but not limited to contracts, deeds, affidavits, bills
                of sale, assignments, and closing statements; provided, however,
                that if I am married, AGENT may not convey or dispose of my
                homestead property without joinder of my spouse or my spouse's
                legal guardian. (Joinder by my spouse may be accomplished by the
                exercise of authority in a durable power of attorney executed by
                my spouse, and either my spouse or I may appoint the other as
                attorney-in-fact.)
              </Text>

              <Text style={PdfStyles.points}>
                g. maintain, repair, improve, invest, manage, partition, insure,
                rent, lease, encumber, and in any manner deal with any real or
                personal property, tangible or intangible, or any interest
                therein, that I now own or may hereafter acquire, in my name and
                for my benefit, upon such terms and conditions that AGENT shall
                deem proper, and execute, acknowledge, and deliver all
                instruments necessary to effectuate the foregoing.
              </Text>

              <Text style={PdfStyles.points}>
                h. conduct banking transactions as provided in section
                709.2208(1), Florida Statutes.
              </Text>

              <Text style={PdfStyles.points}>
                i. borrow from time to time such sums of money upon such terms
                and conditions as AGENT shall deem appropriate for, or in
                relation to, any of the purposes or objects described herein,
                upon the security of any of my property, whether real or
                personal or otherwise, and, for such purposes, to give, execute,
                deliver, and acknowledge mortgages with such power and
                provisions as AGENT may think proper, and also such notes,
                bonds, or other instruments as may be necessary or proper in
                connection therewith; provided, however, that if I am married,
                AGENT shall not mortgage my homestead property without joinder
                of my spouse or my spouse's legal guardian. (Joinder by my
                spouse may be accomplished by the exercise of authority in a
                durable power of attorney executed by my spouse, and either my
                spouse or I may appoint the other as attorney-in-fact.)
              </Text>

              <Text style={PdfStyles.points}>
                j. apply for a certificate of title on, and endorse and transfer
                title to, any automobile, truck, recreational vehicle, off-road
                vehicle, van, motorcycle, or other motor vehicle, airplane, or
                vessel, and to represent in such transfer or assignment that the
                title to said motor vehicle, airplane, or vessel is free and
                clear of all liens and encumbrances except those specifically
                set forth in such transfer or assignment.
              </Text>
              <Text
                style={PdfStyles.pageFooter}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>

            <Page size="A4" style={PdfStyles.page}>
              <Text style={PdfStyles.points}>
                k. conduct or participate in any lawful business of whatever
                nature for me and in my name; execute partnership agreements and
                amendments thereto; incorporate, reorganize, merge, consolidate,
                recapitalize, sell, liquidate, or dissolve any business; enter
                into voting trusts and other agreements or subscriptions; elect
                or employ officers, directors, and agents; carry out the
                provisions of any agreement for the sale of any business
                interest or stock therein; exercise voting rights with respect
                to stock, either in person or proxy; and exercise stock options.
              </Text>

              <Text style={PdfStyles.points}>
                l. transfer any or all assets of mine to any revocable trust
                created by me as to which trust I am, during my life, a primary
                income or principal beneficiary.
              </Text>

              <Text style={PdfStyles.points}>
                m. withdraw from any trust, whether revocable or irrevocable, in
                which I have a current beneficial interest, such amounts of
                principal or accrued or collected but undistributed income of
                such trusts as I would be permitted to receive or withdraw,
                pursuant to any right of receipt or withdrawal contained in such
                trusts.
              </Text>

              <Text style={PdfStyles.points}>
                n. make, execute, and file any and all declarations, joint or
                separate returns, waivers, consents, claims, and other
                instruments or forms (including, without limitation, IRS Form
                2848: Power of Attorney) relating to federal, state, municipal,
                and other taxes or assessments, including income, transfer,
                property, excise, and other taxes of whatever nature and whether
                imposed or required by any domestic or foreign authority, and in
                connection with any such taxes or assessments due or claimed or
                believed to be due from me or in respect of any property or
                rights that I may own or in which I may have any interest.
              </Text>

              <Text style={PdfStyles.points}>
                o. represent me before any office of the Internal Revenue
                Service, state agency, or any other governmental or municipal
                body or authority of whatever nature, domestic or foreign, and
                conduct and transact any case, claim, or other matter whatsoever
                in connection therewith; receive confidential information
                regarding tax matters for all periods, whether before or after
                the execution of this instrument; and make tax elections.
              </Text>

              <Text style={PdfStyles.points}>
                p. have access at any time or times to any safe-deposit box
                rented by me, wheresoever located, and remove all or any part of
                the contents thereof, and surrender or relinquish said
                safe-deposit box, and any institution in which any such
                safe-deposit box may be located shall not incur any liability to
                me or my estate as a result of permitting AGENT to exercise this
                power.
              </Text>

              <Text style={PdfStyles.points}>
                q. exercise any statutory rights or elections, including, but
                not limited to, any rights or elections in any probate or
                similar proceeding to which I am or may become entitled.
              </Text>

              <Text
                style={PdfStyles.pageFooter}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>

            <Page size="A4" style={PdfStyles.page}>
              <Text style={PdfStyles.points}>
                r. employ as investment counsel, custodians, brokers,
                accountants, appraisers, attorneys-at-law or other agents such
                persons, firms, or organizations, including AGENT or AGENT's
                firm, as deemed necessary or desirable; pay such persons, firms,
                or organizations such compensation as is deemed reasonable; and
                determine whether to act on the advice of any such agent without
                liability for acting or failing to act thereon.
              </Text>

              <Text style={PdfStyles.points}>
                s. (i) access, manage, control, delete and terminate any digital
                assets, electronic records, computer, telephone, digital
                devices, data storage device, user account, electronically
                stored information, and any domain name, whether the same are in
                my name or that I own, or that I lawfully use jointly with
                anyone else, including but not limited to, e-mail, telephone,
                bank, brokerage, investment, insurance, social networking,
                internet service provider, retail vendor, utilities and other
                accounts; (ii) change my username and password to gain access to
                such accounts and information; and (iii) transfer or withdraw
                funds or other assets among or from such accounts. I hereby give
                my lawful consent and fully authorize my AGENT to access,
                manage, control, delete and terminate any electronically stored
                information and communications to the fullest extent allowable
                under the Electronic Communications Privacy Act of 1986, as
                amended, the Gramm-Leach-Bliley Act, as amended, and any other
                applicable laws and to take any actions, I am authorized to take
                under all applicable terms of service, terms of use, licensing
                and other account agreements or laws. To the extent a specific
                reference to any statute is required in order to give effect to
                this provision, I specifically provide that my intention is to
                so reference such statute, whether it is not in existence or
                comes into existence or is amended after the date of this
                document.
              </Text>

              <Text style={PdfStyles.points}>
                AGENT is also authorized, if I have initialed the specific act
                below, to:
              </Text>

              <Text style={PdfStyles.points}>Initial:</Text>

              <Text style={PdfStyles.points}>
                (             ) a. make gifts to charitable organizations or to
                or in trust for my spouse or any descendant of mine in
                connection with estate, gift, generation-skipping transfer,
                income, or other tax planning for me or to qualify me for any
                government assistance program; provided, however, that no gift
                may be made to AGENT other than for AGENT's health and
                maintenance or to discharge AGENT's legal obligations.
              </Text>

              <Text style={PdfStyles.points}>
                (             ) b. consent to any gift, use any gift-splitting
                provision or tax election, and pay gift taxes, but only if in
                furtherance of my estate plan or my desire to minimize taxes.
              </Text>

              <Text style={PdfStyles.points}>
                (             ) c. create one or more inter vivos trusts of
                which I am an income or principal beneficiary.
              </Text>

              <Text style={PdfStyles.points}>
                (             ) d. with respect to a trust created by or on
                behalf of the PRINCIPAL, amend, modify, revoke, or terminate a
                trust created by me or on my behalf, but only if the trust
                instrument explicitly provides for amendment, modification,
                revocation, or termination by the settlor's agent.
              </Text>

              <Text
                style={PdfStyles.pageFooter}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>

            <Page size="A4" style={PdfStyles.page}>
              <Text style={PdfStyles.points}>
                (             ) e. create or change rights of survivorship.
              </Text>

              <Text style={PdfStyles.points}>
                (             ) f. create or change a beneficiary designation.
              </Text>

              <Text style={PdfStyles.points}>
                (             ) g. waive the PRINCIPAL's right to be a
                beneficiary of a joint and survivor annuity, including a
                survivor benefit under a retirement plan.
              </Text>

              <Text style={PdfStyles.points}>
                (             ) h. renounce or disclaim any powers of
                appointment or property passing to me by testate or intestate
                succession or by inter vivos transfer.
              </Text>

              <Text style={PdfStyles.points}>
                Interpretation and Governing Law.    This instrument is to be
                construed and interpreted as a general durable power of
                attorney.    This instrument is executed and delivered in the
                State of Florida, and the laws of Florida shall govern all
                questions as to the validity of this power and the construction
                of its provisions.    However, it is my intention that this
                power of attorney shall be exercisable in any other state or
                jurisdiction where I may have any property or interests in
                property.
              </Text>

              <Text style={PdfStyles.points}>
                Third-Party Reliance.    Third parties may rely on the
                representations of AGENT as to all matters relating to any power
                granted to AGENT, and no person who may act in reliance on the
                representations of AGENT shall incur any liability to me or to
                my estate, beneficiaries, or joint owners as a result of
                permitting AGENT to exercise any power prior to receipt of a
                written notice of revocation, suspension, petition to determine
                my incapacity, partial or complete termination of this power, or
                my death.    Any third party may rely on a duly executed
                counterpart of this instrument, or a copy certified by AGENT to
                be a true copy of the original hereof, as fully and completely
                as if such third party had received the original of this
                instrument.
              </Text>

              <Text style={PdfStyles.points}>
                Disability of Principal.    This durable power of attorney is
                not terminated by my subsequent incapacity, except as provided
                in section 709, Florida Statutes, or any successor provision of
                law.
              </Text>

              <Text style={PdfStyles.points}>
                IN WITNESS WHEREOF, I have set my hand and seal on this ___ day
                of __________________20___.
              </Text>

              <Text style={PdfStyles.points}>
                ____________________________________
              </Text>

              <Text style={PdfStyles.points}>{testatorName()}, Principal</Text>
              
              <Text style={PdfStyles.points}>
                SIGNED, SEALED, AND DELIVERED
                {"\n"}IN THE PRESENCE OF:
              </Text>
              
              <Text
                style={PdfStyles.pageFooter}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>

            <Page size="A4" style={PdfStyles.page}>
              <>
                <Text style={PdfStyles.points}>
                  Witness #1 Name:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;___________________________
                </Text>
                <Text style={PdfStyles.points}>
                  Witness #1 Signature:
                  &nbsp;&nbsp;&nbsp;&nbsp;___________________________
                </Text>
                <Text style={PdfStyles.points}>
                  Witness #1 Address:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;___________________________
                  {"\n"}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  ___________________________
                </Text>
              </>

              <>
                <Text style={PdfStyles.points}>
                  Witness #2 Name:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;___________________________
                </Text>
                <Text style={PdfStyles.points}>
                  Witness #2 Signature:
                  &nbsp;&nbsp;&nbsp;&nbsp;___________________________
                </Text>
                <Text style={PdfStyles.points}>
                  Witness #2 Address:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;___________________________
                  {"\n"}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  ___________________________
                </Text>
              </>

              <Text style={PdfStyles.points}>
                STATE OF FLORIDA{"\n"}COUNTY OF __________________________
              </Text>

              <Text style={PdfStyles.points}>
                We, {testatorName()}, ___________________________ (witness #1)
                and __________________________ (witness #2), the designator and
                witnesses, respectively, whose names are signed to this
                instrument, having been sworn, declared to the undersigned
                officer that the designator signed the instrument as their
                designation of health care surrogate and guardian of the person,
                and that they signed in the presence of the witnesses, who then
                signed in the presence of each other.
              </Text>

              <Text style={PdfStyles.points}>
                ___________________________________
                {"\n"}
                {testatorName()}
              </Text>



              <Text style={PdfStyles.points}>
                ___________________________________{"\n"}Witness #1
              </Text>
              <Text style={PdfStyles.points}>
                ___________________________________{"\n"}Witness #2
              </Text>

              <Text style={PdfStyles.points}>
                STATE OF FLORIDA{"\n"}COUNTY OF __________________________
              </Text>

              <Text
                style={PdfStyles.pageFooter}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>

            <Page size="A4" style={PdfStyles.page}>
              {/* <Text style={PdfStyles.points}>
                ___________________________________{"\n"}Witness #1
              </Text>
              <Text style={PdfStyles.points}>
                ___________________________________{"\n"}Witness #2
              </Text>

              <Text style={PdfStyles.points}>
                STATE OF FLORIDA{"\n"}COUNTY OF __________________________
              </Text> */}

              <Text style={PdfStyles.points}>
                The foregoing instrument was acknowledged before me by means of
                ☐ physical presence or ☐ online notarization, this ____ day of
                _______________ 20___ by {testatorName()}, the foregoing, ☐ who
                is personally known to me or ☐ who has produced
                ________________________ as identification and sworn to (or
                affirmed) and subscribed before me by means of ☐ physical
                presence or ☐ online notarization, this ____ day of
                _______________ 20___, by the witnesses
                ___________________________ (witness #1) and
                __________________________ (witness #2) ☐ who are personally
                known to me or ☐ who have produced ________________________ as
                identification.
              </Text>

              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <View>
                  <Text style={PdfStyles.points}>[Notary Seal]</Text>
                </View>

                <View>
                  <Text style={PdfStyles.points}>
                    ____________________________________________{"\n"}Notary
                    Public{"\n"}____________________________________________
                  </Text>
                  <Text style={PdfStyles.points}>
                    Name typed, printed or stamped
                  </Text>
                  <Text style={PdfStyles.points}>
                    My Commission Expires: ____________
                  </Text>
                </View>
              </View>
            </Page>
          </Document>
        </>
      )}
    </>
  );
};

export default DurablePowerOfAttorney;
