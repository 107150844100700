import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { useSelector, useDispatch } from "react-redux"
import { setAuthUser, logout } from "../../store/actions"
import { useHistory } from "react-router-dom";
import { DownOutlined, UserAddOutlined, LogoutOutlined } from '@ant-design/icons';
import { Dropdown, Space, Button } from 'antd';
import user1 from "../../assets/images/users/avatar-1.jpg"
import {axiosApi} from '../../helpers/api_helper'

import logoPng from "../../assets/images/do-your-will-logo.png"

const Header = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { authUser } = useSelector(state => state.User)

  const onLogOut = () => {
    // dispatch(logout())
    axiosApi.defaults.headers.common["Authorization"] = null
    localStorage.removeItem('access_token');
    dispatch(setAuthUser(null))
    history.push('/login')
  }

  const goProfile = () => {
    history.push('/profile')
  }

  const items = [
    {
      key: '1',
      label: (<label style={{marginTop: 10}} onClick={goProfile}>Profile</label>),
      icon: <UserAddOutlined />,
    },
    {
      key: '2',
      label: (<label style={{marginTop: 10}} onClick={onLogOut}>Logout</label>),
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <header className="header">
      <Container>
        <Link to="/home">
        {/* "/images/layout/do-your-will-logo.png" */}
          <img
            src={logoPng}
            alt=""
            className="home-logo logo hover-zoom-effect"
          />
        </Link>

        {authUser ? (
          <div>
            <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />
            <Dropdown
              menu={{
                items,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {authUser?.first_name + ' ' + authUser?.last_name}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>

          </div>
        ) : (
          <Link to="/will-register">
            <Button
              type="primary primary-btn px-3 px-sm-4 flex-shrink-1 hover-zoom-effect"
              size="large"
            >
              GET STARTED!
            </Button>
          </Link>
        )}

      </Container>
    </header>
  );
};

export default Header;
