import React, { useEffect, useState } from 'react';
import { MetaTags } from "react-meta-tags";
import { useSelector, useDispatch } from "react-redux"
import { getWillByUserId } from "../store/actions"
import { Button } from "antd";
import {
  Card,
  CardBody,
  Col,
  Row,
  FormGroup,
  Label,
  Container
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation"


const Profile = (props) => {

  const { authUser } = useSelector(state => state.User)

  const handleFormSubmit = (event, values) => {
    event.preventDefault();
    console.log('values=>', values)
  };


  return (
    <React.Fragment>
      <MetaTags>
        <title> Profile Page </title>
      </MetaTags>
      <div style={{ margin: "45px 0px" }}>
        <Container>
          <Row>
            <Col lg={12}>
              <Card>
                <Row className="g-0 align-items-center">
                  <Col md={12}>
                    <CardBody>
                      <AvForm className="needs-validation" onValidSubmit = {handleFormSubmit}>
                        <Row>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="first_name">First Name</Label>
                              <AvField
                                name="first_name"
                                placeholder="First name"
                                type="text"
                                errorMessage="Enter First Name"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="first_name"
                                value = {authUser?.first_name || ''}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="middle_name">Middle Name</Label>
                              <AvField
                                name="middle_name"
                                placeholder="Middle name"
                                type="text"
                                errorMessage="Enter Middle name"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="middle_name"
                                value = {authUser?.middle_name || ''}

                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="last_name">Last name</Label>
                              <AvField
                                name="last_name"
                                placeholder="Last name"
                                type="text"
                                errorMessage="Enter Last name"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="last_name"
                                value = {authUser?.last_name || ''}

                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="email">Email</Label>
                              <AvField
                                name="email"
                                placeholder="Email"
                                type="email"
                                errorMessage="Enter Email"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="email"
                                value = {authUser?.email || ''}

                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="phon">Phone Number</Label>
                              <AvField
                                name="phone"
                                placeholder="Phone Number"
                                type="text"
                                errorMessage="Enter Phone Number"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="phone"
                                value = {authUser?.phone || ''}

                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="suffix">Suffix</Label>
                              <AvField
                                name="suffix"
                                placeholder="Suffix"
                                type="text"
                                errorMessage="Enter suffix"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="suffix"
                                value = {authUser?.suffix || ''}

                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="date_of_birth">Date of Birth</Label>
                              <AvField
                                name="date_of_birth"
                                placeholder="Date of Birth"
                                type="text"
                                errorMessage=" Please provide a valid Date of Birth."
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="date_of_birth"
                                value = {authUser?.date_of_birth || ''}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="residential_address">Residential Address</Label>
                              <AvField
                                name="residential_address"
                                placeholder="Residential Address"
                                type="text"
                                errorMessage="Please provide a Residential Address"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="residential_address"
                                value = {authUser?.residential_address || ''}
                                
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="address2">Address2</Label>
                              <AvField
                                name="address2"
                                placeholder="Address2"
                                type="text"
                                errorMessage=" Please provide a valid Address2."
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="address2"
                                value = {authUser?.address2 || ''}

                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="city">City</Label>
                              <AvField
                                name="city"
                                placeholder="City"
                                type="text"
                                errorMessage=" Please provide a valid city."
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="city"
                                value = {authUser?.city || ''}

                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="state">State</Label>
                              <AvField
                                name="state"
                                placeholder="State"
                                type="text"
                                errorMessage="Please provide a valid state."
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="state"
                                value = {authUser?.state || ''}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="county">County</Label>
                              <AvField
                                name="county"
                                placeholder="County"
                                type="text"
                                errorMessage="Please provide a valid County."
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="county"
                                value = {authUser?.county || ''}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="zip">Zip</Label>
                              <AvField
                                name="zip"
                                placeholder="Zip Code"
                                type="text"
                                errorMessage=" Please provide a valid zip."
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="zip"
                                value = {authUser?.zip || ''}

                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Button color="primary" type="primary" htmlType="submit">
                          UPDATE
                        </Button>
                      </AvForm>
                    </CardBody>
                  </Col>
                </Row>
              </Card>
            </Col>


          </Row>
        </Container>

      </div>
    </React.Fragment>
  );
};

export default Profile;
