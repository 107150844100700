import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  REGISTER_WILL_USER,
  LOGIN_WILL_USER,
  UPDATE_WILL_USER,
  GET_ALL_USERS,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  LOGOUT_USER
} from "./actionTypes"
import {
  registerWillUserSuccess,
  registerWillUserFail,
  loginWillUserSuccess,
  loginWillUserFail,
  updateWillUserSuccess,
  updateWillUserFail,
  getAllUserSuccess,
  onAddNewUserSuccess,
  onUpdateUserSuccess,
  onDeleteUserSuccess
} from "./actions"

import { axiosApi } from '../../helpers/api_helper'

//Include Both Helper File with needed methods
import {
  registerWillUserAsync,
  loginWillUserAsync,
  updateWillUserAsync,
  getAllUsersAsync,
  addUserAsync,
  updateUserAsync,
  deleteUserAsync,
  logoutUserAsync
} from "../../helpers/backend_helper"

function* registerWillUser({ payload: { user, history } }) {
  try {
    const response = yield call(registerWillUserAsync, user)
    if (response.success) {
      localStorage.setItem('access_token', response.token)
      axiosApi.defaults.headers.common["Authorization"] = "Bearer " + response.token
      yield put(registerWillUserSuccess(response.user))
      // history.push("/your-info")
      history.push("/choose-plan")
    }
  } catch (error) {
    yield put(registerWillUserFail(error?.response?.data))
  }
}

function* loginWillUser({ payload: { user, history } }) {
  try {
    const response = yield call(loginWillUserAsync, user)
    if (response.success) {
      localStorage.setItem('access_token', response.token)
      axiosApi.defaults.headers.common["Authorization"] = "Bearer " + response.token
      yield put(loginWillUserSuccess(response.user))
      // history.push("/your-info")
      history.push("/choose-plan")
    }
  } catch (error) {
    yield put(loginWillUserFail(error?.response?.data?.message))
  }
}

function* updateWillUser({ payload: { user, history } }) {
  try {
    const response = yield call(updateWillUserAsync, user)
    if (response.success) {
      yield put(updateWillUserSuccess(response.data))
      history.push("/review")
    }
  } catch (error) {
    yield put(updateWillUserFail(error.response.data))
  }
}

function* getAllUsers() {
  try {
    const response = yield call(getAllUsersAsync)
    if (response.success) {
      yield put(getAllUserSuccess(response.data))
    }
  } catch (error) {
    console.log('error =>', error)
  }
}

function* addUser({ payload: { user } }) {
  try {
    const response = yield call(addUserAsync, user)
    if (response.success) {
      yield put(onAddNewUserSuccess(response.user))
    }
  } catch (error) {
    yield put(registerWillUserFail(error.response.data))

  }
}

function* updateUser({ payload: { user } }) {
  try {
    const response = yield call(updateUserAsync, user)
    if (response.success) {
      yield put(onUpdateUserSuccess(response.user))
    }
  } catch (error) {
    console.log('error =>', error.response)
    // yield put(registerWillUserFail(error.response.data))

  }
}

function* deleteUser({ payload: { id } }) {
  try {
    const response = yield call(deleteUserAsync, id)
    console.log('response =>', response)
    if (response.success) {
      yield put(onDeleteUserSuccess(id))
    }
  } catch (error) {
    console.log('error =>', error.response)
  }
}

function* logoutUser() {
  try {
    const response = yield call(logoutUserAsync)
    if (response.success) {
      axiosApi.defaults.headers.common["Authorization"] = null
    }
  } catch (error) {
    axiosApi.defaults.headers.common["Authorization"] = null
  }
}


function* userSaga() {
  yield takeEvery(REGISTER_WILL_USER, registerWillUser)
  yield takeEvery(UPDATE_WILL_USER, updateWillUser)


  yield takeEvery(LOGIN_WILL_USER, loginWillUser)
  yield takeEvery(GET_ALL_USERS, getAllUsers)

  yield takeEvery(ADD_USER, addUser)
  yield takeEvery(UPDATE_USER, updateUser)
  yield takeEvery(DELETE_USER, deleteUser)
  yield takeEvery(LOGOUT_USER, logoutUser)

}

export default userSaga
