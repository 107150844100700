import {
  REGISTER_WILL_USER,
  REGISTER_WILL_USER_SUCCESS,
  REGISTER_WILL_USER_FAIL,
  LOGIN_WILL_USER,
  LOGIN_WILL_USER_SUCCESS,
  LOGIN_WILL_USER_FAIL,
  SET_AUTH_USER,
  UPDATE_WILL_USER,
  UPDATE_WILL_USER_SUCCESS,
  GET_ALL_USERS,
  GET_ALL_USERS_SUCCESS,
  ADD_USER,
  ADD_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  DELETE_USER_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  authUser: null,
  register_error: null,
  login_error: null,
  loading: false,
  success: false,
}

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REGISTER_WILL_USER:
      return {
        ...state,
        loading: true,
      }
    case REGISTER_WILL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        authUser: action.payload
      }

    case REGISTER_WILL_USER_FAIL:
      return {
        ...state,
        loading: false,
        register_error: action.payload
      }

    case LOGIN_WILL_USER:
      return {
        ...state,
        loading: true,
      }
    case LOGIN_WILL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        authUser: action.payload
      }

    case LOGIN_WILL_USER_FAIL:
      return {
        ...state,
        loading: false,
        login_error: action.payload
      }
    case SET_AUTH_USER:
      return {
        ...state,
        loading: false,
        authUser: action.payload
      }
    case UPDATE_WILL_USER:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_WILL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        authUser: action.payload
      }

    case GET_ALL_USERS:
      return {
        ...state,
        loading: true,
      }

    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload
      }
    case ADD_USER:
      return {
        ...state,
        loading: true,
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        users: [...state.users, action.payload]
      }

    case UPDATE_USER_SUCCESS:
      const updatedUsers = state.users.map(obj => {
        if (obj.id === action.payload.id) {
          return { ...obj, ...action.payload };
        }
        return obj;
      });
      return {
        ...state,
        loading: false,
        success: true,
        users: updatedUsers
      }

    case DELETE_USER_SUCCESS:
      const filteredUsers = state.users.filter(user => user.id !== action.payload)
      return {
        ...state,
        loading: false,
        users: filteredUsers
      }
    default:
      return state
  }
}

export default users
