import { Button } from "antd";
import FeatherIcon from "feather-icons-react";
import React from "react";

const FeatureCard = ({ data = {} }) => {
  return (
    <div className="feature-card">
      <img src={data.thumbnail} alt={data.title} className="thumbnail" />
      <div className="d-flex align-items-center" style={{ gap: 5 }}>
        <FeatherIcon strokeWidth={3} icon={data.icon} className="icon" />
        <h1 className="title">{data.title}</h1>
      </div>
      <p className="description">{data.description}</p>
    </div>
  );
};

export default FeatureCard;
