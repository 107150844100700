/* INVOICES */
export const GET_WILL_BY_USERID = "GET_WILL_BY_USERID"
export const GET_WILL_BY_USERID_SUCCESS = "GET_WILL_BY_USERID_SUCCESS"
export const GET_WILL_BY_USERID_FAIL = "GET_WILL_BY_USERID_FAIL"

export const SAVE_WILL_BY_USERID = 'SAVE_WILL_BY_USERID'
export const SAVE_WILL_BY_USERID_SUCCESS = 'SAVE_WILL_BY_USERID_SUCCESS'

export const CHANGE_EDIT_STATUS = 'CHANGE_EDIT_STATUS'

export const UPDATE_WILL_BY_USERID = 'UPDATE_WILL_BY_USERID'
export const UPDATE_WILL_SURROGATES = 'UPDATE_WILL_SURROGATES'
export const UPDATE_WILL_BY_USERID_SUCCESS = 'UPDATE_WILL_BY_USERID_SUCCESS'

export const UPDATE_USER_SELECTED_PLANS = 'UPDATE_USER_SELECTED_PLANS'
export const UPDATE_LAST_WILL_TESTAMENT = 'UPDATE_LAST_WILL_TESTAMENT'
