import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

const UserLayout = (props) => {
  return (
    <div className="user-layout">
      <Header />
      <div className="content">{props.children}</div>
      <Footer />
    </div>
  );
};

UserLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withRouter(UserLayout);
