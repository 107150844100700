import { Button, Carousel } from "antd";
import PropTypes from "prop-types";
import React, { useLayoutEffect, useRef } from "react";
import MetaTags from "react-meta-tags";
import { useHistory, withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import FeatureCard from "../components/Home/FeatureCard";
import { useSelector } from "react-redux";

const Home = () => {
  const history = useHistory();
  const carouselRef = useRef();
  const { authUser } = useSelector((state) => state.User);

  useLayoutEffect(() => {
    const CarouselPlayInterval = setInterval(() => {
      carouselRef.current.next();
    }, 6000);
    carouselRef.current.next();
    return () => clearInterval(CarouselPlayInterval);
  }, []);

  const getStarted = () => {
    if (authUser) {
      history.push("/your-info");
    } else {
      history.push("/will-register");
    }
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Home page</title>
      </MetaTags>
      <div className="home-page">
        <Container fluid className="p-0">
          <Carousel
            effect="fade"
            dots={false}
            ref={carouselRef}
            className="home-slider"
          >
            {["slide-4.png", "slide-1.png", "slide-2.png", "slide-3.png"].map(
              (imgSrc) => (
                <div key={imgSrc} className="slide-container">
                  <img
                    src={`/images/home/${imgSrc}`}
                    alt=""
                    className="slide-img"
                  />

                  <div className="layer" />

                  <div className="slide-content">
                    <Container>
                      <h2 className="title-1">
                        Secure
                        <br />
                        Your Future:
                      </h2>

                      <h3 className="title-2">Create Your Estate Plan Today!</h3>

                      <p className="description">*Florida Residents Only.</p>

                      <Button
                        type="text btn hover-zoom-effect"
                        size="large"
                        onClick={getStarted}
                      >
                        GET STARTED
                      </Button>
                    </Container>
                  </div>
                </div>
              )
            )}
          </Carousel>

          <Container>
            <h2
              style={{ fontSize: "1.6rem", marginBottom: 10 }}
              className="fw-medium mt-4 txt-primary"
            >
              Simple and Affordable Estate Planning documents at your fingertips
            </h2>

            <p style={{ fontSize: 19, marginBottom: 42 }} className="fw-normal">
              Do Your Will provides a fast and efficient way for you to plan for
              your family’s future. Simply click here to get started and fill
              out our questionnaire to generate your estate planning documents.
              Once finalized we will mail your plan right to your doorstep with
              detailed instructions on how to sign and store in a safe place.
            </p>

            <div className="mb-5">
              <Row className="m-n3">
                {[
                  {
                    id: 1,
                    thumbnail: "/images/home/card-thumbnail-1.jpg",
                    title: "Fast & Easy",
                    description:
                      "In just 30 minutes or less you can have the peace of mind, knowing your loved ones will be provided for when you’re gone.",
                    icon: "clock",
                  },
                  {
                    id: 2,
                    thumbnail: "/images/home/card-thumbnail-2.jpg",
                    title: "Affordable",
                    description:
                      "Protect your loved ones and ensure your legacy with a comprehensive and legally binding will, all at an affordable price of $149.",
                    icon: "dollar-sign",
                  },
                ].map((data) => (
                  <Col key={data.id} xs={12} md={6} className="p-3">
                    <FeatureCard data={data} />
                  </Col>
                ))}
              </Row>
            </div>
          </Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Home);

Home.propTypes = {
  history: PropTypes.object,
};
